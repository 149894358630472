import { Typography } from '@mui/material';
import { useCallback } from 'react';

import { ENV } from '@shared/config/constants';
import { useNavigateExternal } from '@shared/hooks';
import { PageTemplate } from '@shared/ui/templates/page-template';
import { HeaderWidgetConnector } from '@widgets/header';

import { Help } from './help';

export const HelpConnector = () => {
    const navigateExt = useNavigateExternal();
    const handleSupportClick = useCallback(() => {
        navigateExt(ENV.yandexSupportLink);
    }, [navigateExt]);
    return (
        <PageTemplate
            variant="big"
            withHeadSpace
            Header={
                <HeaderWidgetConnector withLogo variant="primary" withProfile>
                    <Typography
                        variant="h2"
                        textAlign={{ xs: 'center', md: 'start' }}>
                        Вопросы и ответы
                    </Typography>
                </HeaderWidgetConnector>
            }>
            <Help onSupportClick={handleSupportClick} />
        </PageTemplate>
    );
};
