import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import { FormClientId, TFormData } from './form-client-id';

type TProps = {
    buttonText: string;
    defaultValue?: number;
    externalFormError?: TExternalFormError;
    onSubmit: (data: TFormData) => void;
};

export type TExternalFormError = {
    name: keyof TFormData;
    message: string;
    type?: 'api_error';
};

export const FormClientIdProvider = ({
    buttonText,
    defaultValue,
    externalFormError,
    onSubmit,
}: TProps) => {
    const schema = z.object({
        clientId: z
            .string({ required_error: 'введите сlientID' })
            .transform(string => string.trim())
            .pipe(
                z
                    .string()
                    .regex(/^[-0-9]*$/g, 'clientID вида XXXX-XXXX-XXXX')
                    .min(1, 'введите сlientID')
                    .refine(
                        str => str !== String(defaultValue),
                        'введите новый сlientID',
                    ),
            ),
    });
    const methods = useForm<TFormData>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues: {
            clientId: defaultValue ? String(defaultValue) : undefined,
        },
    });
    useEffect(() => {
        if (externalFormError) {
            methods.setError(externalFormError.name, {
                message: externalFormError.message,
                type: externalFormError.type,
            });
        }
    }, [externalFormError, methods]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <FormClientId buttonText={buttonText} />
            </form>
        </FormProvider>
    );
};
