import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
    Box,
    IconButton,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { useRef, useState } from 'react';
import { Control, Controller } from 'react-hook-form';

import { TPriceLimit } from '@entities/products/types';
import { TFormData } from '@features/form-filters/form-filters';

type TFormFieldsValue = {
    minPrice: TFormData['minPrice'];
    maxPrice: TFormData['maxPrice'];
};

type TProps = {
    control: Control<TFormData>;
    formFields: TFormFieldsValue;
    priceLimit: TPriceLimit;
};

export const ProductPriceFilter = ({
    control,
    formFields,
    priceLimit,
}: TProps) => {
    const { minPrice, maxPrice } = formFields;
    const [isBlur, setIsBlur] = useState(false);
    const theme = useTheme();
    const containerRef = useRef<HTMLHeadingElement>(null);

    const getFieldText = () => {
        if (!minPrice && !maxPrice) {
            return;
        }
        if (minPrice && maxPrice) {
            return `от ${minPrice} до ${maxPrice}`;
        }
        if (minPrice) {
            return `от ${minPrice}`;
        }
        return `до ${maxPrice}`;
    };

    return (
        <Box
            ref={containerRef}
            position="relative"
            borderRadius="24px 24px 0 0"
            padding="20px"
            minWidth="280px"
            width="280px"
            sx={theme => ({
                border: isBlur
                    ? `1px solid ${theme.palette.border.border4}`
                    : '1px solid transparent',
                background: isBlur ? theme.palette.bg.primary : 'transparent',
            })}>
            <TextField
                select
                fullWidth
                placeholder="Любая"
                size="small"
                variant="standard"
                label="Стоимость, ₽"
                InputLabelProps={{
                    shrink: true,
                    filled: true,
                }}
                InputProps={{
                    sx: {
                        '&::placeholder': {
                            color: theme.palette.typography.tertiary,
                            opacity: '1 !important',
                        },
                    },
                }}
                SelectProps={{
                    MenuProps: {
                        disablePortal: true,
                        slotProps: {
                            paper: {
                                sx: theme => ({
                                    border: isBlur
                                        ? `solid ${theme.palette.border.border4}`
                                        : 'solid transparent',
                                    borderWidth: '0 1px 1px 1px',
                                    borderRadius: 0,
                                    width: containerRef.current?.offsetWidth,
                                    transition: 'none !important',
                                }),
                            },
                        },
                    },
                    onClose: () => {
                        setIsBlur(false);
                    },
                    onOpen: () => {
                        setIsBlur(true);
                    },
                    displayEmpty: true,
                    renderValue: () =>
                        minPrice || maxPrice ? (
                            <Typography
                                variant="inputValue3"
                                color={theme =>
                                    theme.palette.typography.primary
                                }>
                                {getFieldText()}
                            </Typography>
                        ) : (
                            <Typography
                                variant="inputValue3"
                                color={theme =>
                                    theme.palette.typography.secondary
                                }>
                                Любая
                            </Typography>
                        ),
                    IconComponent: props => (
                        <IconButton color="buttonPrimary">
                            <ArrowDropDownIcon {...props} />
                        </IconButton>
                    ),
                }}>
                <Stack direction="row" padding="15px 20px" gap="15px">
                    <Controller
                        control={control}
                        name="minPrice"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                size="small"
                                value={minPrice || ''}
                                placeholder={String(priceLimit.min)}
                                variant="standard"
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                                inputProps={{
                                    sx: {
                                        '&::placeholder': {
                                            color: theme.palette.typography
                                                .tertiary,
                                            opacity: 1,
                                            fontWeight: 600,
                                        },
                                    },
                                }}
                                onChange={e => {
                                    if (!e.currentTarget) {
                                        return;
                                    }
                                    const numberValue = Number(
                                        e.currentTarget.value.replace(
                                            /[^0-9]/g,
                                            '',
                                        ),
                                    );
                                    field.onChange(
                                        isNaN(numberValue || NaN)
                                            ? undefined
                                            : numberValue,
                                    );
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <Typography
                                            variant="inputValue3"
                                            color={theme =>
                                                theme.palette.typography
                                                    .tertiary
                                            }
                                            marginTop="2px">
                                            от{'\u00A0'}
                                        </Typography>
                                    ),
                                }}
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="maxPrice"
                        render={({ field }) => (
                            <TextField
                                {...field}
                                size="small"
                                placeholder={String(priceLimit.max)}
                                variant="standard"
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                                value={maxPrice || ''}
                                onChange={e => {
                                    if (!e.currentTarget) {
                                        return;
                                    }
                                    const numberValue = Number(
                                        e.currentTarget.value.replace(
                                            /[^0-9]/g,
                                            '',
                                        ),
                                    );

                                    field.onChange(
                                        isNaN(numberValue || NaN)
                                            ? undefined
                                            : numberValue,
                                    );
                                }}
                                inputProps={{
                                    sx: {
                                        '&::placeholder': {
                                            color: theme.palette.typography
                                                .tertiary,
                                            opacity: 1,
                                            fontWeight: 600,
                                        },
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <Typography
                                            variant="inputValue3"
                                            color={theme =>
                                                theme.palette.typography
                                                    .tertiary
                                            }
                                            marginTop="2px">
                                            до{'\u00A0'}
                                        </Typography>
                                    ),
                                }}
                            />
                        )}
                    />
                </Stack>
            </TextField>
        </Box>
    );
};
