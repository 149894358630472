import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { useGetProducts } from '@entities/products';
import { useSearchHistoryStore } from '@entities/user/model/user-domain';
import { FormSearchHeaderProvider } from '@features/form-search-header';
import { accountPaths } from '@pages/account/paths';
import { ENV } from '@shared/config/constants';
import { useNavigateExternal } from '@shared/hooks';
import { CatalogPageTemplate } from '@shared/ui/templates/catalog-page-template';
import { HeaderWidgetConnector } from '@widgets/header';

import { BrandPage } from './brand-page';
import { mapProduct } from './utils/mappers';
import { CatalogPageSkeleton } from '../catalog/ui/catalog-page-skeleton'; //TODO @OKA create for this page
import { catalogPaths } from '../paths';

export const BrandPageConnector = () => {
    const [params, setParams] = useSearchParams();
    const navigate = useNavigate();
    const navigateExt = useNavigateExternal();
    const { enqueueSnackbar } = useSnackbar();
    const initSearch = params.get('search');
    const searchHistoryStore = useSearchHistoryStore();
    const { data, isLoading, isRefetching } = useGetProducts({
        body: {
            vendorCode: initSearch || '',
        },
        onError: () => {
            enqueueSnackbar('что-то пошло не так', {
                variant: 'error',
                description: 'обновите страницу или попробуйте позже',
            });
        },
    });
    useEffect(() => {
        if (
            data?.data.providersError?.find(error => error.errorCode === '500')
        ) {
            enqueueSnackbar('что-то пошло не так', {
                variant: 'error',
                description: 'обновите страницу или попробуйте позже', //TODO @OKA (des) change error
            });
        }
    }, [data?.data.providersError, enqueueSnackbar]);
    const handleSubmit = useCallback(
        (search: string) => {
            searchHistoryStore.add(search);
            setParams({ search: search });
        },
        [searchHistoryStore, setParams],
    );
    const products = mapProduct(data?.data.partTypeGroup);
    const providersError = useMemo(
        () =>
            data?.data.providersError
                ?.filter(error => error.errorCode === '401')
                .map(error => error.providerName) || [],
        [data?.data.providersError],
    );
    const handleSelect = useCallback(
        (brand: string) => {
            navigate(
                `${catalogPaths.catalog.absolute}?brand=${brand}&search=${initSearch}`,
            );
        },
        [initSearch, navigate],
    );

    const handleChangeProviderClick = () => {
        navigate(accountPaths.providers.absolute);
    };

    const handleSupportClick = useCallback(() => {
        navigateExt(ENV.yandexSupportLink);
    }, [navigateExt]);

    if (data?.data.partTypeGroup && data.data.partTypeGroup.length === 1) {
        return (
            <Navigate
                to={`${catalogPaths.catalog.absolute}?search=${initSearch}&brand=${data.data.partTypeGroup[0].brand}`}
                replace
            />
        );
    }

    if (isLoading || isRefetching) {
        return <CatalogPageSkeleton />;
    }

    return (
        <CatalogPageTemplate
            Header={
                <HeaderWidgetConnector withLogo variant="primary" withProfile>
                    <FormSearchHeaderProvider
                        options={searchHistoryStore.searchHistory}
                        initSearch={initSearch || ''}
                        onSubmit={data => handleSubmit(data.search)}
                    />
                </HeaderWidgetConnector>
            }>
            <BrandPage
                products={products}
                onSelect={handleSelect}
                onHelpClick={handleSupportClick}
                onChangeProvider={handleChangeProviderClick}
                providersError={providersError}
            />
        </CatalogPageTemplate>
    );
};
