import { Box, Grid, Skeleton, Stack } from '@mui/material';

export const CatalogPageSkeleton = () => {
    return (
        <Stack direction="column" gap="40px">
            <Stack
                direction="column"
                padding="140px 40px 40px 40px"
                gap="60px"
                sx={theme => ({ background: theme.palette.surface.secondary })}>
                <Stack direction="row" justifyContent="space-between">
                    <Skeleton variant="rounded" width="500px" height="55px" />
                    <Box>
                        <Skeleton
                            variant="rounded"
                            width="200px"
                            height="42px"
                        />
                    </Box>
                </Stack>
                <Grid
                    container
                    direction="row"
                    paddingTop="40px"
                    sx={theme => ({
                        borderTop: `solid 1px ${theme.palette.icon.tertiary}`,
                    })}
                />
            </Stack>
            <Stack paddingX="40px" gap="10px">
                {[0, 1, 2, 3, 4, 5, 6, 7].map(item => (
                    <Skeleton
                        key={item}
                        variant="rounded"
                        width="100%"
                        height="64px"
                    />
                ))}
            </Stack>
        </Stack>
    );
};
