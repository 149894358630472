import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { CreateAuthCredentialsResponse } from '../../api/axios-client';

export type TSession = CreateAuthCredentialsResponse;
export type TUserInfo = {
    login?: string;
    firstName?: string;
    lastName?: string;
    displayName?: string;
    realName?: string;
    avatarId?: string;
    email?: string;
    phone?: string;
    clientId?: number;
    yaToken?: string;
};

type TSessionStore = {
    session: TSession | null;
    set: (value: TSession) => void;
    reset: () => void;
};

export const useSessionStore = create<TSessionStore>()(
    persist(
        set => ({
            session: null,
            set: session => set({ session: session }),
            reset: () => set({ session: null }),
        }),
        {
            name: 'sessionStore',
            storage: createJSONStorage(() => localStorage),
        },
    ),
);

type TLoginStore = {
    login: boolean | null;
    set: (value: boolean) => void;
    reset: () => void;
};

export const useLoginStore = create<TLoginStore>()(
    persist(
        set => ({
            login: null,
            set: login => set({ login: login }),
            reset: () => set({ login: null }),
        }),
        {
            name: 'loginStore',
            storage: createJSONStorage(() => localStorage),
        },
    ),
);

type TUserInfoStore = {
    userInfo: TUserInfo | null;
    set: (userInfo: TUserInfo) => void;
    update: (userInfo: TUserInfo) => void;
    reset: () => void;
};

export const useUserInfoStore = create<TUserInfoStore>()(
    persist(
        set => ({
            userInfo: null,
            set: newUserInfo => set({ userInfo: newUserInfo }),
            update: newUserInfo =>
                set(state => ({
                    userInfo: { ...state.userInfo, ...newUserInfo },
                })),
            reset: () => set({ userInfo: null }),
        }),
        {
            name: 'userInfoStore',
            storage: createJSONStorage(() => localStorage),
        },
    ),
);

export const useResetSessionDomain = () => {
    const sessionStore = useSessionStore();
    const loginStore = useLoginStore();
    const userInfoStore = useUserInfoStore();
    return () => {
        loginStore.reset();
        sessionStore.reset();
        userInfoStore.reset();
    };
};
