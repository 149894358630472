import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Box, Button, Stack, useMediaQuery, useTheme } from '@mui/material';

import { FormCounterProvider } from '@features/form-counter';

type TProps = {
    cartQuantity?: number;
    cartStock?: number;
    onCounter: (count: number) => void;
};

export const ProductControllers = ({
    cartQuantity,
    cartStock,
    onCounter,
}: TProps) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xl'));

    return (
        <Stack direction="row" gap="8px" alignSelf="center">
            <Box maxWidth="117px" height="42px">
                {cartQuantity && cartStock ? (
                    <FormCounterProvider
                        initValue={cartQuantity}
                        variant="rounded"
                        minValue={0}
                        maxValue={cartStock}
                        onSubmit={data => onCounter(data.counter)}
                    />
                ) : (
                    <Button
                        fullWidth
                        variant="contained"
                        color="buttonSecondaryEnabled"
                        onClick={() => onCounter(1)}>
                        {isSmallScreen ? (
                            <ShoppingCartOutlinedIcon />
                        ) : (
                            'в корзину'
                        )}
                    </Button>
                )}
            </Box>
        </Stack>
    );
};
