import { Box, Button, Typography } from '@mui/material';
import { ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLogout } from '@entities/user/model';
import { authPaths } from '@pages/auth/paths';
import { useLogoClick } from '@shared/hooks';
import { useLogin } from '@shared/session';
import { TopbarMenuWidgetConnector } from '@widgets/topbar-menu';

import { HeaderWidget, THeaderVariants } from './header-widget';

type TProps = {
    children?: ReactNode;
    withLogo: boolean;
    backgroundColor?: string;
    withProfile?: boolean;
    variant: THeaderVariants;
};

export const HeaderWidgetConnector = ({
    children,
    withLogo,
    withProfile,
    variant = 'primary',
    backgroundColor,
}: TProps) => {
    const navigate = useNavigate();
    const isLogin = useLogin();
    const onLogin = useCallback(
        () => navigate(authPaths.home.absolute),
        [navigate],
    );
    const logout = useLogout();
    const onLogoClick = useLogoClick();
    const profile = withProfile ? (
        <TopbarMenuWidgetConnector />
    ) : (
        <Box display="flex" justifyContent="flex-end">
            <Button color="buttonPrimary" variant="text" onClick={logout}>
                <Typography variant="subtitle1">Выход</Typography>
            </Button>
        </Box>
    );
    return (
        <HeaderWidget
            withLogo={withLogo}
            onLogoClick={withProfile ? onLogoClick : undefined}
            backgroundColor={backgroundColor}
            RightSlot={
                isLogin ? (
                    profile
                ) : (
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            color="buttonPrimary"
                            variant="text"
                            onClick={onLogin}>
                            <Typography variant="subtitle1">Войти</Typography>
                        </Button>
                    </Box>
                )
            }
            children={children}
            variant={variant}
        />
    );
};
