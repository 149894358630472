/* tslint:disable */
/* eslint-disable */
/**
 * openapi-yavitrina-service
 * openapi-yavitrina-service
 *
 * The version of the OpenAPI document: 1.0
 * Contact: slurm@kode.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface CartAmountResponse
 */
export interface CartAmountResponse {
    /**
     *
     * @type {number}
     * @memberof CartAmountResponse
     */
    cartAmountResponse?: number;
}
/**
 * Товар в корзине
 * @export
 * @interface CartProduct
 */
export interface CartProduct {
    /**
     *
     * @type {CartWarehouse}
     * @memberof CartProduct
     */
    warehouse: CartWarehouse;
    /**
     *
     * @type {CartProvider}
     * @memberof CartProduct
     */
    provider: CartProvider;
    /**
     *
     * @type {Product}
     * @memberof CartProduct
     */
    product: Product;
}
/**
 *
 * @export
 * @interface CartProductGroup
 */
export interface CartProductGroup {
    /**
     * ИД поставщика
     * @type {string}
     * @memberof CartProductGroup
     */
    providerId: string;
    /**
     * Название поставщика
     * @type {string}
     * @memberof CartProductGroup
     */
    providerName: string;
    /**
     *
     * @type {Array<CartProduct>}
     * @memberof CartProductGroup
     */
    products: Array<CartProduct>;
    /**
     * Общая сумма товаров по поставщику
     * @type {number}
     * @memberof CartProductGroup
     */
    totalPrice: number;
    /**
     * Общее кол-во товаров
     * @type {number}
     * @memberof CartProductGroup
     */
    totalQuantity: number;
    /**
     * Минимальная цена оформления заказа по поставщику
     * @type {number}
     * @memberof CartProductGroup
     */
    minOrderPrice: number;
    /**
     * Признак поставщика с мгновенным заказом    true - заказ мгновенный   false - заказ на стороне поставщика
     * @type {boolean}
     * @memberof CartProductGroup
     */
    instantOrderAvailable: boolean;
}
/**
 *
 * @export
 * @interface CartProvider
 */
export interface CartProvider {
    /**
     * ИД поставщика
     * @type {string}
     * @memberof CartProvider
     */
    id: string;
    /**
     * Название поставщика
     * @type {string}
     * @memberof CartProvider
     */
    name: string;
}
/**
 *
 * @export
 * @interface CartWarehouse
 */
export interface CartWarehouse {
    /**
     *
     * @type {string}
     * @memberof CartWarehouse
     */
    id: string;
    /**
     * Название
     * @type {string}
     * @memberof CartWarehouse
     */
    shortName: string;
    /**
     * дата отгрузки
     * @type {string}
     * @memberof CartWarehouse
     */
    shipmentDate: string | null;
    /**
     * В налчии   true - да  false - нет
     * @type {boolean}
     * @memberof CartWarehouse
     */
    inStock: boolean;
    /**
     * Кол-во на складе
     * @type {number}
     * @memberof CartWarehouse
     */
    stock: number;
    /**
     * стоиомость
     * @type {number}
     * @memberof CartWarehouse
     */
    price: number;
    /**
     * Возвратная позиция  true - да   false - нет
     * @type {boolean}
     * @memberof CartWarehouse
     */
    notRefund: boolean;
    /**
     * состояние детали  true - б/у  false - новое
     * @type {boolean}
     * @memberof CartWarehouse
     */
    used: boolean;
    /**
     * Необходимое кол-во
     * @type {number}
     * @memberof CartWarehouse
     */
    quantity: number;
}
/**
 *
 * @export
 * @interface ClIdResponse
 */
export interface ClIdResponse {
    /**
     * Идентификатор автосервиса
     * @type {number}
     * @memberof ClIdResponse
     */
    clientId?: number;
}
/**
 * Атрибут для подключения к поставщику
 * @export
 * @interface ConnectAttribute
 */
export interface ConnectAttribute {
    /**
     * Название атрибута
     * @type {string}
     * @memberof ConnectAttribute
     */
    attribute: string;
    /**
     * Значение атрибута
     * @type {string}
     * @memberof ConnectAttribute
     */
    value: string;
    /**
     * Отображение для UI названия атрибута
     * @type {string}
     * @memberof ConnectAttribute
     */
    label: string;
}
/**
 * Список атрибутов необходимых для подключения
 * @export
 * @interface ConnectAttributeItem
 */
export interface ConnectAttributeItem {
    /**
     * наименование атрибута
     * @type {string}
     * @memberof ConnectAttributeItem
     */
    attribute: string;
    /**
     * наименование атрибута на фронте
     * @type {string}
     * @memberof ConnectAttributeItem
     */
    description: string;
    /**
     * Текстовое описание \"Что это за ключ\" и \"Где его найти\"
     * @type {string}
     * @memberof ConnectAttributeItem
     */
    label: string;
}
/**
 *
 * @export
 * @interface ConnectAttributeRequest
 */
export interface ConnectAttributeRequest {
    /**
     * Название атрибута
     * @type {string}
     * @memberof ConnectAttributeRequest
     */
    attribute: string;
    /**
     * Значение атрибута
     * @type {string}
     * @memberof ConnectAttributeRequest
     */
    value: string;
}
/**
 *
 * @export
 * @interface CreateAuthCredentialsResponse
 */
export interface CreateAuthCredentialsResponse {
    /**
     * Токен доступа
     * @type {string}
     * @memberof CreateAuthCredentialsResponse
     */
    accessToken: string;
    /**
     * Токен обновления сессии
     * @type {string}
     * @memberof CreateAuthCredentialsResponse
     */
    refreshToken: string;
}
/**
 *
 * @export
 * @interface CreateAuthCredentialsYandexResponse
 */
export interface CreateAuthCredentialsYandexResponse {
    /**
     *
     * @type {string}
     * @memberof CreateAuthCredentialsYandexResponse
     */
    accessToken: string;
    /**
     *
     * @type {string}
     * @memberof CreateAuthCredentialsYandexResponse
     */
    refreshToken: string;
}
/**
 *
 * @export
 * @interface CreateLoginHashPasswordRequest
 */
export interface CreateLoginHashPasswordRequest {
    /**
     *
     * @type {string}
     * @memberof CreateLoginHashPasswordRequest
     */
    email: string;
    /**
     * Пароль пользователя
     * @type {string}
     * @memberof CreateLoginHashPasswordRequest
     */
    password: string;
}
/**
 *
 * @export
 * @interface CreateLogoutRequest
 */
export interface CreateLogoutRequest {
    /**
     * Токен закрытия сессии
     * @type {string}
     * @memberof CreateLogoutRequest
     */
    logoutToken: string;
    /**
     * Токен яндекса
     * @type {string}
     * @memberof CreateLogoutRequest
     */
    authToken: string;
}
/**
 *
 * @export
 * @interface CreateRefreshModelRequest
 */
export interface CreateRefreshModelRequest {
    /**
     * Токен обновления сессии
     * @type {string}
     * @memberof CreateRefreshModelRequest
     */
    refreshToken: string;
}
/**
 *
 * @export
 * @interface CreateRefreshYandexModelRequest
 */
export interface CreateRefreshYandexModelRequest {
    /**
     * Токен обновления сессии
     * @type {string}
     * @memberof CreateRefreshYandexModelRequest
     */
    refreshToken: string;
}
/**
 *
 * @export
 * @interface CreateRegistrationConfirmationResponse
 */
export interface CreateRegistrationConfirmationResponse {
    /**
     * Токен попытки OTP
     * @type {string}
     * @memberof CreateRegistrationConfirmationResponse
     */
    registrationToken: string;
}
/**
 *
 * @export
 * @interface CreateRegistrationInfoRequest
 */
export interface CreateRegistrationInfoRequest {
    /**
     * Токен попытки OTP
     * @type {string}
     * @memberof CreateRegistrationInfoRequest
     */
    registrationToken: string;
    /**
     *
     * @type {ProfileRegistrationModel}
     * @memberof CreateRegistrationInfoRequest
     */
    profile: ProfileRegistrationModel;
    /**
     * Признак, согласие на рекламную рассылку
     * @type {boolean}
     * @memberof CreateRegistrationInfoRequest
     */
    isMarketingConsentPermitted: boolean;
    /**
     * Хэш пароля пользователя
     * @type {string}
     * @memberof CreateRegistrationInfoRequest
     */
    password: string;
}
/**
 *
 * @export
 * @interface CreateRegistrationRequest
 */
export interface CreateRegistrationRequest {
    /**
     *
     * @type {string}
     * @memberof CreateRegistrationRequest
     */
    email: string;
    /**
     * Признак, согласие на обработку и хранения персональных данных
     * @type {boolean}
     * @memberof CreateRegistrationRequest
     */
    isPersonalDataProcessingPermitted: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export const Enum401Error = {
    AccessTokenInvalid: 'AccessTokenInvalid',
    AccessTokenExpired: 'AccessTokenExpired',
} as const;

export type Enum401Error = (typeof Enum401Error)[keyof typeof Enum401Error];

/**
 * Модель ошибок
 * @export
 * @interface ErrorModel
 */
export interface ErrorModel {
    /**
     *
     * @type {ResponseCodeModel}
     * @memberof ErrorModel
     */
    code: ResponseCodeModel;
    /**
     * Сообщение (устанавливается сервисов по code ошибки)
     * @type {string}
     * @memberof ErrorModel
     */
    message: string;
    /**
     * Трейс ошибки (возвращается, если сервис находится в режиме отладки)
     * @type {string}
     * @memberof ErrorModel
     */
    debug?: string;
    /**
     * Полезная нагрузка
     * @type {{ [key: string]: string; }}
     * @memberof ErrorModel
     */
    payload?: { [key: string]: string } | null;
}

/**
 *
 * @export
 * @interface ErrorModel400
 */
export interface ErrorModel400 {
    /**
     *
     * @type {ResponseCodeModel400}
     * @memberof ErrorModel400
     */
    code?: ResponseCodeModel400;
    /**
     *
     * @type {string}
     * @memberof ErrorModel400
     */
    message?: string;
}

/**
 *
 * @export
 * @interface ErrorModelLoginOrPasswordIncorrect
 */
export interface ErrorModelLoginOrPasswordIncorrect {
    /**
     *
     * @type {ResponseCodeModel}
     * @memberof ErrorModelLoginOrPasswordIncorrect
     */
    code: ResponseCodeModel;
    /**
     * Сообщение (устанавливается сервисов по code ошибки)
     * @type {string}
     * @memberof ErrorModelLoginOrPasswordIncorrect
     */
    message: string;
    /**
     * Трейс ошибки (возвращается, если сервис находится в режиме отладки)
     * @type {string}
     * @memberof ErrorModelLoginOrPasswordIncorrect
     */
    debug?: string;
}

/**
 *
 * @export
 * @interface ErrorModelPersonalDataConsentError
 */
export interface ErrorModelPersonalDataConsentError {
    /**
     *
     * @type {ResponseCodeModel}
     * @memberof ErrorModelPersonalDataConsentError
     */
    code: ResponseCodeModel;
    /**
     * Сообщение (устанавливается сервисов по code ошибки)
     * @type {string}
     * @memberof ErrorModelPersonalDataConsentError
     */
    message: string;
    /**
     * Трейс ошибки (возвращается, если сервис находится в режиме отладки)
     * @type {string}
     * @memberof ErrorModelPersonalDataConsentError
     */
    debug?: string;
}

/**
 *
 * @export
 * @interface ErrorModelRegistrationTokenNotFound
 */
export interface ErrorModelRegistrationTokenNotFound {
    /**
     *
     * @type {ResponseCodeModel}
     * @memberof ErrorModelRegistrationTokenNotFound
     */
    code: ResponseCodeModel;
    /**
     * Сообщение (устанавливается сервисов по code ошибки)
     * @type {string}
     * @memberof ErrorModelRegistrationTokenNotFound
     */
    message: string;
    /**
     * Трейс ошибки (возвращается, если сервис находится в режиме отладки)
     * @type {string}
     * @memberof ErrorModelRegistrationTokenNotFound
     */
    debug?: string;
}

/**
 * Ошибка валидации данных
 * @export
 * @interface ErrorModelValidationError
 */
export interface ErrorModelValidationError {
    /**
     *
     * @type {ResponseCodeModel}
     * @memberof ErrorModelValidationError
     */
    code: ResponseCodeModel;
    /**
     *
     * @type {string}
     * @memberof ErrorModelValidationError
     */
    message?: string;
    /**
     *
     * @type {string}
     * @memberof ErrorModelValidationError
     */
    debug?: string;
    /**
     *
     * @type {object}
     * @memberof ErrorModelValidationError
     */
    payload?: object;
}

/**
 *
 * @export
 * @interface FavoritesListTotalQuantity
 */
export interface FavoritesListTotalQuantity {
    /**
     * Кол-во товаров в избранном
     * @type {number}
     * @memberof FavoritesListTotalQuantity
     */
    totalQuantity: number;
}
/**
 *
 * @export
 * @interface Model401ErrorModel
 */
export interface Model401ErrorModel {
    /**
     *
     * @type {Enum401Error}
     * @memberof Model401ErrorModel
     */
    code?: Enum401Error;
    /**
     *
     * @type {string}
     * @memberof Model401ErrorModel
     */
    message?: string;
}

/**
 *
 * @export
 * @interface PartTypeGroup
 */
export interface PartTypeGroup {
    /**
     * Название бренда
     * @type {string}
     * @memberof PartTypeGroup
     */
    brand: string;
    /**
     * Название группы товара (Берётся из первого названия товара)
     * @type {string}
     * @memberof PartTypeGroup
     */
    partTypeName: string;
    /**
     * Общий артикул группы товаров
     * @type {string}
     * @memberof PartTypeGroup
     */
    vendorCode: string;
    /**
     *
     * @type {Array<ProductInfo>}
     * @memberof PartTypeGroup
     */
    products: Array<ProductInfo>;
    /**
     *
     * @type {number}
     * @memberof PartTypeGroup
     */
    page: number;
    /**
     *
     * @type {number}
     * @memberof PartTypeGroup
     */
    count: number;
    /**
     *
     * @type {number}
     * @memberof PartTypeGroup
     */
    totalCount: number;
}
/**
 *
 * @export
 * @interface PartTypeGroupsResponse
 */
export interface PartTypeGroupsResponse {
    /**
     *
     * @type {string}
     * @memberof PartTypeGroupsResponse
     */
    page: string;
    /**
     *
     * @type {string}
     * @memberof PartTypeGroupsResponse
     */
    count: string;
    /**
     *
     * @type {string}
     * @memberof PartTypeGroupsResponse
     */
    totalCount: string;
    /**
     *
     * @type {Array<PartTypeGroup>}
     * @memberof PartTypeGroupsResponse
     */
    partTypeGroup: Array<PartTypeGroup>;
    /**
     *
     * @type {Array<ProviderError>}
     * @memberof PartTypeGroupsResponse
     */
    providersError?: Array<ProviderError> | null;
}
/**
 *
 * @export
 * @interface PostV1ClIdRequest
 */
export interface PostV1ClIdRequest {
    /**
     *
     * @type {number}
     * @memberof PostV1ClIdRequest
     */
    clientId: number;
}
/**
 *
 * @export
 * @interface PostV1ClIdResponse
 */
export interface PostV1ClIdResponse {
    /**
     * Токен доступа
     * @type {string}
     * @memberof PostV1ClIdResponse
     */
    accessToken: string;
    /**
     * Токен обновления сессии
     * @type {string}
     * @memberof PostV1ClIdResponse
     */
    refreshToken: string;
    /**
     * Идентификатор автосервиса
     * @type {number}
     * @memberof PostV1ClIdResponse
     */
    clientId?: number;
}
/**
 * @type PostV1Login400Response
 * @export
 */
export type PostV1Login400Response =
    | ErrorModel
    | ErrorModelPersonalDataConsentError;

/**
 * @type PostV1Login422Response
 * @export
 */
export type PostV1Login422Response =
    | ErrorModelLoginOrPasswordIncorrect
    | ErrorModelPersonalDataConsentError;

/**
 * Запрос входа в систему
 * @export
 * @interface PostV2LoginRequest
 */
export interface PostV2LoginRequest {
    /**
     * Токен, полученный пользователем от IDP
     * @type {string}
     * @memberof PostV2LoginRequest
     */
    authToken: string;
}
/**
 *
 * @export
 * @interface PostV2LoginResponse
 */
export interface PostV2LoginResponse {
    /**
     * Токен доступа
     * @type {string}
     * @memberof PostV2LoginResponse
     */
    accessToken: string;
    /**
     * Токен обновления сессии
     * @type {string}
     * @memberof PostV2LoginResponse
     */
    refreshToken: string;
    /**
     *
     * @type {string}
     * @memberof PostV2LoginResponse
     */
    login?: string;
    /**
     *
     * @type {string}
     * @memberof PostV2LoginResponse
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof PostV2LoginResponse
     */
    lastName?: string;
    /**
     *
     * @type {string}
     * @memberof PostV2LoginResponse
     */
    displayName?: string;
    /**
     *
     * @type {string}
     * @memberof PostV2LoginResponse
     */
    realName?: string;
    /**
     *
     * @type {string}
     * @memberof PostV2LoginResponse
     */
    avatarId?: string;
    /**
     *
     * @type {string}
     * @memberof PostV2LoginResponse
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof PostV2LoginResponse
     */
    phone?: string;
    /**
     * Кол-во товаров в корзине
     * @type {number}
     * @memberof PostV2LoginResponse
     */
    cartAmount?: number | null;
    /**
     * Идентификатор автосервиса
     * @type {number}
     * @memberof PostV2LoginResponse
     */
    clientId?: number;
}
/**
 *
 * @export
 * @interface PostV2LoginResponseAllOf
 */
export interface PostV2LoginResponseAllOf {
    /**
     * Идентификатор автосервиса
     * @type {number}
     * @memberof PostV2LoginResponseAllOf
     */
    clientId?: number;
}
/**
 * Товар
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * Идентификатор продукта
     * @type {string}
     * @memberof Product
     */
    id: string;
    /**
     * Артикул
     * @type {string}
     * @memberof Product
     */
    vendorCode: string;
    /**
     * Название товара
     * @type {string}
     * @memberof Product
     */
    name: string;
    /**
     * Бренд
     * @type {string}
     * @memberof Product
     */
    brand: string;
}
/**
 *
 * @export
 * @interface ProductFavoriteRequest
 */
export interface ProductFavoriteRequest {
    /**
     * ИД товара
     * @type {string}
     * @memberof ProductFavoriteRequest
     */
    productId: string;
}
/**
 * Избранные товары
 * @export
 * @interface ProductFavorites
 */
export interface ProductFavorites {
    /**
     *
     * @type {Array<Product>}
     * @memberof ProductFavorites
     */
    productsFavorites: Array<Product>;
    /**
     * Кол-во товаров в избранном
     * @type {number}
     * @memberof ProductFavorites
     */
    totalQuantity: number;
}
/**
 *
 * @export
 * @interface ProductInfo
 */
export interface ProductInfo {
    /**
     *
     * @type {Product}
     * @memberof ProductInfo
     */
    product: Product;
    /**
     *
     * @type {Warehouse}
     * @memberof ProductInfo
     */
    warehouse: Warehouse;
    /**
     *
     * @type {Provider}
     * @memberof ProductInfo
     */
    providers: Provider;
}
/**
 *
 * @export
 * @interface ProfileInfo
 */
export interface ProfileInfo {
    /**
     *
     * @type {string}
     * @memberof ProfileInfo
     */
    login?: string;
    /**
     *
     * @type {string}
     * @memberof ProfileInfo
     */
    firstName?: string;
    /**
     *
     * @type {string}
     * @memberof ProfileInfo
     */
    lastName?: string;
    /**
     *
     * @type {string}
     * @memberof ProfileInfo
     */
    displayName?: string;
    /**
     *
     * @type {string}
     * @memberof ProfileInfo
     */
    realName?: string;
    /**
     *
     * @type {string}
     * @memberof ProfileInfo
     */
    avatarId?: string;
    /**
     *
     * @type {string}
     * @memberof ProfileInfo
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof ProfileInfo
     */
    phone?: string;
    /**
     * Кол-во товаров в корзине
     * @type {number}
     * @memberof ProfileInfo
     */
    cartAmount?: number | null;
}
/**
 *
 * @export
 * @interface ProfileRegistrationModel
 */
export interface ProfileRegistrationModel {
    /**
     * Фамилия
     * @type {string}
     * @memberof ProfileRegistrationModel
     */
    lastName?: string | null;
    /**
     * Имя
     * @type {string}
     * @memberof ProfileRegistrationModel
     */
    firstName?: string | null;
    /**
     *
     * @type {string}
     * @memberof ProfileRegistrationModel
     */
    middleName?: string | null;
    /**
     * Must be filled automaticaly
     * @type {string}
     * @memberof ProfileRegistrationModel
     */
    email: string | null;
    /**
     *
     * @type {string}
     * @memberof ProfileRegistrationModel
     */
    phone?: string;
}
/**
 * Поставщик данных (интегратор)
 * @export
 * @interface Provider
 */
export interface Provider {
    /**
     * ID провайдера (интегратора, он же поставщик)
     * @type {string}
     * @memberof Provider
     */
    id: string;
    /**
     * Название поставщика
     * @type {string}
     * @memberof Provider
     */
    name: string;
    /**
     *
     * @type {Array<ConnectAttribute>}
     * @memberof Provider
     */
    connectAttribute?: Array<ConnectAttribute> | null;
    /**
     * Верны ли учетные данные  true - верны   false - не верны
     * @type {boolean}
     * @memberof Provider
     */
    isCredentialCorrect?: boolean;
    /**
     * Признак поставщика с мгновенным заказом    true - заказ мгновенный   false - заказ на стороне поставщика
     * @type {boolean}
     * @memberof Provider
     */
    instantOrderAvailable: boolean;
}
/**
 *
 * @export
 * @interface ProviderError
 */
export interface ProviderError {
    /**
     * ИД поставщика
     * @type {string}
     * @memberof ProviderError
     */
    providerId: string;
    /**
     * Название поставщика
     * @type {string}
     * @memberof ProviderError
     */
    providerName: string;
    /**
     * Код http
     * @type {string}
     * @memberof ProviderError
     */
    errorCode: string;
}
/**
 * Интегратор (не привязанный к пользователю)
 * @export
 * @interface ProviderItem
 */
export interface ProviderItem {
    /**
     * provider Id
     * @type {string}
     * @memberof ProviderItem
     */
    id: string;
    /**
     * Название
     * @type {string}
     * @memberof ProviderItem
     */
    name: string;
    /**
     * логотип (сделан на будущее)
     * @type {string}
     * @memberof ProviderItem
     */
    logo?: string;
    /**
     *
     * @type {Array<ConnectAttributeItem>}
     * @memberof ProviderItem
     */
    connectAttribute: Array<ConnectAttributeItem>;
    /**
     * Признак поставщика с мгновенным заказом    true - заказ мгновенный   false - заказ на стороне поставщика
     * @type {boolean}
     * @memberof ProviderItem
     */
    instantOrderAvailable: boolean;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ResponseCodeModel = {
    ValidationError: 'ValidationError',
    InternalServerError: 'InternalServerError',
    UnknownError: 'UnknownError',
    LoginOrPasswordIncorrect: 'LoginOrPasswordIncorrect',
    RegistrationTokenNotFound: 'RegistrationTokenNotFound',
    RegistrationTokenInvalid: 'RegistrationTokenInvalid',
    PersonalDataConsentError: 'PersonalDataConsentError',
    RefreshTokenInvalid: 'RefreshTokenInvalid',
    OtpMismatchError: 'OtpMismatchError',
    OtpAttemptsLimitError: 'OtpAttemptsLimitError',
    UserAlreadyRegistered: 'UserAlreadyRegistered',
    CaptchaTokenInvalid: 'CaptchaTokenInvalid',
    CaptchaChallengeRequired: 'CaptchaChallengeRequired',
    LanguageNotFound: 'LanguageNotFound',
    UserNotFound: 'UserNotFound',
    AttemptTokenNotFound: 'AttemptTokenNotFound',
    ResendOtpTimeoutError: 'ResendOtpTimeoutError',
    ShareLimitExceeded: 'ShareLimitExceeded',
    TimeoutExceeded: 'TimeoutExceeded',
    InvalidRegistrationToken: 'InvalidRegistrationToken',
    EmailAlreadyExists: 'EmailAlreadyExists',
    EmailInvalid: 'EmailInvalid',
    PasswordInvalid: 'PasswordInvalid',
    JsonInvalid: 'JSONInvalid',
    ProviderIdInvalid: 'ProviderIDInvalid',
    ProviderAttributeInvalid: 'ProviderAttributeInvalid',
    EncryptionKeyNotFound: 'EncryptionKeyNotFound',
    ProviderNotConnected: 'ProviderNotConnected',
    VendorCodeInvalid: 'VendorCodeInvalid',
    ProductIdInvalid: 'ProductIDInvalid',
    WarehouseIdInvalid: 'WarehouseIDInvalid',
    ProductNotFoundInCart: 'ProductNotFoundInCart',
    RefreshExpired: 'RefreshExpired',
    RefreshInvalid: 'RefreshInvalid',
    AccessExpired: 'AccessExpired',
    AccessExpiredYandex: 'AccessExpiredYandex',
    ClidInvalid: 'ClidInvalid',
    ProductNotFoundInFavorite: 'ProductNotFoundInFavorite',
    ProviderDeletedOrNotAdd: 'ProviderDeletedOrNotAdd',
    EmptyCart: 'EmptyCart',
    ProviderNotFoundInCart: 'ProviderNotFoundInCart',
} as const;

export type ResponseCodeModel =
    (typeof ResponseCodeModel)[keyof typeof ResponseCodeModel];

/**
 *
 * @export
 * @enum {string}
 */

export const ResponseCodeModel400 = {
    ProductIdInvalid: 'ProductIDInvalid',
    WarehouseIdInvalid: 'WarehouseIDInvalid',
    RequiredQuantityIsNotAvailable: 'RequiredQuantityIsNotAvailable',
} as const;

export type ResponseCodeModel400 =
    (typeof ResponseCodeModel400)[keyof typeof ResponseCodeModel400];

/**
 *
 * @export
 * @interface V1CartExportResponse
 */
export interface V1CartExportResponse {
    /**
     *
     * @type {string}
     * @memberof V1CartExportResponse
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof V1CartExportResponse
     */
    name: string;
    /**
     *
     * @type {string}
     * @memberof V1CartExportResponse
     */
    cartUrl: string;
    /**
     *
     * @type {string}
     * @memberof V1CartExportResponse
     */
    error?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const V1CartProductCodeEnum = {
    Ok: 'OK',
    OutOfStockError: 'OutOfStockError',
} as const;

export type V1CartProductCodeEnum =
    (typeof V1CartProductCodeEnum)[keyof typeof V1CartProductCodeEnum];

/**
 *
 * @export
 * @interface V1CartProductRequest
 */
export interface V1CartProductRequest {
    /**
     * ID товара
     * @type {string}
     * @memberof V1CartProductRequest
     */
    productId: string;
    /**
     * ID Поставщика
     * @type {string}
     * @memberof V1CartProductRequest
     */
    providerId: string;
    /**
     * Идентификатор склада
     * @type {string}
     * @memberof V1CartProductRequest
     */
    warehouseId: string;
    /**
     * кол-во
     * @type {number}
     * @memberof V1CartProductRequest
     */
    quantity: number;
}
/**
 *
 * @export
 * @interface V1CartProductResponse
 */
export interface V1CartProductResponse {
    /**
     *
     * @type {string}
     * @memberof V1CartProductResponse
     */
    message: string;
    /**
     *
     * @type {V1CartProductCodeEnum}
     * @memberof V1CartProductResponse
     */
    code: V1CartProductCodeEnum;
}

/**
 *
 * @export
 * @interface V1CartResponse
 */
export interface V1CartResponse {
    /**
     * Идентификатор карзины пользователя
     * @type {string}
     * @memberof V1CartResponse
     */
    id: string;
    /**
     * Общая сумма заказа в рублях
     * @type {number}
     * @memberof V1CartResponse
     */
    totalAmount: number;
    /**
     * Общее количество товаров в корзине
     * @type {number}
     * @memberof V1CartResponse
     */
    totalQuantity: number;
    /**
     *
     * @type {Array<CartProductGroup>}
     * @memberof V1CartResponse
     */
    cart: Array<CartProductGroup>;
}
/**
 *
 * @export
 * @interface V1InfoResponse
 */
export interface V1InfoResponse {
    /**
     * Имя пользователя, указанное им в Яндекс ID.
     * @type {string}
     * @memberof V1InfoResponse
     */
    first_name?: string;
    /**
     * Фамилия пользователя, указанная им в Яндекс ID.
     * @type {string}
     * @memberof V1InfoResponse
     */
    last_name?: string;
    /**
     * Имя, которое отображается для данной учетной записи в интерфейсе Яндекса.
     * @type {string}
     * @memberof V1InfoResponse
     */
    display_name?: string;
    /**
     * Массив электронных адресов пользователя.
     * @type {string}
     * @memberof V1InfoResponse
     */
    emails?: string;
    /**
     * E-mail по умолчанию, предназначенный для связи с пользователем.
     * @type {string}
     * @memberof V1InfoResponse
     */
    default_email?: string;
    /**
     *
     * @type {V1InfoResponseDefaultPhone}
     * @memberof V1InfoResponse
     */
    default_phone?: V1InfoResponseDefaultPhone;
    /**
     * Имя и фамилия пользователя, указанные им в Яндекс ID.
     * @type {string}
     * @memberof V1InfoResponse
     */
    real_name?: string;
    /**
     * Признак того, что в поле default_avatar_id указан идентификатор заглушки
     * @type {string}
     * @memberof V1InfoResponse
     */
    is_avatar_empty?: string;
    /**
     * Дата рождения пользователя в формате ГГГГ-ММ-ДД.
     * @type {string}
     * @memberof V1InfoResponse
     */
    birthday?: string;
    /**
     * Идентификатор портрета пользователя Яндекса.  Портрет с данным идентификатором можно скачать по ссылке следующего формата:    https://avatars.yandex.net/get-yapic/<идентификатор портрета>/<размер>
     * @type {string}
     * @memberof V1InfoResponse
     */
    default_avatar_id?: string;
    /**
     * Логин пользователя на Яндексе.
     * @type {string}
     * @memberof V1InfoResponse
     */
    login?: string;
    /**
     *
     * @type {string}
     * @memberof V1InfoResponse
     */
    old_social_login?: string;
    /**
     * Пол пользователя. Возможные значения:    male — мужской;  female — женский;  неизвестный пол:  в JSON-документе обозначается ключом \"sex\": null;
     * @type {string}
     * @memberof V1InfoResponse
     */
    sex?: string;
    /**
     * Уникальный идентификатор пользователя Яндекса.
     * @type {string}
     * @memberof V1InfoResponse
     */
    id?: string;
    /**
     * Идентификатор приложения, для которого был выдан переданный в запросе OAuth-токен.
     * @type {string}
     * @memberof V1InfoResponse
     */
    client_id?: string;
    /**
     * Идентификатор авторизованного пользователя в Яндексе. Формируется на стороне Яндекса на основе пары client_id и user_id.
     * @type {string}
     * @memberof V1InfoResponse
     */
    psuid?: string;
}
/**
 * Телефон по умолчанию, предназначенный для связи с пользователем. Поле содержит параметры
 * @export
 * @interface V1InfoResponseDefaultPhone
 */
export interface V1InfoResponseDefaultPhone {
    /**
     * id — идентификатор номера телефона;
     * @type {string}
     * @memberof V1InfoResponseDefaultPhone
     */
    id?: string;
    /**
     * number — номер телефона пользователя.
     * @type {string}
     * @memberof V1InfoResponseDefaultPhone
     */
    number?: string;
}
/**
 *
 * @export
 * @interface V1ProductWarehousesResponse
 */
export interface V1ProductWarehousesResponse {
    /**
     *
     * @type {Array<Warehouse>}
     * @memberof V1ProductWarehousesResponse
     */
    warehouses: Array<Warehouse>;
}
/**
 *
 * @export
 * @interface V1ProviderCredentialRequest
 */
export interface V1ProviderCredentialRequest {
    /**
     * ID провайдера (интегратора, он же поставщик)
     * @type {string}
     * @memberof V1ProviderCredentialRequest
     */
    providerId: string;
    /**
     *
     * @type {Array<ConnectAttributeRequest>}
     * @memberof V1ProviderCredentialRequest
     */
    connectAttribute: Array<ConnectAttributeRequest>;
}
/**
 *
 * @export
 * @interface V1ProviderCredentialResponse
 */
export interface V1ProviderCredentialResponse {
    /**
     *
     * @type {Array<ConnectAttributeItem>}
     * @memberof V1ProviderCredentialResponse
     */
    attributes: Array<ConnectAttributeItem>;
}
/**
 *
 * @export
 * @interface V1ProviderListMissingResponse
 */
export interface V1ProviderListMissingResponse {
    /**
     *
     * @type {Array<ProviderItem>}
     * @memberof V1ProviderListMissingResponse
     */
    providers: Array<ProviderItem>;
}
/**
 *
 * @export
 * @interface V1ProviderListResponse
 */
export interface V1ProviderListResponse {
    /**
     *
     * @type {Array<ProviderItem>}
     * @memberof V1ProviderListResponse
     */
    providers: Array<ProviderItem>;
}
/**
 *
 * @export
 * @interface V1ProvidersCredentialRequest
 */
export interface V1ProvidersCredentialRequest {
    /**
     *
     * @type {Array<V1ProviderCredentialRequest>}
     * @memberof V1ProvidersCredentialRequest
     */
    providersCretential: Array<V1ProviderCredentialRequest>;
}
/**
 *
 * @export
 * @interface V1UserHistoryResponse
 */
export interface V1UserHistoryResponse {
    /**
     *
     * @type {Array<string>}
     * @memberof V1UserHistoryResponse
     */
    vendorCodes: Array<string>;
}
/**
 *
 * @export
 * @interface V1UserProfileResponse
 */
export interface V1UserProfileResponse {
    /**
     * Имя
     * @type {string}
     * @memberof V1UserProfileResponse
     */
    firstName: string;
    /**
     * Имя пользователя полученное через api Яндекс Паспорта
     * @type {string}
     * @memberof V1UserProfileResponse
     */
    lastName: string;
    /**
     * Логин пользователя
     * @type {string}
     * @memberof V1UserProfileResponse
     */
    login: string;
    /**
     * email полученный через api Яндекс Паспорта
     * @type {string}
     * @memberof V1UserProfileResponse
     */
    email: string;
    /**
     * Номер телефона полученный через api Яндекс Паспорта
     * @type {string}
     * @memberof V1UserProfileResponse
     */
    phone: string;
    /**
     * Клиент Id полученный через api Google
     * @type {string}
     * @memberof V1UserProfileResponse
     */
    clientID: string;
    /**
     * Аватар пользователя
     * @type {string}
     * @memberof V1UserProfileResponse
     */
    avatarURL: string;
    /**
     * Кол-во товаров в корзине
     * @type {number}
     * @memberof V1UserProfileResponse
     */
    cartAmount?: number | null;
}
/**
 *
 * @export
 * @interface V1UserProvidersResponse
 */
export interface V1UserProvidersResponse {
    /**
     *
     * @type {Array<Provider>}
     * @memberof V1UserProvidersResponse
     */
    provider?: Array<Provider>;
}
/**
 * Информация о товаре на складе
 * @export
 * @interface Warehouse
 */
export interface Warehouse {
    /**
     * идентификатор склада
     * @type {string}
     * @memberof Warehouse
     */
    id: string;
    /**
     * сокращенное наименование склада
     * @type {string}
     * @memberof Warehouse
     */
    shortName: string | null;
    /**
     * Поле содержит Дату и Время. Дата – это дата отгрузки товара. Время – это время, до какого часа можно заказать, что бы товар можно было отгрузить на эту дату.
     * @type {string}
     * @memberof Warehouse
     */
    shipmentDate: string | null;
    /**
     * Товар находится на нашем складе  true = \'на нашем складе\'  false = \'на складе партнеров\'
     * @type {boolean}
     * @memberof Warehouse
     */
    inStock: boolean;
    /**
     * Остаток на складе
     * @type {number}
     * @memberof Warehouse
     */
    stock: number;
    /**
     * Цена товара
     * @type {number}
     * @memberof Warehouse
     */
    price: number;
    /**
     * Невозвратная позиция  true = \'невозвратная\'  false = \'возвратная\'  null = \'возвратная\'
     * @type {boolean}
     * @memberof Warehouse
     */
    notRefund: boolean | null;
    /**
     * Состояние товара   true = \'б/у\'  false = \'новое\'  null = \'новое\'
     * @type {boolean}
     * @memberof Warehouse
     */
    used: boolean | null;
}

/**
 * AuthorizationApi - axios parameter creator
 * @export
 */
export const AuthorizationApiAxiosParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         * Отправка Clid при авторизации пользователя
         * @summary Отправка Clid при авторизации пользователя
         * @param {PostV1ClIdRequest} [postV1ClIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1Clid: async (
            postV1ClIdRequest?: PostV1ClIdRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/clid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                postV1ClIdRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Вход в систему из неавторизованной зоны
         * @summary post-v1-login
         * @param {CreateLoginHashPasswordRequest} [createLoginHashPasswordRequest]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postV1Login: async (
            createLoginHashPasswordRequest?: CreateLoginHashPasswordRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createLoginHashPasswordRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Метод закрытия сессии
         * @summary post-v1-logout
         * @param {CreateLogoutRequest} [createLogoutRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1Logout: async (
            createLogoutRequest?: CreateLogoutRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createLogoutRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Метод обновления сессии
         * @summary post-v1-refresh
         * @param {CreateRefreshModelRequest} [createRefreshModelRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1Refresh: async (
            createRefreshModelRequest?: CreateRefreshModelRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createRefreshModelRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Обновление OAuth-токен Яндекса
         * @summary Обновление OAuth-токен Яндекса
         * @param {CreateRefreshYandexModelRequest} [createRefreshYandexModelRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1RefreshYandex: async (
            createRefreshYandexModelRequest?: CreateRefreshYandexModelRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/refresh/yandex`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createRefreshYandexModelRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Метод запроса на регистрацию в системе
         * @summary post-v1-registration
         * @param {CreateRegistrationRequest} [createRegistrationRequest]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postV1Registration: async (
            createRegistrationRequest?: CreateRegistrationRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/registration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createRegistrationRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Метод завершения регистрации в системе
         * @summary post-v1-registration-completion
         * @param {CreateRegistrationInfoRequest} [createRegistrationInfoRequest]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postV1RegistrationCompletion: async (
            createRegistrationInfoRequest?: CreateRegistrationInfoRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/registration/completion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                createRegistrationInfoRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Вход в систему из неавторизованной зоны
         * @summary post-v2-login
         * @param {PostV2LoginRequest} postV2LoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2Login: async (
            postV2LoginRequest: PostV2LoginRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'postV2LoginRequest' is not null or undefined
            assertParamExists(
                'postV2Login',
                'postV2LoginRequest',
                postV2LoginRequest,
            );
            const localVarPath = `/v2/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                postV2LoginRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * AuthorizationApi - functional programming interface
 * @export
 */
export const AuthorizationApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator =
        AuthorizationApiAxiosParamCreator(configuration);
    return {
        /**
         * Отправка Clid при авторизации пользователя
         * @summary Отправка Clid при авторизации пользователя
         * @param {PostV1ClIdRequest} [postV1ClIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1Clid(
            postV1ClIdRequest?: PostV1ClIdRequest,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<PostV1ClIdResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.postV1Clid(
                    postV1ClIdRequest,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Вход в систему из неавторизованной зоны
         * @summary post-v1-login
         * @param {CreateLoginHashPasswordRequest} [createLoginHashPasswordRequest]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async postV1Login(
            createLoginHashPasswordRequest?: CreateLoginHashPasswordRequest,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<CreateAuthCredentialsResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.postV1Login(
                    createLoginHashPasswordRequest,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Метод закрытия сессии
         * @summary post-v1-logout
         * @param {CreateLogoutRequest} [createLogoutRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1Logout(
            createLogoutRequest?: CreateLogoutRequest,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.postV1Logout(
                    createLogoutRequest,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Метод обновления сессии
         * @summary post-v1-refresh
         * @param {CreateRefreshModelRequest} [createRefreshModelRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1Refresh(
            createRefreshModelRequest?: CreateRefreshModelRequest,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<CreateAuthCredentialsResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.postV1Refresh(
                    createRefreshModelRequest,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Обновление OAuth-токен Яндекса
         * @summary Обновление OAuth-токен Яндекса
         * @param {CreateRefreshYandexModelRequest} [createRefreshYandexModelRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1RefreshYandex(
            createRefreshYandexModelRequest?: CreateRefreshYandexModelRequest,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<CreateAuthCredentialsYandexResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.postV1RefreshYandex(
                    createRefreshYandexModelRequest,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Метод запроса на регистрацию в системе
         * @summary post-v1-registration
         * @param {CreateRegistrationRequest} [createRegistrationRequest]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async postV1Registration(
            createRegistrationRequest?: CreateRegistrationRequest,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<CreateRegistrationConfirmationResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.postV1Registration(
                    createRegistrationRequest,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Метод завершения регистрации в системе
         * @summary post-v1-registration-completion
         * @param {CreateRegistrationInfoRequest} [createRegistrationInfoRequest]
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async postV1RegistrationCompletion(
            createRegistrationInfoRequest?: CreateRegistrationInfoRequest,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<CreateAuthCredentialsResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.postV1RegistrationCompletion(
                    createRegistrationInfoRequest,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Вход в систему из неавторизованной зоны
         * @summary post-v2-login
         * @param {PostV2LoginRequest} postV2LoginRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV2Login(
            postV2LoginRequest: PostV2LoginRequest,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<PostV2LoginResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.postV2Login(
                    postV2LoginRequest,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
    };
};

/**
 * AuthorizationApi - factory interface
 * @export
 */
export const AuthorizationApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = AuthorizationApiFp(configuration);
    return {
        /**
         * Отправка Clid при авторизации пользователя
         * @summary Отправка Clid при авторизации пользователя
         * @param {AuthorizationApiPostV1ClidRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1Clid(
            requestParameters: AuthorizationApiPostV1ClidRequest = {},
            options?: AxiosRequestConfig,
        ): AxiosPromise<PostV1ClIdResponse> {
            return localVarFp
                .postV1Clid(requestParameters.postV1ClIdRequest, options)
                .then(request => request(axios, basePath));
        },
        /**
         * Вход в систему из неавторизованной зоны
         * @summary post-v1-login
         * @param {AuthorizationApiPostV1LoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postV1Login(
            requestParameters: AuthorizationApiPostV1LoginRequest = {},
            options?: AxiosRequestConfig,
        ): AxiosPromise<CreateAuthCredentialsResponse> {
            return localVarFp
                .postV1Login(
                    requestParameters.createLoginHashPasswordRequest,
                    options,
                )
                .then(request => request(axios, basePath));
        },
        /**
         * Метод закрытия сессии
         * @summary post-v1-logout
         * @param {AuthorizationApiPostV1LogoutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1Logout(
            requestParameters: AuthorizationApiPostV1LogoutRequest = {},
            options?: AxiosRequestConfig,
        ): AxiosPromise<void> {
            return localVarFp
                .postV1Logout(requestParameters.createLogoutRequest, options)
                .then(request => request(axios, basePath));
        },
        /**
         * Метод обновления сессии
         * @summary post-v1-refresh
         * @param {AuthorizationApiPostV1RefreshRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1Refresh(
            requestParameters: AuthorizationApiPostV1RefreshRequest = {},
            options?: AxiosRequestConfig,
        ): AxiosPromise<CreateAuthCredentialsResponse> {
            return localVarFp
                .postV1Refresh(
                    requestParameters.createRefreshModelRequest,
                    options,
                )
                .then(request => request(axios, basePath));
        },
        /**
         * Обновление OAuth-токен Яндекса
         * @summary Обновление OAuth-токен Яндекса
         * @param {AuthorizationApiPostV1RefreshYandexRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1RefreshYandex(
            requestParameters: AuthorizationApiPostV1RefreshYandexRequest = {},
            options?: AxiosRequestConfig,
        ): AxiosPromise<CreateAuthCredentialsYandexResponse> {
            return localVarFp
                .postV1RefreshYandex(
                    requestParameters.createRefreshYandexModelRequest,
                    options,
                )
                .then(request => request(axios, basePath));
        },
        /**
         * Метод запроса на регистрацию в системе
         * @summary post-v1-registration
         * @param {AuthorizationApiPostV1RegistrationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postV1Registration(
            requestParameters: AuthorizationApiPostV1RegistrationRequest = {},
            options?: AxiosRequestConfig,
        ): AxiosPromise<CreateRegistrationConfirmationResponse> {
            return localVarFp
                .postV1Registration(
                    requestParameters.createRegistrationRequest,
                    options,
                )
                .then(request => request(axios, basePath));
        },
        /**
         * Метод завершения регистрации в системе
         * @summary post-v1-registration-completion
         * @param {AuthorizationApiPostV1RegistrationCompletionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        postV1RegistrationCompletion(
            requestParameters: AuthorizationApiPostV1RegistrationCompletionRequest = {},
            options?: AxiosRequestConfig,
        ): AxiosPromise<CreateAuthCredentialsResponse> {
            return localVarFp
                .postV1RegistrationCompletion(
                    requestParameters.createRegistrationInfoRequest,
                    options,
                )
                .then(request => request(axios, basePath));
        },
        /**
         * Вход в систему из неавторизованной зоны
         * @summary post-v2-login
         * @param {AuthorizationApiPostV2LoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV2Login(
            requestParameters: AuthorizationApiPostV2LoginRequest,
            options?: AxiosRequestConfig,
        ): AxiosPromise<PostV2LoginResponse> {
            return localVarFp
                .postV2Login(requestParameters.postV2LoginRequest, options)
                .then(request => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for postV1Clid operation in AuthorizationApi.
 * @export
 * @interface AuthorizationApiPostV1ClidRequest
 */
export interface AuthorizationApiPostV1ClidRequest {
    /**
     *
     * @type {PostV1ClIdRequest}
     * @memberof AuthorizationApiPostV1Clid
     */
    readonly postV1ClIdRequest?: PostV1ClIdRequest;
}

/**
 * Request parameters for postV1Login operation in AuthorizationApi.
 * @export
 * @interface AuthorizationApiPostV1LoginRequest
 */
export interface AuthorizationApiPostV1LoginRequest {
    /**
     *
     * @type {CreateLoginHashPasswordRequest}
     * @memberof AuthorizationApiPostV1Login
     */
    readonly createLoginHashPasswordRequest?: CreateLoginHashPasswordRequest;
}

/**
 * Request parameters for postV1Logout operation in AuthorizationApi.
 * @export
 * @interface AuthorizationApiPostV1LogoutRequest
 */
export interface AuthorizationApiPostV1LogoutRequest {
    /**
     *
     * @type {CreateLogoutRequest}
     * @memberof AuthorizationApiPostV1Logout
     */
    readonly createLogoutRequest?: CreateLogoutRequest;
}

/**
 * Request parameters for postV1Refresh operation in AuthorizationApi.
 * @export
 * @interface AuthorizationApiPostV1RefreshRequest
 */
export interface AuthorizationApiPostV1RefreshRequest {
    /**
     *
     * @type {CreateRefreshModelRequest}
     * @memberof AuthorizationApiPostV1Refresh
     */
    readonly createRefreshModelRequest?: CreateRefreshModelRequest;
}

/**
 * Request parameters for postV1RefreshYandex operation in AuthorizationApi.
 * @export
 * @interface AuthorizationApiPostV1RefreshYandexRequest
 */
export interface AuthorizationApiPostV1RefreshYandexRequest {
    /**
     *
     * @type {CreateRefreshYandexModelRequest}
     * @memberof AuthorizationApiPostV1RefreshYandex
     */
    readonly createRefreshYandexModelRequest?: CreateRefreshYandexModelRequest;
}

/**
 * Request parameters for postV1Registration operation in AuthorizationApi.
 * @export
 * @interface AuthorizationApiPostV1RegistrationRequest
 */
export interface AuthorizationApiPostV1RegistrationRequest {
    /**
     *
     * @type {CreateRegistrationRequest}
     * @memberof AuthorizationApiPostV1Registration
     */
    readonly createRegistrationRequest?: CreateRegistrationRequest;
}

/**
 * Request parameters for postV1RegistrationCompletion operation in AuthorizationApi.
 * @export
 * @interface AuthorizationApiPostV1RegistrationCompletionRequest
 */
export interface AuthorizationApiPostV1RegistrationCompletionRequest {
    /**
     *
     * @type {CreateRegistrationInfoRequest}
     * @memberof AuthorizationApiPostV1RegistrationCompletion
     */
    readonly createRegistrationInfoRequest?: CreateRegistrationInfoRequest;
}

/**
 * Request parameters for postV2Login operation in AuthorizationApi.
 * @export
 * @interface AuthorizationApiPostV2LoginRequest
 */
export interface AuthorizationApiPostV2LoginRequest {
    /**
     *
     * @type {PostV2LoginRequest}
     * @memberof AuthorizationApiPostV2Login
     */
    readonly postV2LoginRequest: PostV2LoginRequest;
}

/**
 * AuthorizationApi - object-oriented interface
 * @export
 * @class AuthorizationApi
 * @extends {BaseAPI}
 */
export class AuthorizationApi extends BaseAPI {
    /**
     * Отправка Clid при авторизации пользователя
     * @summary Отправка Clid при авторизации пользователя
     * @param {AuthorizationApiPostV1ClidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public postV1Clid(
        requestParameters: AuthorizationApiPostV1ClidRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return AuthorizationApiFp(this.configuration)
            .postV1Clid(requestParameters.postV1ClIdRequest, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Вход в систему из неавторизованной зоны
     * @summary post-v1-login
     * @param {AuthorizationApiPostV1LoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public postV1Login(
        requestParameters: AuthorizationApiPostV1LoginRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return AuthorizationApiFp(this.configuration)
            .postV1Login(
                requestParameters.createLoginHashPasswordRequest,
                options,
            )
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Метод закрытия сессии
     * @summary post-v1-logout
     * @param {AuthorizationApiPostV1LogoutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public postV1Logout(
        requestParameters: AuthorizationApiPostV1LogoutRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return AuthorizationApiFp(this.configuration)
            .postV1Logout(requestParameters.createLogoutRequest, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Метод обновления сессии
     * @summary post-v1-refresh
     * @param {AuthorizationApiPostV1RefreshRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public postV1Refresh(
        requestParameters: AuthorizationApiPostV1RefreshRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return AuthorizationApiFp(this.configuration)
            .postV1Refresh(requestParameters.createRefreshModelRequest, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Обновление OAuth-токен Яндекса
     * @summary Обновление OAuth-токен Яндекса
     * @param {AuthorizationApiPostV1RefreshYandexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public postV1RefreshYandex(
        requestParameters: AuthorizationApiPostV1RefreshYandexRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return AuthorizationApiFp(this.configuration)
            .postV1RefreshYandex(
                requestParameters.createRefreshYandexModelRequest,
                options,
            )
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Метод запроса на регистрацию в системе
     * @summary post-v1-registration
     * @param {AuthorizationApiPostV1RegistrationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public postV1Registration(
        requestParameters: AuthorizationApiPostV1RegistrationRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return AuthorizationApiFp(this.configuration)
            .postV1Registration(
                requestParameters.createRegistrationRequest,
                options,
            )
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Метод завершения регистрации в системе
     * @summary post-v1-registration-completion
     * @param {AuthorizationApiPostV1RegistrationCompletionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public postV1RegistrationCompletion(
        requestParameters: AuthorizationApiPostV1RegistrationCompletionRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return AuthorizationApiFp(this.configuration)
            .postV1RegistrationCompletion(
                requestParameters.createRegistrationInfoRequest,
                options,
            )
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Вход в систему из неавторизованной зоны
     * @summary post-v2-login
     * @param {AuthorizationApiPostV2LoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorizationApi
     */
    public postV2Login(
        requestParameters: AuthorizationApiPostV2LoginRequest,
        options?: AxiosRequestConfig,
    ) {
        return AuthorizationApiFp(this.configuration)
            .postV2Login(requestParameters.postV2LoginRequest, options)
            .then(request => request(this.axios, this.basePath));
    }
}

/**
 * CartApi - axios parameter creator
 * @export
 */
export const CartApiAxiosParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         * Удаление всех корзины
         * @summary delete-v1-cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1Cart: async (
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'DELETE',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Удаления конкретного товара в корзине
         * @summary delete-v1-cart-product
         * @param {string} productId Ид товара
         * @param {string} warehouseId Ид склада
         * @param {string} providerId Ид поставщика
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1CartProduct: async (
            productId: string,
            warehouseId: string,
            providerId: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteV1CartProduct', 'productId', productId);
            // verify required parameter 'warehouseId' is not null or undefined
            assertParamExists(
                'deleteV1CartProduct',
                'warehouseId',
                warehouseId,
            );
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('deleteV1CartProduct', 'providerId', providerId);
            const localVarPath = `/v1/cart/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'DELETE',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            if (warehouseId !== undefined) {
                localVarQueryParameter['warehouseId'] = warehouseId;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Удаление группы товаров по поставщику
         * @summary Удаление группы товаров по поставщику
         * @param {string} providerId Идентификатор поставщика
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1CartProductGroup: async (
            providerId: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists(
                'deleteV1CartProductGroup',
                'providerId',
                providerId,
            );
            const localVarPath = `/v1/cart/productGroup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'DELETE',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получение списка товаров из корзины
         * @summary get-v1-cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1Cart: async (
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/cart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Передача корзины поставщику
         * @summary Экспорт корзины
         * @param {string} providerId Ид провайдера чью корзину экспортируют
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1CartExport: async (
            providerId: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getV1CartExport', 'providerId', providerId);
            const localVarPath = `/v1/cart/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Отправка товара в корзину
         * @summary post-v1-cart-product
         * @param {V1CartProductRequest} [v1CartProductRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1CartProduct: async (
            v1CartProductRequest?: V1CartProductRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/cart/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                v1CartProductRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * CartApi - functional programming interface
 * @export
 */
export const CartApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = CartApiAxiosParamCreator(configuration);
    return {
        /**
         * Удаление всех корзины
         * @summary delete-v1-cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1Cart(
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.deleteV1Cart(options);
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Удаления конкретного товара в корзине
         * @summary delete-v1-cart-product
         * @param {string} productId Ид товара
         * @param {string} warehouseId Ид склада
         * @param {string} providerId Ид поставщика
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1CartProduct(
            productId: string,
            warehouseId: string,
            providerId: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.deleteV1CartProduct(
                    productId,
                    warehouseId,
                    providerId,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Удаление группы товаров по поставщику
         * @summary Удаление группы товаров по поставщику
         * @param {string} providerId Идентификатор поставщика
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1CartProductGroup(
            providerId: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.deleteV1CartProductGroup(
                    providerId,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Получение списка товаров из корзины
         * @summary get-v1-cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1Cart(
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<V1CartResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.getV1Cart(options);
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Передача корзины поставщику
         * @summary Экспорт корзины
         * @param {string} providerId Ид провайдера чью корзину экспортируют
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1CartExport(
            providerId: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<V1CartExportResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.getV1CartExport(
                    providerId,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Отправка товара в корзину
         * @summary post-v1-cart-product
         * @param {V1CartProductRequest} [v1CartProductRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1CartProduct(
            v1CartProductRequest?: V1CartProductRequest,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<V1CartResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.postV1CartProduct(
                    v1CartProductRequest,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
    };
};

/**
 * CartApi - factory interface
 * @export
 */
export const CartApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = CartApiFp(configuration);
    return {
        /**
         * Удаление всех корзины
         * @summary delete-v1-cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1Cart(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp
                .deleteV1Cart(options)
                .then(request => request(axios, basePath));
        },
        /**
         * Удаления конкретного товара в корзине
         * @summary delete-v1-cart-product
         * @param {CartApiDeleteV1CartProductRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1CartProduct(
            requestParameters: CartApiDeleteV1CartProductRequest,
            options?: AxiosRequestConfig,
        ): AxiosPromise<void> {
            return localVarFp
                .deleteV1CartProduct(
                    requestParameters.productId,
                    requestParameters.warehouseId,
                    requestParameters.providerId,
                    options,
                )
                .then(request => request(axios, basePath));
        },
        /**
         * Удаление группы товаров по поставщику
         * @summary Удаление группы товаров по поставщику
         * @param {CartApiDeleteV1CartProductGroupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1CartProductGroup(
            requestParameters: CartApiDeleteV1CartProductGroupRequest,
            options?: AxiosRequestConfig,
        ): AxiosPromise<void> {
            return localVarFp
                .deleteV1CartProductGroup(requestParameters.providerId, options)
                .then(request => request(axios, basePath));
        },
        /**
         * Получение списка товаров из корзины
         * @summary get-v1-cart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1Cart(options?: AxiosRequestConfig): AxiosPromise<V1CartResponse> {
            return localVarFp
                .getV1Cart(options)
                .then(request => request(axios, basePath));
        },
        /**
         * Передача корзины поставщику
         * @summary Экспорт корзины
         * @param {CartApiGetV1CartExportRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1CartExport(
            requestParameters: CartApiGetV1CartExportRequest,
            options?: AxiosRequestConfig,
        ): AxiosPromise<V1CartExportResponse> {
            return localVarFp
                .getV1CartExport(requestParameters.providerId, options)
                .then(request => request(axios, basePath));
        },
        /**
         * Отправка товара в корзину
         * @summary post-v1-cart-product
         * @param {CartApiPostV1CartProductRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1CartProduct(
            requestParameters: CartApiPostV1CartProductRequest = {},
            options?: AxiosRequestConfig,
        ): AxiosPromise<V1CartResponse> {
            return localVarFp
                .postV1CartProduct(
                    requestParameters.v1CartProductRequest,
                    options,
                )
                .then(request => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteV1CartProduct operation in CartApi.
 * @export
 * @interface CartApiDeleteV1CartProductRequest
 */
export interface CartApiDeleteV1CartProductRequest {
    /**
     * Ид товара
     * @type {string}
     * @memberof CartApiDeleteV1CartProduct
     */
    readonly productId: string;

    /**
     * Ид склада
     * @type {string}
     * @memberof CartApiDeleteV1CartProduct
     */
    readonly warehouseId: string;

    /**
     * Ид поставщика
     * @type {string}
     * @memberof CartApiDeleteV1CartProduct
     */
    readonly providerId: string;
}

/**
 * Request parameters for deleteV1CartProductGroup operation in CartApi.
 * @export
 * @interface CartApiDeleteV1CartProductGroupRequest
 */
export interface CartApiDeleteV1CartProductGroupRequest {
    /**
     * Идентификатор поставщика
     * @type {string}
     * @memberof CartApiDeleteV1CartProductGroup
     */
    readonly providerId: string;
}

/**
 * Request parameters for getV1CartExport operation in CartApi.
 * @export
 * @interface CartApiGetV1CartExportRequest
 */
export interface CartApiGetV1CartExportRequest {
    /**
     * Ид провайдера чью корзину экспортируют
     * @type {string}
     * @memberof CartApiGetV1CartExport
     */
    readonly providerId: string;
}

/**
 * Request parameters for postV1CartProduct operation in CartApi.
 * @export
 * @interface CartApiPostV1CartProductRequest
 */
export interface CartApiPostV1CartProductRequest {
    /**
     *
     * @type {V1CartProductRequest}
     * @memberof CartApiPostV1CartProduct
     */
    readonly v1CartProductRequest?: V1CartProductRequest;
}

/**
 * CartApi - object-oriented interface
 * @export
 * @class CartApi
 * @extends {BaseAPI}
 */
export class CartApi extends BaseAPI {
    /**
     * Удаление всех корзины
     * @summary delete-v1-cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public deleteV1Cart(options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration)
            .deleteV1Cart(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Удаления конкретного товара в корзине
     * @summary delete-v1-cart-product
     * @param {CartApiDeleteV1CartProductRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public deleteV1CartProduct(
        requestParameters: CartApiDeleteV1CartProductRequest,
        options?: AxiosRequestConfig,
    ) {
        return CartApiFp(this.configuration)
            .deleteV1CartProduct(
                requestParameters.productId,
                requestParameters.warehouseId,
                requestParameters.providerId,
                options,
            )
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Удаление группы товаров по поставщику
     * @summary Удаление группы товаров по поставщику
     * @param {CartApiDeleteV1CartProductGroupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public deleteV1CartProductGroup(
        requestParameters: CartApiDeleteV1CartProductGroupRequest,
        options?: AxiosRequestConfig,
    ) {
        return CartApiFp(this.configuration)
            .deleteV1CartProductGroup(requestParameters.providerId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Получение списка товаров из корзины
     * @summary get-v1-cart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public getV1Cart(options?: AxiosRequestConfig) {
        return CartApiFp(this.configuration)
            .getV1Cart(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Передача корзины поставщику
     * @summary Экспорт корзины
     * @param {CartApiGetV1CartExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public getV1CartExport(
        requestParameters: CartApiGetV1CartExportRequest,
        options?: AxiosRequestConfig,
    ) {
        return CartApiFp(this.configuration)
            .getV1CartExport(requestParameters.providerId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Отправка товара в корзину
     * @summary post-v1-cart-product
     * @param {CartApiPostV1CartProductRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public postV1CartProduct(
        requestParameters: CartApiPostV1CartProductRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return CartApiFp(this.configuration)
            .postV1CartProduct(requestParameters.v1CartProductRequest, options)
            .then(request => request(this.axios, this.basePath));
    }
}

/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         * Удаление товара из избранного
         * @summary Удаление товара из избранного
         * @param {string} productId ИД товара
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ProductFavorites: async (
            productId: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists(
                'deleteV1ProductFavorites',
                'productId',
                productId,
            );
            const localVarPath = `/v1/product/favorites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'DELETE',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получение товаров по артикулу
         * @summary get-v1-product
         * @param {string} vendorCode Код вендора
         * @param {string} [limit] Лимит
         * @param {string} [page] Пагинация
         * @param {string} [brand] Фильтрация (PartTypeGroup) по производителю
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1Product: async (
            vendorCode: string,
            limit?: string,
            page?: string,
            brand?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'vendorCode' is not null or undefined
            assertParamExists('getV1Product', 'vendorCode', vendorCode);
            const localVarPath = `/v1/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (vendorCode !== undefined) {
                localVarQueryParameter['vendorCode'] = vendorCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (brand !== undefined) {
                localVarQueryParameter['brand'] = brand;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получение списка избранных товаров
         * @summary Получение списка избранных товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1ProductFavoritesList: async (
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/product/favoritesList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получение общего кол-ва избранных товаров пользователя
         * @summary Получение общего кол-ва избранных товаров пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1ProductFavoritesListTotalQuantity: async (
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/product/favoritesList/totalQuantity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получение складов для конкретного товара
         * @summary Получение складов товара
         * @param {string} productId Ид продукта, для которого ищем склады
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1ProductWarehouses: async (
            productId: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getV1ProductWarehouses', 'productId', productId);
            const localVarPath = `/v1/product/warehouses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (productId !== undefined) {
                localVarQueryParameter['productId'] = productId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Добавление товара в избранное
         * @summary Добавление товара в избранное
         * @param {ProductFavoriteRequest} [productFavoriteRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1ProductFavorites: async (
            productFavoriteRequest?: ProductFavoriteRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/product/favorites`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                productFavoriteRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator =
        ProductsApiAxiosParamCreator(configuration);
    return {
        /**
         * Удаление товара из избранного
         * @summary Удаление товара из избранного
         * @param {string} productId ИД товара
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1ProductFavorites(
            productId: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.deleteV1ProductFavorites(
                    productId,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Получение товаров по артикулу
         * @summary get-v1-product
         * @param {string} vendorCode Код вендора
         * @param {string} [limit] Лимит
         * @param {string} [page] Пагинация
         * @param {string} [brand] Фильтрация (PartTypeGroup) по производителю
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1Product(
            vendorCode: string,
            limit?: string,
            page?: string,
            brand?: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<PartTypeGroupsResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.getV1Product(
                    vendorCode,
                    limit,
                    page,
                    brand,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Получение списка избранных товаров
         * @summary Получение списка избранных товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1ProductFavoritesList(
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<ProductFavorites>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.getV1ProductFavoritesList(
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Получение общего кол-ва избранных товаров пользователя
         * @summary Получение общего кол-ва избранных товаров пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1ProductFavoritesListTotalQuantity(
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<FavoritesListTotalQuantity>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.getV1ProductFavoritesListTotalQuantity(
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Получение складов для конкретного товара
         * @summary Получение складов товара
         * @param {string} productId Ид продукта, для которого ищем склады
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1ProductWarehouses(
            productId: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<V1ProductWarehousesResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.getV1ProductWarehouses(
                    productId,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Добавление товара в избранное
         * @summary Добавление товара в избранное
         * @param {ProductFavoriteRequest} [productFavoriteRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1ProductFavorites(
            productFavoriteRequest?: ProductFavoriteRequest,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.postV1ProductFavorites(
                    productFavoriteRequest,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
    };
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = ProductsApiFp(configuration);
    return {
        /**
         * Удаление товара из избранного
         * @summary Удаление товара из избранного
         * @param {ProductsApiDeleteV1ProductFavoritesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1ProductFavorites(
            requestParameters: ProductsApiDeleteV1ProductFavoritesRequest,
            options?: AxiosRequestConfig,
        ): AxiosPromise<void> {
            return localVarFp
                .deleteV1ProductFavorites(requestParameters.productId, options)
                .then(request => request(axios, basePath));
        },
        /**
         * Получение товаров по артикулу
         * @summary get-v1-product
         * @param {ProductsApiGetV1ProductRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1Product(
            requestParameters: ProductsApiGetV1ProductRequest,
            options?: AxiosRequestConfig,
        ): AxiosPromise<PartTypeGroupsResponse> {
            return localVarFp
                .getV1Product(
                    requestParameters.vendorCode,
                    requestParameters.limit,
                    requestParameters.page,
                    requestParameters.brand,
                    options,
                )
                .then(request => request(axios, basePath));
        },
        /**
         * Получение списка избранных товаров
         * @summary Получение списка избранных товаров
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1ProductFavoritesList(
            options?: AxiosRequestConfig,
        ): AxiosPromise<ProductFavorites> {
            return localVarFp
                .getV1ProductFavoritesList(options)
                .then(request => request(axios, basePath));
        },
        /**
         * Получение общего кол-ва избранных товаров пользователя
         * @summary Получение общего кол-ва избранных товаров пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1ProductFavoritesListTotalQuantity(
            options?: AxiosRequestConfig,
        ): AxiosPromise<FavoritesListTotalQuantity> {
            return localVarFp
                .getV1ProductFavoritesListTotalQuantity(options)
                .then(request => request(axios, basePath));
        },
        /**
         * Получение складов для конкретного товара
         * @summary Получение складов товара
         * @param {ProductsApiGetV1ProductWarehousesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1ProductWarehouses(
            requestParameters: ProductsApiGetV1ProductWarehousesRequest,
            options?: AxiosRequestConfig,
        ): AxiosPromise<V1ProductWarehousesResponse> {
            return localVarFp
                .getV1ProductWarehouses(requestParameters.productId, options)
                .then(request => request(axios, basePath));
        },
        /**
         * Добавление товара в избранное
         * @summary Добавление товара в избранное
         * @param {ProductsApiPostV1ProductFavoritesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1ProductFavorites(
            requestParameters: ProductsApiPostV1ProductFavoritesRequest = {},
            options?: AxiosRequestConfig,
        ): AxiosPromise<void> {
            return localVarFp
                .postV1ProductFavorites(
                    requestParameters.productFavoriteRequest,
                    options,
                )
                .then(request => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteV1ProductFavorites operation in ProductsApi.
 * @export
 * @interface ProductsApiDeleteV1ProductFavoritesRequest
 */
export interface ProductsApiDeleteV1ProductFavoritesRequest {
    /**
     * ИД товара
     * @type {string}
     * @memberof ProductsApiDeleteV1ProductFavorites
     */
    readonly productId: string;
}

/**
 * Request parameters for getV1Product operation in ProductsApi.
 * @export
 * @interface ProductsApiGetV1ProductRequest
 */
export interface ProductsApiGetV1ProductRequest {
    /**
     * Код вендора
     * @type {string}
     * @memberof ProductsApiGetV1Product
     */
    readonly vendorCode: string;

    /**
     * Лимит
     * @type {string}
     * @memberof ProductsApiGetV1Product
     */
    readonly limit?: string;

    /**
     * Пагинация
     * @type {string}
     * @memberof ProductsApiGetV1Product
     */
    readonly page?: string;

    /**
     * Фильтрация (PartTypeGroup) по производителю
     * @type {string}
     * @memberof ProductsApiGetV1Product
     */
    readonly brand?: string;
}

/**
 * Request parameters for getV1ProductWarehouses operation in ProductsApi.
 * @export
 * @interface ProductsApiGetV1ProductWarehousesRequest
 */
export interface ProductsApiGetV1ProductWarehousesRequest {
    /**
     * Ид продукта, для которого ищем склады
     * @type {string}
     * @memberof ProductsApiGetV1ProductWarehouses
     */
    readonly productId: string;
}

/**
 * Request parameters for postV1ProductFavorites operation in ProductsApi.
 * @export
 * @interface ProductsApiPostV1ProductFavoritesRequest
 */
export interface ProductsApiPostV1ProductFavoritesRequest {
    /**
     *
     * @type {ProductFavoriteRequest}
     * @memberof ProductsApiPostV1ProductFavorites
     */
    readonly productFavoriteRequest?: ProductFavoriteRequest;
}

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * Удаление товара из избранного
     * @summary Удаление товара из избранного
     * @param {ProductsApiDeleteV1ProductFavoritesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public deleteV1ProductFavorites(
        requestParameters: ProductsApiDeleteV1ProductFavoritesRequest,
        options?: AxiosRequestConfig,
    ) {
        return ProductsApiFp(this.configuration)
            .deleteV1ProductFavorites(requestParameters.productId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Получение товаров по артикулу
     * @summary get-v1-product
     * @param {ProductsApiGetV1ProductRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getV1Product(
        requestParameters: ProductsApiGetV1ProductRequest,
        options?: AxiosRequestConfig,
    ) {
        return ProductsApiFp(this.configuration)
            .getV1Product(
                requestParameters.vendorCode,
                requestParameters.limit,
                requestParameters.page,
                requestParameters.brand,
                options,
            )
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Получение списка избранных товаров
     * @summary Получение списка избранных товаров
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getV1ProductFavoritesList(options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration)
            .getV1ProductFavoritesList(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Получение общего кол-ва избранных товаров пользователя
     * @summary Получение общего кол-ва избранных товаров пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getV1ProductFavoritesListTotalQuantity(
        options?: AxiosRequestConfig,
    ) {
        return ProductsApiFp(this.configuration)
            .getV1ProductFavoritesListTotalQuantity(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Получение складов для конкретного товара
     * @summary Получение складов товара
     * @param {ProductsApiGetV1ProductWarehousesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getV1ProductWarehouses(
        requestParameters: ProductsApiGetV1ProductWarehousesRequest,
        options?: AxiosRequestConfig,
    ) {
        return ProductsApiFp(this.configuration)
            .getV1ProductWarehouses(requestParameters.productId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Добавление товара в избранное
     * @summary Добавление товара в избранное
     * @param {ProductsApiPostV1ProductFavoritesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public postV1ProductFavorites(
        requestParameters: ProductsApiPostV1ProductFavoritesRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return ProductsApiFp(this.configuration)
            .postV1ProductFavorites(
                requestParameters.productFavoriteRequest,
                options,
            )
            .then(request => request(this.axios, this.basePath));
    }
}

/**
 * ProvidersApi - axios parameter creator
 * @export
 */
export const ProvidersApiAxiosParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         * Получее списка атрибутов, необходимых для заполнения, для подключения к интегратору
         * @summary get-v1-provider-credential
         * @param {string} [providerId] Ид провайдера
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1ProviderCredential: async (
            providerId?: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/provider/credential`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получение списка не добавленных пользователем поставщиков
         * @summary Получение списка не добавленных пользователем поставщиков
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1ProviderListAvailable: async (
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/provider/list/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получения списка существующих интеграторов
         * @summary get-v1-providers-list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1ProvidersList: async (
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/provider/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Изменить креды интегратора для подключения
         * @summary post-v1-provider-credential
         * @param {V1ProviderCredentialRequest} [v1ProviderCredentialRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1ProviderCredential: async (
            v1ProviderCredentialRequest?: V1ProviderCredentialRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/provider/credential`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                v1ProviderCredentialRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Создание кредов для подключению к интеграторам
         * @summary Создание кредов для подключению к интеграторам
         * @param {V1ProvidersCredentialRequest} [v1ProvidersCredentialRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1ProvidersCredential: async (
            v1ProvidersCredentialRequest?: V1ProvidersCredentialRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/providers/credential`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                v1ProvidersCredentialRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ProvidersApi - functional programming interface
 * @export
 */
export const ProvidersApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator =
        ProvidersApiAxiosParamCreator(configuration);
    return {
        /**
         * Получее списка атрибутов, необходимых для заполнения, для подключения к интегратору
         * @summary get-v1-provider-credential
         * @param {string} [providerId] Ид провайдера
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1ProviderCredential(
            providerId?: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<V1ProviderCredentialResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.getV1ProviderCredential(
                    providerId,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Получение списка не добавленных пользователем поставщиков
         * @summary Получение списка не добавленных пользователем поставщиков
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1ProviderListAvailable(
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<V1ProviderListMissingResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.getV1ProviderListAvailable(
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Получения списка существующих интеграторов
         * @summary get-v1-providers-list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1ProvidersList(
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<V1ProviderListResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.getV1ProvidersList(options);
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Изменить креды интегратора для подключения
         * @summary post-v1-provider-credential
         * @param {V1ProviderCredentialRequest} [v1ProviderCredentialRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1ProviderCredential(
            v1ProviderCredentialRequest?: V1ProviderCredentialRequest,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<V1UserProvidersResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.postV1ProviderCredential(
                    v1ProviderCredentialRequest,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Создание кредов для подключению к интеграторам
         * @summary Создание кредов для подключению к интеграторам
         * @param {V1ProvidersCredentialRequest} [v1ProvidersCredentialRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postV1ProvidersCredential(
            v1ProvidersCredentialRequest?: V1ProvidersCredentialRequest,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<V1UserProvidersResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.postV1ProvidersCredential(
                    v1ProvidersCredentialRequest,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
    };
};

/**
 * ProvidersApi - factory interface
 * @export
 */
export const ProvidersApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = ProvidersApiFp(configuration);
    return {
        /**
         * Получее списка атрибутов, необходимых для заполнения, для подключения к интегратору
         * @summary get-v1-provider-credential
         * @param {ProvidersApiGetV1ProviderCredentialRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1ProviderCredential(
            requestParameters: ProvidersApiGetV1ProviderCredentialRequest = {},
            options?: AxiosRequestConfig,
        ): AxiosPromise<V1ProviderCredentialResponse> {
            return localVarFp
                .getV1ProviderCredential(requestParameters.providerId, options)
                .then(request => request(axios, basePath));
        },
        /**
         * Получение списка не добавленных пользователем поставщиков
         * @summary Получение списка не добавленных пользователем поставщиков
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1ProviderListAvailable(
            options?: AxiosRequestConfig,
        ): AxiosPromise<V1ProviderListMissingResponse> {
            return localVarFp
                .getV1ProviderListAvailable(options)
                .then(request => request(axios, basePath));
        },
        /**
         * Получения списка существующих интеграторов
         * @summary get-v1-providers-list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1ProvidersList(
            options?: AxiosRequestConfig,
        ): AxiosPromise<V1ProviderListResponse> {
            return localVarFp
                .getV1ProvidersList(options)
                .then(request => request(axios, basePath));
        },
        /**
         * Изменить креды интегратора для подключения
         * @summary post-v1-provider-credential
         * @param {ProvidersApiPostV1ProviderCredentialRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1ProviderCredential(
            requestParameters: ProvidersApiPostV1ProviderCredentialRequest = {},
            options?: AxiosRequestConfig,
        ): AxiosPromise<V1UserProvidersResponse> {
            return localVarFp
                .postV1ProviderCredential(
                    requestParameters.v1ProviderCredentialRequest,
                    options,
                )
                .then(request => request(axios, basePath));
        },
        /**
         * Создание кредов для подключению к интеграторам
         * @summary Создание кредов для подключению к интеграторам
         * @param {ProvidersApiPostV1ProvidersCredentialRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postV1ProvidersCredential(
            requestParameters: ProvidersApiPostV1ProvidersCredentialRequest = {},
            options?: AxiosRequestConfig,
        ): AxiosPromise<V1UserProvidersResponse> {
            return localVarFp
                .postV1ProvidersCredential(
                    requestParameters.v1ProvidersCredentialRequest,
                    options,
                )
                .then(request => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getV1ProviderCredential operation in ProvidersApi.
 * @export
 * @interface ProvidersApiGetV1ProviderCredentialRequest
 */
export interface ProvidersApiGetV1ProviderCredentialRequest {
    /**
     * Ид провайдера
     * @type {string}
     * @memberof ProvidersApiGetV1ProviderCredential
     */
    readonly providerId?: string;
}

/**
 * Request parameters for postV1ProviderCredential operation in ProvidersApi.
 * @export
 * @interface ProvidersApiPostV1ProviderCredentialRequest
 */
export interface ProvidersApiPostV1ProviderCredentialRequest {
    /**
     *
     * @type {V1ProviderCredentialRequest}
     * @memberof ProvidersApiPostV1ProviderCredential
     */
    readonly v1ProviderCredentialRequest?: V1ProviderCredentialRequest;
}

/**
 * Request parameters for postV1ProvidersCredential operation in ProvidersApi.
 * @export
 * @interface ProvidersApiPostV1ProvidersCredentialRequest
 */
export interface ProvidersApiPostV1ProvidersCredentialRequest {
    /**
     *
     * @type {V1ProvidersCredentialRequest}
     * @memberof ProvidersApiPostV1ProvidersCredential
     */
    readonly v1ProvidersCredentialRequest?: V1ProvidersCredentialRequest;
}

/**
 * ProvidersApi - object-oriented interface
 * @export
 * @class ProvidersApi
 * @extends {BaseAPI}
 */
export class ProvidersApi extends BaseAPI {
    /**
     * Получее списка атрибутов, необходимых для заполнения, для подключения к интегратору
     * @summary get-v1-provider-credential
     * @param {ProvidersApiGetV1ProviderCredentialRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getV1ProviderCredential(
        requestParameters: ProvidersApiGetV1ProviderCredentialRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return ProvidersApiFp(this.configuration)
            .getV1ProviderCredential(requestParameters.providerId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Получение списка не добавленных пользователем поставщиков
     * @summary Получение списка не добавленных пользователем поставщиков
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getV1ProviderListAvailable(options?: AxiosRequestConfig) {
        return ProvidersApiFp(this.configuration)
            .getV1ProviderListAvailable(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Получения списка существующих интеграторов
     * @summary get-v1-providers-list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public getV1ProvidersList(options?: AxiosRequestConfig) {
        return ProvidersApiFp(this.configuration)
            .getV1ProvidersList(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Изменить креды интегратора для подключения
     * @summary post-v1-provider-credential
     * @param {ProvidersApiPostV1ProviderCredentialRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public postV1ProviderCredential(
        requestParameters: ProvidersApiPostV1ProviderCredentialRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return ProvidersApiFp(this.configuration)
            .postV1ProviderCredential(
                requestParameters.v1ProviderCredentialRequest,
                options,
            )
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Создание кредов для подключению к интеграторам
     * @summary Создание кредов для подключению к интеграторам
     * @param {ProvidersApiPostV1ProvidersCredentialRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvidersApi
     */
    public postV1ProvidersCredential(
        requestParameters: ProvidersApiPostV1ProvidersCredentialRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return ProvidersApiFp(this.configuration)
            .postV1ProvidersCredential(
                requestParameters.v1ProvidersCredentialRequest,
                options,
            )
            .then(request => request(this.axios, this.basePath));
    }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         * Удаление поставщика пользователя
         * @summary Удаление поставщика пользователя
         * @param {string} providerId Идентификатор поставщика
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1UserProviders: async (
            providerId: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists(
                'deleteV1UserProviders',
                'providerId',
                providerId,
            );
            const localVarPath = `/v1/user/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'DELETE',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получение общего кол-ва товаров в корзине
         * @summary Получение общего кол-ва товаров в корзине
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1UserCartAmount: async (
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/cartAmount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получение профиля пользователя
         * @summary get-v1-user-profile
         * @param {string} authToken Токен Яндекса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1UserProfile: async (
            authToken: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'authToken' is not null or undefined
            assertParamExists('getV1UserProfile', 'authToken', authToken);
            const localVarPath = `/v1/user/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (authToken !== undefined) {
                localVarQueryParameter['authToken'] = authToken;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получения списка поставщиков к которым подключен пользователь
         * @summary get-v1-user-providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1UserProviders: async (
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Получение пользовательской истории запросов
         * @summary Получение поисковых запросов пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1UserSearchHistory: async (
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/user/searchHistory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Замена Clid пользователя
         * @summary Замена Clid пользователя
         * @param {PostV1ClIdRequest} [postV1ClIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV1Clid: async (
            postV1ClIdRequest?: PostV1ClIdRequest,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            const localVarPath = `/v1/clid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'PUT',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication access-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                postV1ClIdRequest,
                localVarRequestOptions,
                configuration,
            );

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration);
    return {
        /**
         * Удаление поставщика пользователя
         * @summary Удаление поставщика пользователя
         * @param {string} providerId Идентификатор поставщика
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteV1UserProviders(
            providerId: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.deleteV1UserProviders(
                    providerId,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Получение общего кол-ва товаров в корзине
         * @summary Получение общего кол-ва товаров в корзине
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1UserCartAmount(
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<CartAmountResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.getV1UserCartAmount(options);
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Получение профиля пользователя
         * @summary get-v1-user-profile
         * @param {string} authToken Токен Яндекса
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1UserProfile(
            authToken: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<V1UserProfileResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.getV1UserProfile(
                    authToken,
                    options,
                );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Получения списка поставщиков к которым подключен пользователь
         * @summary get-v1-user-providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1UserProviders(
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<V1UserProvidersResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.getV1UserProviders(options);
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Получение пользовательской истории запросов
         * @summary Получение поисковых запросов пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1UserSearchHistory(
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<V1UserHistoryResponse>
        > {
            const localVarAxiosArgs =
                await localVarAxiosParamCreator.getV1UserSearchHistory(options);
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
        /**
         * Замена Clid пользователя
         * @summary Замена Clid пользователя
         * @param {PostV1ClIdRequest} [postV1ClIdRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putV1Clid(
            postV1ClIdRequest?: PostV1ClIdRequest,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<ClIdResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putV1Clid(
                postV1ClIdRequest,
                options,
            );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
    };
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = UserApiFp(configuration);
    return {
        /**
         * Удаление поставщика пользователя
         * @summary Удаление поставщика пользователя
         * @param {UserApiDeleteV1UserProvidersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteV1UserProviders(
            requestParameters: UserApiDeleteV1UserProvidersRequest,
            options?: AxiosRequestConfig,
        ): AxiosPromise<void> {
            return localVarFp
                .deleteV1UserProviders(requestParameters.providerId, options)
                .then(request => request(axios, basePath));
        },
        /**
         * Получение общего кол-ва товаров в корзине
         * @summary Получение общего кол-ва товаров в корзине
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1UserCartAmount(
            options?: AxiosRequestConfig,
        ): AxiosPromise<CartAmountResponse> {
            return localVarFp
                .getV1UserCartAmount(options)
                .then(request => request(axios, basePath));
        },
        /**
         * Получение профиля пользователя
         * @summary get-v1-user-profile
         * @param {UserApiGetV1UserProfileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1UserProfile(
            requestParameters: UserApiGetV1UserProfileRequest,
            options?: AxiosRequestConfig,
        ): AxiosPromise<V1UserProfileResponse> {
            return localVarFp
                .getV1UserProfile(requestParameters.authToken, options)
                .then(request => request(axios, basePath));
        },
        /**
         * Получения списка поставщиков к которым подключен пользователь
         * @summary get-v1-user-providers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1UserProviders(
            options?: AxiosRequestConfig,
        ): AxiosPromise<V1UserProvidersResponse> {
            return localVarFp
                .getV1UserProviders(options)
                .then(request => request(axios, basePath));
        },
        /**
         * Получение пользовательской истории запросов
         * @summary Получение поисковых запросов пользователя
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1UserSearchHistory(
            options?: AxiosRequestConfig,
        ): AxiosPromise<V1UserHistoryResponse> {
            return localVarFp
                .getV1UserSearchHistory(options)
                .then(request => request(axios, basePath));
        },
        /**
         * Замена Clid пользователя
         * @summary Замена Clid пользователя
         * @param {UserApiPutV1ClidRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putV1Clid(
            requestParameters: UserApiPutV1ClidRequest = {},
            options?: AxiosRequestConfig,
        ): AxiosPromise<ClIdResponse> {
            return localVarFp
                .putV1Clid(requestParameters.postV1ClIdRequest, options)
                .then(request => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteV1UserProviders operation in UserApi.
 * @export
 * @interface UserApiDeleteV1UserProvidersRequest
 */
export interface UserApiDeleteV1UserProvidersRequest {
    /**
     * Идентификатор поставщика
     * @type {string}
     * @memberof UserApiDeleteV1UserProviders
     */
    readonly providerId: string;
}

/**
 * Request parameters for getV1UserProfile operation in UserApi.
 * @export
 * @interface UserApiGetV1UserProfileRequest
 */
export interface UserApiGetV1UserProfileRequest {
    /**
     * Токен Яндекса
     * @type {string}
     * @memberof UserApiGetV1UserProfile
     */
    readonly authToken: string;
}

/**
 * Request parameters for putV1Clid operation in UserApi.
 * @export
 * @interface UserApiPutV1ClidRequest
 */
export interface UserApiPutV1ClidRequest {
    /**
     *
     * @type {PostV1ClIdRequest}
     * @memberof UserApiPutV1Clid
     */
    readonly postV1ClIdRequest?: PostV1ClIdRequest;
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Удаление поставщика пользователя
     * @summary Удаление поставщика пользователя
     * @param {UserApiDeleteV1UserProvidersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteV1UserProviders(
        requestParameters: UserApiDeleteV1UserProvidersRequest,
        options?: AxiosRequestConfig,
    ) {
        return UserApiFp(this.configuration)
            .deleteV1UserProviders(requestParameters.providerId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Получение общего кол-ва товаров в корзине
     * @summary Получение общего кол-ва товаров в корзине
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getV1UserCartAmount(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration)
            .getV1UserCartAmount(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Получение профиля пользователя
     * @summary get-v1-user-profile
     * @param {UserApiGetV1UserProfileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getV1UserProfile(
        requestParameters: UserApiGetV1UserProfileRequest,
        options?: AxiosRequestConfig,
    ) {
        return UserApiFp(this.configuration)
            .getV1UserProfile(requestParameters.authToken, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Получения списка поставщиков к которым подключен пользователь
     * @summary get-v1-user-providers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getV1UserProviders(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration)
            .getV1UserProviders(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Получение пользовательской истории запросов
     * @summary Получение поисковых запросов пользователя
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getV1UserSearchHistory(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration)
            .getV1UserSearchHistory(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Замена Clid пользователя
     * @summary Замена Clid пользователя
     * @param {UserApiPutV1ClidRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putV1Clid(
        requestParameters: UserApiPutV1ClidRequest = {},
        options?: AxiosRequestConfig,
    ) {
        return UserApiFp(this.configuration)
            .putV1Clid(requestParameters.postV1ClIdRequest, options)
            .then(request => request(this.axios, this.basePath));
    }
}

/**
 * YandexApi - axios parameter creator
 * @export
 */
export const YandexApiAxiosParamCreator = function (
    configuration?: Configuration,
) {
    return {
        /**
         * Получение объекта данных по пользователю
         * @summary Получение данных о пользователе
         * @param {'json' | 'xml' | 'jwt'} format Формат возвращаемых данных: json, xml, jwt
         * @param {string} jwtSecret секретный ключ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1Info: async (
            format: 'json' | 'xml' | 'jwt',
            jwtSecret: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'format' is not null or undefined
            assertParamExists('getV1Info', 'format', format);
            // verify required parameter 'jwtSecret' is not null or undefined
            assertParamExists('getV1Info', 'jwtSecret', jwtSecret);
            const localVarPath = `/v1/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKey_1 required
            // oauth required
            await setOAuthToObject(
                localVarHeaderParameter,
                'apiKey_1',
                [],
                configuration,
            );

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }

            if (jwtSecret !== undefined) {
                localVarQueryParameter['jwt_secret'] = jwtSecret;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions =
                baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * YandexApi - functional programming interface
 * @export
 */
export const YandexApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = YandexApiAxiosParamCreator(configuration);
    return {
        /**
         * Получение объекта данных по пользователю
         * @summary Получение данных о пользователе
         * @param {'json' | 'xml' | 'jwt'} format Формат возвращаемых данных: json, xml, jwt
         * @param {string} jwtSecret секретный ключ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getV1Info(
            format: 'json' | 'xml' | 'jwt',
            jwtSecret: string,
            options?: AxiosRequestConfig,
        ): Promise<
            (
                axios?: AxiosInstance,
                basePath?: string,
            ) => AxiosPromise<V1InfoResponse>
        > {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getV1Info(
                format,
                jwtSecret,
                options,
            );
            return createRequestFunction(
                localVarAxiosArgs,
                globalAxios,
                BASE_PATH,
                configuration,
            );
        },
    };
};

/**
 * YandexApi - factory interface
 * @export
 */
export const YandexApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = YandexApiFp(configuration);
    return {
        /**
         * Получение объекта данных по пользователю
         * @summary Получение данных о пользователе
         * @param {YandexApiGetV1InfoRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getV1Info(
            requestParameters: YandexApiGetV1InfoRequest,
            options?: AxiosRequestConfig,
        ): AxiosPromise<V1InfoResponse> {
            return localVarFp
                .getV1Info(
                    requestParameters.format,
                    requestParameters.jwtSecret,
                    options,
                )
                .then(request => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getV1Info operation in YandexApi.
 * @export
 * @interface YandexApiGetV1InfoRequest
 */
export interface YandexApiGetV1InfoRequest {
    /**
     * Формат возвращаемых данных: json, xml, jwt
     * @type {'json' | 'xml' | 'jwt'}
     * @memberof YandexApiGetV1Info
     */
    readonly format: 'json' | 'xml' | 'jwt';

    /**
     * секретный ключ
     * @type {string}
     * @memberof YandexApiGetV1Info
     */
    readonly jwtSecret: string;
}

/**
 * YandexApi - object-oriented interface
 * @export
 * @class YandexApi
 * @extends {BaseAPI}
 */
export class YandexApi extends BaseAPI {
    /**
     * Получение объекта данных по пользователю
     * @summary Получение данных о пользователе
     * @param {YandexApiGetV1InfoRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof YandexApi
     */
    public getV1Info(
        requestParameters: YandexApiGetV1InfoRequest,
        options?: AxiosRequestConfig,
    ) {
        return YandexApiFp(this.configuration)
            .getV1Info(
                requestParameters.format,
                requestParameters.jwtSecret,
                options,
            )
            .then(request => request(this.axios, this.basePath));
    }
}
