import { Stack } from '@mui/material';
import { ReactNode } from 'react';

import {
    TProductTable,
    TProductTableHeaderField,
    TSortableField,
} from '@entities/products/types';
import { Conditional } from '@shared/ui/atoms/conditional';

import { ProductItem } from '../product-item';
import { ProductTableHeader } from '../product-table-header';

type TProps = {
    sortableField?: TSortableField;
    tableHeader: TProductTableHeaderField[];
    products: TProductTable[];
    EmptyComponent?: ReactNode;
    renderControl: (item: TRenderItem) => ReactNode;
    onItemClick?: (productId: string) => void;
    onHeaderClick?: (productId: string) => void;
};

type TRenderItem = {
    position: number;
    providerId?: string;
    productId?: string;
    warehouseId?: string;
    brand?: string;
};
export const ProductTable = ({
    sortableField,
    tableHeader,
    products,
    EmptyComponent,
    renderControl,
    onItemClick,
    onHeaderClick,
}: TProps) => {
    return (
        <Stack direction="column" width="100%">
            <ProductTableHeader
                fields={tableHeader}
                onClick={onHeaderClick}
                sortableField={sortableField}
            />
            <Conditional condition={products.length < 1}>
                {EmptyComponent}
            </Conditional>
            <Stack overflow="auto" maxHeight="80dvh">
                {products.map((product, index) => (
                    <ProductItem
                        key={index}
                        fields={product.fields}
                        Control={renderControl({
                            position: index + 1,
                            providerId: product.providerId,
                            productId: product.id,
                            warehouseId: product.warehouseId,
                            brand: product.brand,
                        })}
                        onClick={() => onItemClick?.(product.id)}
                    />
                ))}
            </Stack>
        </Stack>
    );
};
