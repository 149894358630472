import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
type TProps = {
    title: string;
    imgUrl: string;
    text: string;
    onClick: () => void;
    onClose: () => void;
};

export const NewsCardDetails = ({
    title,
    imgUrl,
    text,
    onClick,
    onClose,
}: TProps) => {
    return (
        <Stack
            maxWidth="620px"
            width="100%"
            maxHeight="90dvh"
            height="100%"
            padding="20px"
            gap="20px"
            borderRadius="32px"
            sx={theme => ({ background: theme.palette.common.white })}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h4">{title}</Typography>
                <IconButton onClick={onClose} color="buttonPrimary">
                    <CloseIcon />
                </IconButton>
            </Stack>
            <Stack overflow="scroll" gap="20px">
                <Box
                    borderRadius="20px"
                    height="355px"
                    minHeight="355px"
                    sx={{
                        background: `url('${imgUrl}')`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                    }}
                />
                <Stack gap="10px">
                    <Typography variant="subtitle1">{title}</Typography>
                    <Typography
                        variant="body2"
                        dangerouslySetInnerHTML={{ __html: text }}
                    />
                </Stack>
            </Stack>
            <Button
                variant="contained"
                color="buttonPrimary"
                fullWidth
                onClick={onClick}
                sx={{ gap: '4px' }}>
                Подключить <ArrowOutwardIcon fontSize="small" />
            </Button>
        </Stack>
    );
};
