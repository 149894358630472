import { Box, Modal } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

import { useLogin } from '@entities/auth';
import { NewsCardDetails } from '@entities/news/ui/news-card-details';
import { mapNews } from '@entities/news/utils/mappers';
import {
    useLogoClick,
    useNavigateExternal,
    useYandexReachGoal,
} from '@shared/hooks';
import { mockNewsesData } from '@shared/mock';
import {
    useLoginStore,
    useSessionStore,
    useUserInfoStore,
} from '@shared/session';
import { Logo } from '@shared/ui/atoms/logo';
import { PageTemplate } from '@shared/ui/templates/page-template';
import { OAuthYandexConnector } from '@widgets/oauth-yandex';
import { TYandexAuthResponse } from '@widgets/oauth-yandex/oauth-yandex';

import { CompanyAuthPage } from './company-auth-page';

type TActiveBanner = {
    sectionId: string;
    bannerId: string;
};

export const CompanyAuthPageConnector = () => {
    const navigateExt = useNavigateExternal();
    const [modalBanner, setModalBanner] = useState<TActiveBanner>();
    const getMockNews = () => mockNewsesData;
    const onLogoClick = useLogoClick();
    const { enqueueSnackbar } = useSnackbar();
    const fireProductEvent = useYandexReachGoal();
    const sessionStore = useSessionStore();
    const userInfoStore = useUserInfoStore();
    const loginStore = useLoginStore();
    const { login } = useLogin({
        onSuccess: ({ data }) => {
            userInfoStore.update(data);
            sessionStore.set(data);
            loginStore.set(true);
        },
        onError: () => {
            enqueueSnackbar('что-то пошло не так', {
                variant: 'error',
                description: 'Повторите попытку',
            });
        },
    });
    const successHandler = (data: TYandexAuthResponse) => {
        if (data.access_token) {
            fireProductEvent('yandexOauth');
            userInfoStore.update({ yaToken: data.access_token });
            login({ authToken: data.access_token });
        }
    };
    const newses = mapNews(getMockNews());
    const modalBannerInfo = newses
        .find(news => news.id === modalBanner?.sectionId)
        ?.banners.find(banner => banner.id);

    const handleCloseModal = () => {
        setModalBanner(undefined);
    };
    return (
        <PageTemplate
            variant="medium"
            withHeadSpace
            Header={
                <Logo
                    color={theme => theme.palette.typography.primary}
                    sx={{ paddingTop: '5px', paddingRight: '10px' }}
                    onClick={onLogoClick}
                />
            }>
            <CompanyAuthPage
                onBannerClick={(sectionId, bannerId) =>
                    setModalBanner({ sectionId, bannerId })
                }
                newses={newses}
                AuthControl={
                    <OAuthYandexConnector
                        onSuccess={successHandler}
                        onError={() => null}
                    />
                }
            />
            <Modal open={Boolean(modalBanner)} onClose={handleCloseModal}>
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    sx={{ transform: 'translate(-50%, -50%)' }}>
                    <NewsCardDetails
                        title={modalBannerInfo?.title || ''}
                        imgUrl={modalBannerInfo?.pictureId || ''}
                        text={modalBannerInfo?.fullText || ''}
                        onClick={() => navigateExt(modalBannerInfo?.url || '')}
                        onClose={handleCloseModal}
                    />
                </Box>
            </Modal>
        </PageTemplate>
    );
};
