import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { useCartAmountStore } from '@entities/cart/model/cart-domain';
import { useGetProfile } from '@entities/user/model';
import { logoutPaths } from '@pages/logout/paths';
import { useUserInfoStore } from '@shared/session';

type TProps = {
    children: ReactElement | null;
};

export const UserInfoGuard = ({ children }: TProps) => {
    const userInfoStore = useUserInfoStore();
    const cartAmountStore = useCartAmountStore();
    const { refetch } = useGetProfile({
        body: { authToken: userInfoStore.userInfo?.yaToken || '' },
        onSuccess: data => {
            cartAmountStore.set(data.data.cartAmount || 0);
            userInfoStore.update(data.data);
        },
    });

    if (!userInfoStore.userInfo) {
        return <Navigate to={logoutPaths.home.absolute} />;
    }

    if (cartAmountStore.cartAmount === null) {
        refetch();
    }

    return children;
};
