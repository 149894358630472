import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import { TCounterVariant } from '@shared/ui/molecules/counter/counter';

import { FormCounter, TFormData } from './form-counter';

type TProps = {
    initValue: number;
    variant?: TCounterVariant;
    minValue?: number;
    maxValue?: number;
    onSubmit: (data: TFormData) => void;
};

export const FormCounterProvider = ({
    initValue,
    variant,
    minValue,
    maxValue,
    onSubmit,
}: TProps) => {
    const schema = z.object({
        counter: z.coerce
            .number({
                invalid_type_error: 'Сумма должна быть числом',
            })
            .int()
            .min(minValue || 0, {
                message: `Сумма должна быть больше ${minValue || 0}`,
            })
            .max(maxValue || Infinity, {
                message: `Сумма должна быть меньше ${maxValue || Infinity}`,
            }),
    });

    const methods = useForm<TFormData>({
        mode: 'onSubmit',
        resolver: zodResolver(schema),
        defaultValues: {
            counter: initValue,
        },
    });

    const handleSubmit = () => {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit, handleSubmit)}>
                <FormCounter
                    variant={variant}
                    minValue={minValue}
                    maxValue={maxValue}
                    onSubmit={methods.handleSubmit(onSubmit)}
                />
            </form>
        </FormProvider>
    );
};
