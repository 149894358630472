import { Navigate } from 'react-router-dom';

import { clientIdPaths } from '@pages/client-id/paths';
import { providerPaths } from '@pages/provider/paths';
import { useLogin, useUserInfo } from '@shared/session';

type TProps = {
    children: JSX.Element;
};

export const Public = ({ children }: TProps) => {
    const isLogin = useLogin();
    const userInfo = useUserInfo();
    if (!isLogin) {
        return children;
    }
    if (!userInfo?.clientId) {
        return <Navigate to={clientIdPaths.home.absolute} />;
    }
    return (
        <Navigate to={providerPaths.home.absolute} state={{ fromAuth: true }} />
    );
};
