import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Collapse, Grid, Stack } from '@mui/material';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';

import { TFormProvider } from '@entities/providers/types';
import { ProviderInfoCard } from '@entities/providers/ui/provider-info-card';
import { FormProviderSelectProvider } from '@features/form-provider-select';
import { TFormData } from '@features/form-provider-select/form-provider-select';
import { Conditional } from '@shared/ui/atoms/conditional';

type TProps = {
    providers: TFormProvider[];
    selectedProviders?: TFormProvider[];
    dirtyProviders: string[];
    isAddProvider: boolean;
    onCardClick: (id: string) => void;
    onChange: (data: TFormData) => void;
    onSkip: () => void;
    onSubmit: () => void;
    onProposition: () => void;
    setSelectedValues: Dispatch<SetStateAction<TFormProvider[]>>;
};

export const ProviderPage = ({
    selectedProviders,
    dirtyProviders,
    isAddProvider,
    providers,
    onCardClick,
    onChange,
    onSkip,
    onSubmit,
    onProposition,
    setSelectedValues,
}: TProps) => {
    const [isNoProviders, setIsNoProviders] = useState(false);
    const handleSubmit = () => {
        if (!selectedProviders?.length) {
            setIsNoProviders(true);
            return;
        }
        onSubmit();
    };
    useEffect(() => {
        if (selectedProviders?.length) {
            setIsNoProviders(false);
        }
    }, [selectedProviders]);
    return (
        <Grid
            container
            gap={{ xs: '8px', md: '0' }}
            paddingX={{ xs: '8px', md: 0 }}>
            <Grid item xs={0} md={2} />
            <Grid item xs={12} md={6}>
                <Stack direction="column">
                    <FormProviderSelectProvider
                        onChange={onChange}
                        providers={providers}
                        selectedValues={selectedProviders || []}
                        setSelectedValues={setSelectedValues}
                        isNoProviders={isNoProviders}
                    />

                    <TransitionGroup
                        style={{
                            gap: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                        {selectedProviders?.map(provider => (
                            <Collapse key={provider.id}>
                                <ProviderInfoCard
                                    onClick={() => onCardClick(provider.id)}
                                    title={provider.label}
                                    isDirty={dirtyProviders.includes(
                                        provider.id,
                                    )}
                                />
                            </Collapse>
                        ))}
                    </TransitionGroup>
                    <Box marginTop="40px">
                        <Stack
                            direction={{ xs: 'column', md: 'row' }}
                            gap={{ xs: '8px', md: '16px' }}>
                            <Button
                                size="medium"
                                variant="contained"
                                color="buttonPrimary"
                                sx={{ minWidth: '150px' }}
                                onClick={handleSubmit}>
                                сохранить
                            </Button>
                            <Conditional condition={!isAddProvider}>
                                <Button
                                    size="medium"
                                    variant="contained"
                                    color="buttonSecondaryEnabled"
                                    sx={{ minWidth: '150px' }}
                                    onClick={onSkip}>
                                    пропустить
                                </Button>
                            </Conditional>
                        </Stack>
                    </Box>
                </Stack>
            </Grid>
            <Conditional condition={isAddProvider}>
                <Grid item xs={12} md={4}>
                    <Stack
                        justifyContent="flex-end"
                        direction="row"
                        paddingRight={{ xs: 0, md: '40px' }}>
                        <Button
                            sx={{ width: { xs: '100%', md: 'auto' } }}
                            variant="contained"
                            color="buttonSecondaryEnabled"
                            onClick={onProposition}
                            startIcon={<AddIcon />}>
                            предложить поставщика
                        </Button>
                    </Stack>
                </Grid>
            </Conditional>
        </Grid>
    );
};
