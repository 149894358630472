import { Box, Button, Stack, Typography } from '@mui/material';

type TItem = {
    value: string;
    label: string;
};

type TProps = {
    items: TItem[];
    selectedItem: TItem;
    onChange: (newItem: TItem) => void;
};

export const RadioButtonGroup = ({ items, selectedItem, onChange }: TProps) => {
    return (
        <Stack direction="row" gap="8px" alignItems="center">
            {items.map((item, index) => (
                <Box key={index}>
                    <Button
                        sx={{
                            minWidth: '82px',
                            p: '6px 10px',
                            border:
                                item.value === selectedItem.value
                                    ? '1px solid'
                                    : undefined,
                        }}
                        variant={
                            item.value === selectedItem.value
                                ? 'contained'
                                : 'outlined'
                        }
                        color="buttonPrimary"
                        onClick={() => onChange(item)}>
                        <Typography variant="caption1" whiteSpace="nowrap">
                            {item.label}
                        </Typography>
                    </Button>
                </Box>
            ))}
        </Stack>
    );
};
