import { Box, Grid, Stack } from '@mui/material';
import { ReactNode } from 'react';

type TProps = {
    children?: ReactNode;
    Header?: ReactNode;
};

export const ErrorPageTemplate = ({ children, Header }: TProps) => {
    return (
        <Stack
            direction="column"
            minHeight="100dvh"
            width="100vw"
            position="relative"
            paddingLeft="40px">
            <Box width="100%" padding="20px 40px 0 0">
                {Header}
            </Box>
            <Grid container width="100%" flex={1} marginTop="10%">
                <Grid item xs={0} md={2} />
                <Grid item xs={12} md={10} flex={1}>
                    {children}
                </Grid>
            </Grid>
        </Stack>
    );
};
