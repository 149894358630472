import SearchIcon from '@mui/icons-material/Search';
import { Stack } from '@mui/material';

import { FormSearchSuggestProvider } from '@features/form-search-suggest';

type TProps = {
    initSearch: string;
    suggests: string[];
    onSubmit: (search: string) => void;
};

export const SearchPage = ({ initSearch, suggests, onSubmit }: TProps) => {
    return (
        <Stack sx={{ paddingX: { xs: '8px', md: 0 } }}>
            <Stack direction="row" width="100%" position="relative">
                <SearchIcon
                    sx={theme => ({
                        marginTop: '30px',
                        color: theme.palette.icon.primary,
                        fontSize: '30px',
                        left: '-40px',
                        position: 'absolute',
                    })}
                />
                <FormSearchSuggestProvider
                    onSubmit={data => onSubmit(data.search)}
                    initSearch={initSearch}
                    options={suggests}
                />
            </Stack>
        </Stack>
    );
};
