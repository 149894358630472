import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SnackbarProvider } from 'notistack';
import { ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { FeatureToggleSettings } from '@features/feature-toggle-settings';
import { PathfinderProvider } from '@features/pathfinder';
import { apiInstance, queryClient } from '@shared/api';
import { SessionProvider } from '@shared/session';
import { ErrorSnackbar } from '@shared/ui/molecules/error-snackbar';
import { InfoSnackbar } from '@shared/ui/molecules/info-snackbar';
import { GlobalStyles, ThemeProvider } from '@shared/ui/theme';

import { ErrorBoundaryProvider } from './providers/error-boundary';

type TProps = {
    children: ReactNode;
};

export const AppProviders = ({ children }: TProps) => {
    return (
        <SessionProvider axiosInstances={[apiInstance]}>
            <PathfinderProvider>
                <ThemeProvider>
                    <SnackbarProvider
                        preventDuplicate
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        Components={{
                            error: ErrorSnackbar,
                            info: InfoSnackbar,
                        }}
                        autoHideDuration={3000}>
                        <GlobalStyles />
                        <ErrorBoundaryProvider>
                            <FeatureToggleSettings />
                            <QueryClientProvider client={queryClient}>
                                <HelmetProvider>{children}</HelmetProvider>
                                <ReactQueryDevtools initialIsOpen={false} />
                            </QueryClientProvider>
                        </ErrorBoundaryProvider>
                    </SnackbarProvider>
                </ThemeProvider>
            </PathfinderProvider>
        </SessionProvider>
    );
};
