// Deprecated

import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { accountPaths } from '@pages/account/paths';
import { Dialog } from '@shared/ui/organisms/dialog';
import { InfoTemplate } from '@shared/ui/templates/info-template';
import { PageTemplate } from '@shared/ui/templates/page-template';
import { HeaderWidgetConnector } from '@widgets/header';

import { ProviderPropositionPage } from './provider-proposition-page';

export const ProviderPropositionPageConnector = () => {
    const navigate = useNavigate();
    const handleSubmit = useCallback(() => {
        setCurrentState('loading');
        setTimeout(() => {
            setCurrentState('success');
        }, 2000);
    }, []);

    const [currentState, setCurrentState] = useState<string>('init');
    const handleMore = useCallback(() => {
        setCurrentState('init');
    }, []);
    const handleOK = useCallback(() => {
        navigate(accountPaths.providers.absolute);
    }, [navigate]);
    if (currentState === 'loading') {
        return (
            <InfoTemplate
                Header={<HeaderWidgetConnector withLogo variant="secondary" />}
                title="один момент..."
                CenterSlot={
                    <Box color={theme => theme.palette.common.white}>
                        <CircularProgress color="inherit" size="80px" />
                    </Box>
                }
            />
        );
    }
    if (currentState === 'success') {
        return (
            <Dialog
                Header={<HeaderWidgetConnector withLogo variant="secondary" />}
                title="спасибо за предложение"
                helpedText="мы обязательно свяжемся с поставщиком"
                variant="success"
                primaryButtonText="хорошо"
                secondaryButtonText="предложить еще"
                onPrimaryButtonClick={handleOK}
                onSecondaryButtonClick={handleMore}
            />
        );
    }
    return (
        <PageTemplate
            withHeadSpace
            variant="small"
            Header={
                <HeaderWidgetConnector withLogo variant="primary" withProfile>
                    <Stack
                        direction="column"
                        gap="15px"
                        textAlign={{ xs: 'center', md: 'start' }}>
                        <Typography variant="h2">
                            предложить поставщика
                        </Typography>
                        <Typography
                            variant="body2"
                            color={theme => theme.palette.typography.secondary}>
                            Давно выяснено, что при оценке дизайна и композиции
                            читаемый текст мешает сосредоточиться. Lorem Ipsum
                            используют потому, что тот обеспечивает более
                        </Typography>
                    </Stack>
                </HeaderWidgetConnector>
            }>
            <ProviderPropositionPage onSubmit={handleSubmit} />
        </PageTemplate>
    );
};
