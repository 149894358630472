import { TNews } from '@entities/news/types';
import { TMockNewsesData } from '@shared/mock';

export const mapNews = (newses: TMockNewsesData): TNews[] => {
    return newses.sections.map(news => ({
        id: news.sectionId,
        title: news.name,
        banners: news.banners.map(banner => ({
            id: banner.bannerId,
            title: banner.title,
            pictureId: banner.pictureId,
            shortText: banner.shortText,
            fullText: banner.fullText,
            url: banner.url,
        })),
    }));
};
