import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { userKeys } from '@entities/user/model';
import { UnknownBusinessError, productApi } from '@shared/api';
import { ProductsApiGetV1ProductRequest } from '@shared/api/axios-client';

import { productsKeys } from '../query-keys';

type TBusinessError = UnknownBusinessError;

type TUseGetParts = {
    body: ProductsApiGetV1ProductRequest;
    onSuccess?: () => void;
    onError?: (businessError: TBusinessError) => void;
};

export const useGetProducts = ({ body, onSuccess, onError }: TUseGetParts) => {
    const queryClient = useQueryClient();
    return useQuery(
        productsKeys.list(body).queryKey,
        () => {
            return productApi.getV1Product(body, {
                headers: {
                    Authorization: 'Bearer',
                },
            });
        },
        {
            enabled: Boolean(body),
            onSuccess: data => {
                if (data.data.providersError?.length) {
                    queryClient.invalidateQueries(userKeys.providers.queryKey);
                }
                onSuccess && onSuccess();
            },
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!onError) {
                    return;
                }
                if (response?.data.code && response.data.message) {
                    onError(response.data);
                } else {
                    onError({
                        code: 'unknown_code',
                        message: 'что-то пошло не так',
                        response,
                    });
                }
            },
        },
    );
};
