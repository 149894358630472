import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { FeatureToggle, TFlags } from '@features/feature-toggle';
import { errorPaths } from '@pages/error/paths';

type TProps = {
    children: ReactElement | null;
    featureFlag: TFlags;
};

export const FeatureToggleGuard = ({ children, featureFlag }: TProps) => {
    const isEnabled = FeatureToggle.hasFeatureFlag(featureFlag);
    if (!isEnabled) {
        return <Navigate to={errorPaths.home.absolute} />;
    }

    return children;
};
