import { Box, Grid, Stack } from '@mui/material';
import { ReactNode } from 'react';

const variants = {
    small: {
        grid: 6,
    },
    medium: {
        grid: 8,
    },
    big: {
        grid: 12,
    },
};

type TProps = {
    children?: ReactNode;
    Header?: ReactNode;
    withHeadSpace?: boolean;
    variant: keyof typeof variants;
};

export const PageTemplate = ({
    children,
    variant,
    withHeadSpace,
    Header,
}: TProps) => {
    return (
        <Stack
            direction="column"
            minHeight="100dvh"
            width="100vw"
            padding="20px 40px 40px 40px">
            <Box width="100%">{Header}</Box>
            <Grid
                direction="row"
                container
                width="100%"
                flex={1}
                marginTop={{ md: withHeadSpace ? '10%' : 0 }}>
                <Grid item xs={0} md={variant === 'big' ? 0 : 2} />
                <Grid item xs={12} md={variants[variant].grid} flex={1}>
                    {children}
                </Grid>
            </Grid>
        </Stack>
    );
};
