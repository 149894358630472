import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback } from 'react';

import { productsKeys } from '@entities/products/model';
import { userKeys } from '@entities/user/model';
import { UnknownBusinessError, providersApi, queryClient } from '@shared/api';
import { V1ProviderCredentialRequest } from '@shared/api/axios-client';

import { providersKeys } from '../query-keys';

type TBusinessError = UnknownBusinessError;

type TUseChangeProviderCredential = {
    onSuccess?: () => void;
    onError?: (businessError: TBusinessError) => void;
};

export const useChangeProviderCredential = (
    props?: TUseChangeProviderCredential,
) => {
    const { mutateAsync, ...rest } = useMutation(
        providersKeys.change.queryKey,
        ({
            v1ProviderCredentialRequest,
        }: {
            v1ProviderCredentialRequest: V1ProviderCredentialRequest;
        }) => {
            return providersApi.postV1ProviderCredential(
                {
                    v1ProviderCredentialRequest,
                },
                {
                    headers: {
                        Authorization: 'Bearer',
                    },
                },
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(userKeys.providers.queryKey);
                queryClient.invalidateQueries(providersKeys.available.queryKey);
                queryClient.invalidateQueries(productsKeys.list._def);
                props?.onSuccess && props.onSuccess();
            },
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!props?.onError) {
                    return;
                }
                if (response?.data.code && response.data.message) {
                    props.onError(response.data);
                } else {
                    props.onError({
                        code: 'unknown_code',
                        message: 'что-то пошло не так',
                        response,
                    });
                }
            },
        },
    );

    const changeProviderCredential = useCallback(
        (v1ProviderCredentialRequest: V1ProviderCredentialRequest) => {
            return mutateAsync({
                v1ProviderCredentialRequest,
            });
        },
        [mutateAsync],
    );

    return { ...rest, changeProviderCredential };
};
