import { RouteObject } from 'react-router-dom';

import { NotFoundConnector } from './not-found';
import { errorPaths } from './paths';

export const routes: RouteObject[] = [
    {
        path: errorPaths.home.absolute,
        element: <NotFoundConnector />,
    },
    {
        path: '*',
        element: <NotFoundConnector />,
    },
];
