import { RouteObject } from 'react-router-dom';

import { MetaTitle } from '@shared/ui/atoms/meta-title';

import { ProviderPageConnector } from './home/provider-page-connector';
import { providerPaths } from './paths';
import { ProviderPropositionPageConnector } from './provider-proposition';

export const routes: RouteObject[] = [
    {
        path: providerPaths.home.absolute,
        element: (
            <>
                <MetaTitle>Поставщики</MetaTitle>
                <ProviderPageConnector />
            </>
        ),
    },
    {
        path: providerPaths.proposition.absolute,
        element: (
            <>
                <MetaTitle>Предложение поставщика</MetaTitle>
                <ProviderPropositionPageConnector />
            </>
        ),
    },
];
