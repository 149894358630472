import { create } from 'zustand';

type TFeatureToggleModalState = {
    isOpen: boolean;
    open: () => void;
    close: () => void;
    reset: () => void;
};

export const useFeatureToggleModalStore = create<TFeatureToggleModalState>()(
    set => ({
        isOpen: false,
        open: () => set({ isOpen: true }),
        close: () => set({ isOpen: false }),
        reset: () => set({ isOpen: false }),
    }),
);
