import {
    TFormDelivery,
    TFormDeliveryType,
    TFormState,
    TProductFilters,
} from '@entities/products/types';
import { ProductInfo } from '@shared/api/axios-client';
import { dateUtils } from '@shared/lib/utils';

const dayFilterToDate = (filter?: TFormDelivery['id']) => {
    const today = new Date();
    const numFilter = Number(filter);
    if (isNaN(numFilter)) {
        return;
    }
    today.setDate(today.getDate() + numFilter);
    return today;
};

export const filterProducts = (
    filters: TProductFilters,
    products: ProductInfo[],
    formState: TFormState[],
    formDeliveryType: TFormDeliveryType[],
    formDeliveryDate: TFormDelivery[],
) => {
    return products.reduce<ProductInfo[]>((result, product) => {
        if (
            (filters.providers &&
                filters.providers.length > 0 &&
                !filters.providers.includes(product.providers.id)) ||
            (filters.minPrice && filters.minPrice > product.warehouse.price) ||
            (filters.maxPrice && filters.maxPrice < product.warehouse.price) ||
            (filters.state &&
                !validateState(filters.state, product, formState)) ||
            (filters.type &&
                !validateType(filters.type, product, formDeliveryType)) ||
            (filters.delivery &&
                !validateDelivery(filters.delivery, product, formDeliveryDate))
        ) {
            return result;
        }
        return [...result, product];
    }, []);
};

const validateState = (
    state: string,
    product: ProductInfo,
    formState: TFormState[],
) => {
    const stateOption = formState.find(item => item.value === state);

    switch (stateOption?.value) {
        case undefined:
            return true;
        case 'used':
            return product.warehouse.used;
        case 'new':
            return !product.warehouse.used;
        default:
            return true;
    }
};

const validateType = (
    type: string,
    product: ProductInfo,
    formDeliveryType: TFormDeliveryType[],
) => {
    const typeOption = formDeliveryType.find(item => item.value === type);
    switch (typeOption?.value) {
        case 'delivery':
            return !!product.warehouse.shipmentDate;
        case 'pickup':
            return !product.warehouse.shipmentDate;
        default:
            return true;
    }
};

const validateDelivery = (
    delivery: string,
    product: ProductInfo,
    formDeliveryDate: TFormDelivery[],
) => {
    const deliveryFilter = formDeliveryDate.find(date => date.id === delivery);
    const deliveryDate = dayFilterToDate(deliveryFilter?.id);
    return !(
        deliveryDate &&
        deliveryDate <=
            dateUtils.validateDate(product.warehouse.shipmentDate || '')
    );
};
