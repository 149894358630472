import { Box, Button, Stack, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { NotFoundIcon } from '@shared/ui/icons';

type TProps = {
    navigateTo: string;
};

const IconWrapper = styled(Box)`
    position: absolute;
    width: 70%;
    bottom: -20px;
    right: 0;
`;

export const NotFound = ({ navigateTo }: TProps) => {
    const navigate = useNavigate();
    const onClickHandler = () => {
        navigate(navigateTo, { replace: true });
    };
    return (
        <Stack height="100%" overflow="hidden" position="relative">
            <Box>
                <Button
                    variant="contained"
                    color="buttonPrimary"
                    onClick={onClickHandler}>
                    вернуться на главную
                </Button>
            </Box>
            <IconWrapper>
                <NotFoundIcon />
            </IconWrapper>
        </Stack>
    );
};
