import { Typography, TypographyProps } from '@mui/material';

import { LogoYavitrina } from '@shared/ui/icons/logo-yavitrina';

type TProps = {
    onClick?: () => void;
} & TypographyProps;

export const Logo = ({ onClick, color = '#1C1C1E', ...rest }: TProps) => {
    return (
        <Typography
            onClick={onClick}
            {...rest}
            sx={{ cursor: onClick ? 'pointer' : 'default', ...rest.sx }}>
            <LogoYavitrina />
        </Typography>
    );
};
