import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Button, IconButton, Stack, Typography } from '@mui/material';

type TProps = {
    title: string;
    // imageUrl: string; TODO in future
    subTitle: string;
    subDescription: string;
    onAction: () => void;
    onRemove: () => void;
};

export const FavoriteCardItem = ({
    title,
    subTitle,
    subDescription,
    onAction,
    onRemove,
}: TProps) => {
    return (
        <Stack
            width="360px"
            height="320px"
            justifyContent="space-between"
            border={theme => `1px solid ${theme.palette.border.border4}`}
            padding="20px"
            borderRadius="10px">
            <Stack direction="row" justifyContent="space-between">
                <Stack gap="10px" maxWidth="90%">
                    <Typography
                        variant="h4"
                        overflow="hidden"
                        sx={{
                            '-webkit-line-clamp': '3',
                            display: '-webkit-box',
                            '-webkit-box-orient': 'vertical',
                        }}>
                        {title}
                    </Typography>
                </Stack>
                <Stack>
                    <IconButton color="buttonSecondaryText" onClick={onRemove}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </Stack>
            </Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                paddingTop="16px"
                borderTop={theme =>
                    `1px solid ${theme.palette.border.border4}`
                }>
                <Stack gap="4px" maxWidth="50%">
                    <Typography
                        textOverflow="ellipsis"
                        whiteSpace="noWrap"
                        overflow="hidden"
                        variant="caption2">
                        {subTitle}
                    </Typography>
                    <Typography
                        textOverflow="ellipsis"
                        whiteSpace="noWrap"
                        overflow="hidden"
                        variant="caption1"
                        color={theme => theme.palette.typography.secondary}>
                        {subDescription}
                    </Typography>
                </Stack>
                <Stack>
                    <Button
                        onClick={onAction}
                        variant="contained"
                        color="buttonSecondaryEnabled">
                        перейти
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
};
