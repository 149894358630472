import CancelIcon from '@mui/icons-material/Cancel';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
    Box,
    Button,
    IconButton,
    Stack,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { Conditional } from '@shared/ui/atoms/conditional';

export type TFormData = {
    clientId: string;
};

type TProps = {
    buttonText: string;
};

export const FormClientId = ({ buttonText }: TProps) => {
    const {
        formState: { errors },
        control,
        setValue,
    } = useFormContext<TFormData>();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Stack direction="column" gap="40px">
            <Controller
                name="clientId"
                control={control}
                render={({ field }) => (
                    <TextField
                        label="Ваш clientID в системе Яндекс Такси"
                        InputLabelProps={{
                            style: {
                                color: errors.clientId
                                    ? theme.palette.typography.negative
                                    : theme.palette.typography.secondary,
                            },
                        }}
                        InputProps={{
                            sx: {
                                color: errors.clientId
                                    ? theme.palette.typography.negative
                                    : theme.palette.typography.primary,
                            },
                            fullWidth: true,
                            endAdornment: (
                                <>
                                    <Conditional
                                        condition={Boolean(errors.clientId)}>
                                        <ErrorOutlineIcon fontSize="large" />
                                    </Conditional>
                                    <Conditional
                                        condition={Boolean(field.value)}>
                                        <IconButton
                                            onClick={() =>
                                                setValue('clientId', '')
                                            }
                                            color="buttonSecondaryText">
                                            <CancelIcon
                                                color="inherit"
                                                fontSize="large"
                                            />
                                        </IconButton>
                                    </Conditional>
                                </>
                            ),
                        }}
                        error={Boolean(errors.clientId)}
                        helperText={
                            errors.clientId ? errors.clientId?.message : ' '
                        }
                        variant="standard"
                        {...field}
                    />
                )}
            />
            <Box>
                <Button
                    size="medium"
                    variant="contained"
                    color="buttonPrimary"
                    type="submit"
                    fullWidth={isTablet}>
                    {buttonText}
                </Button>
            </Box>
        </Stack>
    );
};
