import { Grid, Typography } from '@mui/material';

import { useLogoClick } from '@shared/hooks';
import { Logo } from '@shared/ui/atoms/logo';

export const Header = () => {
    const onLogoClick = useLogoClick();
    return (
        <Grid container>
            <Grid xs={2} item>
                <Logo
                    color={theme => theme.palette.typography.primary}
                    sx={{ paddingTop: '5px', paddingRight: '10px' }}
                    onClick={onLogoClick}
                />
            </Grid>
            <Grid xs={6} item>
                <Typography variant="h2">Новости и предложения</Typography>
            </Grid>
        </Grid>
    );
};
