import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Alert, Button, IconButton, Stack, Typography } from '@mui/material';

type TProps = {
    providersError: string[];
    onChangeProvider: () => void;
    onClose: () => void;
};

export const ProviderErrorAlert = ({
    providersError,
    onChangeProvider,
    onClose,
}: TProps) => {
    return (
        <Alert
            sx={theme => ({
                backgroundColor: theme.palette.surface.errorLight,
                '.MuiAlert-action': {
                    alignItems: 'center',
                    padding: 0,
                },
                display: 'flex',
                alignItems: 'center',
            })}
            color="error"
            icon={
                <ErrorOutlineOutlinedIcon
                    sx={theme => ({
                        color: theme.palette.icon.error,
                    })}
                />
            }
            action={
                <Stack direction="row" gap="20px">
                    <Button
                        variant="text"
                        color="buttonPrimary"
                        sx={{ height: '24px' }}
                        onClick={onChangeProvider}>
                        изменить данные
                    </Button>
                    <IconButton color="buttonPrimary" onClick={onClose}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </Stack>
            }>
            <Typography
                variant="body3"
                color={theme => theme.palette.typography.primary}>
                От {providersError.length} поставщиков нет ответа. Пожалуйста,
                убедитесь, что ваши данные в их системах ещё актуальны
            </Typography>
        </Alert>
    );
};
