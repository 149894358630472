import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useCartAmountStore } from '@entities/cart/model/cart-domain';
import { mapUserInfo } from '@entities/user/utils/mappers/user-info-mapper';
import { useUserInfoStore } from '@shared/session';
import { PageTemplate } from '@shared/ui/templates/page-template';
import { HeaderWidgetConnector } from '@widgets/header';

import { AccountPage } from './account-page';
import { accountPaths } from '../paths';

const YA_CHANGE_INFO_LINK =
    'https://id.yandex.ru/personal?dialog=personal-data';

export const AccountPageConnector = () => {
    const navigate = useNavigate();
    const userInfoStore = useUserInfoStore();
    const cartAmountStore = useCartAmountStore();
    const handleChangeClientId = () => {
        navigate(accountPaths.clientId.absolute);
    };

    const userInfo = mapUserInfo(
        userInfoStore.userInfo,
        cartAmountStore.cartAmount || 0,
    );
    return (
        <PageTemplate
            withHeadSpace
            Header={
                <HeaderWidgetConnector withLogo variant="primary" withProfile>
                    <Typography
                        variant="h2"
                        textAlign={{ xs: 'center', md: 'start' }}>
                        личные данные
                    </Typography>
                </HeaderWidgetConnector>
            }
            variant="big">
            <AccountPage
                userInfo={userInfo}
                onChangeClientID={handleChangeClientId}
                changeInfoLink={YA_CHANGE_INFO_LINK}
            />
        </PageTemplate>
    );
};
