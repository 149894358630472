import { ReactElement, useEffect } from 'react';

import { useGetSearchHistory } from '@entities/user/model/hooks/use-get-search-history';
import { useSearchHistoryStore } from '@entities/user/model/user-domain';

type TProps = {
    children: ReactElement | null;
};

export const UserSearchHistoryGuard = ({ children }: TProps) => {
    const { data } = useGetSearchHistory();
    const searchHistoryStore = useSearchHistoryStore();
    useEffect(() => {
        searchHistoryStore.set(data?.data.vendorCodes || []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return children;
};
