import { Grid, Stack, Tooltip, Typography, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import { TProductTableField } from '@entities/products/types';
import { Conditional } from '@shared/ui/atoms/conditional';

type TProps = {} & TProductTableField;

const FieldTypography = styled(Typography)`
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const HelpedTypography = styled(Typography)`
    width: fit-content;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-self: end;
`;

const mapAlight = (alight?: 'left' | 'right') => {
    switch (alight) {
        case 'right':
            return 'end';
        case 'left':
        default:
            return 'start';
    }
};

export const ProductItemField = ({
    size,
    alight,
    label,
    isLabelBold,
    helpedText,
    helpedTextBg,
    Component,
}: TProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [isEllipsis, setIsEllipsis] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    useEffect(() => {
        if (ref.current) {
            if (ref.current.clientWidth < ref.current.scrollWidth) {
                //display tooltip only if text ellipsis
                setIsEllipsis(true);
            }
        }
    }, [ref]);
    return (
        <Grid item xs={size ?? 1}>
            <Stack
                direction="column"
                textAlign={mapAlight(alight)}
                paddingY="20px"
                minHeight="75px"
                justifyContent="center">
                {Component ? (
                    Component
                ) : (
                    <>
                        <Tooltip
                            title={label}
                            onMouseEnter={() => {
                                if (isEllipsis) {
                                    setIsHovered(true);
                                }
                            }}
                            onMouseLeave={() => {
                                setIsHovered(false);
                            }}
                            open={isHovered ? true : false}>
                            <FieldTypography
                                ref={ref}
                                variant="body3"
                                fontWeight={isLabelBold ? '700' : '400'}
                                paddingRight="10px">
                                {label}
                            </FieldTypography>
                        </Tooltip>
                        <Conditional condition={Boolean(helpedText)}>
                            <HelpedTypography
                                variant="caption2"
                                textAlign={mapAlight(alight)}
                                sx={{
                                    background: helpedTextBg ?? 'transparent',
                                    paddingX: '4px',
                                    borderRadius: '8px 0 0 8px',
                                }}>
                                {helpedText}
                            </HelpedTypography>
                        </Conditional>
                    </>
                )}
            </Stack>
        </Grid>
    );
};
