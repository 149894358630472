import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { clientIdPaths } from '@pages/client-id/paths';
import { useUserInfo } from '@shared/session';

type TProps = {
    children: ReactElement | null;
};

export const ClientIdGuard = ({ children }: TProps) => {
    const userInfo = useUserInfo();

    if (!userInfo?.clientId) {
        return <Navigate to={clientIdPaths.home.absolute} />;
    }

    return children;
};
