import { RouteObject } from 'react-router-dom';

import { LogoutPageConnector } from './logout-page-connector';
import { logoutPaths } from './paths';

export const routes: RouteObject[] = [
    {
        path: logoutPaths.home.absolute,
        element: <LogoutPageConnector />,
    },
];
