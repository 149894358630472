import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

import {
    ErrorOutlinedIcon,
    InfoOutlinedIcon,
    SuccessOutlinedIcon,
} from '@shared/ui/icons';

type TProps = {
    Header: ReactNode;
    title: string;
    helpedText?: string;
    primaryButtonText: ReactNode;
    secondaryButtonText: ReactNode;
    variant: TVariant;
    onPrimaryButtonClick: () => void;
    onSecondaryButtonClick: () => void;
};

const variantsIcon = {
    success: {
        icon: <SuccessOutlinedIcon />,
        color: 'icon.primaryInvert',
    },
    error: {
        icon: <ErrorOutlinedIcon />,
        color: 'icon.error',
    },
    info: {
        icon: <InfoOutlinedIcon />,
        color: 'icon.primaryInvert',
    },
};

type TVariant = 'error' | 'info' | 'success';

export const Dialog = ({
    Header,
    title,
    variant,
    helpedText,
    primaryButtonText,
    secondaryButtonText,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
}: TProps) => {
    const dialogVariant = variantsIcon[variant];
    return (
        <Stack
            direction="column"
            height="100dvh"
            sx={theme => ({ background: theme.palette.bg.primaryInvert })}>
            <Box width="100%" position="absolute">
                {Header}
            </Box>
            <Grid
                zIndex={2}
                width="100vw"
                flex={1}
                container
                direction="row"
                spacing="40px">
                <Grid item xs={0} md={1} />
                <Grid item xs={12} md={10} justifyContent="center">
                    <Stack height="100%" justifyContent="center">
                        <Stack
                            padding={{ xs: '20px', md: '0' }}
                            direction="column"
                            gap="90px"
                            position="relative"
                            alignItems={{ xs: 'center', md: 'normal' }}>
                            <Stack
                                fontSize="128px"
                                color={dialogVariant.color}
                                width="101px"
                                height="101px">
                                {dialogVariant.icon}
                            </Stack>
                            <Stack direction="column" gap="40px">
                                <Stack direction="column" gap="22px">
                                    <Typography
                                        variant="h2"
                                        color={theme =>
                                            theme.palette.typography.onlyWhite
                                        }
                                        textAlign={{
                                            xs: 'center',
                                            md: 'start',
                                        }}>
                                        {title}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color={theme =>
                                            theme.palette.typography.secondary
                                        }
                                        textAlign={{
                                            xs: 'center',
                                            md: 'start',
                                        }}>
                                        {helpedText}
                                    </Typography>
                                </Stack>
                                <Stack
                                    direction={{ xs: 'column', md: 'row' }}
                                    gap="20px">
                                    <Button
                                        sx={{
                                            minWidth: {
                                                xs: '100%',
                                                md: '174px',
                                            },
                                        }}
                                        variant="contained"
                                        color="buttonPrimaryInvert"
                                        onClick={onPrimaryButtonClick}>
                                        {primaryButtonText}
                                    </Button>
                                    <Button
                                        sx={{
                                            minWidth: {
                                                xs: '100%',
                                                md: '174px',
                                            },
                                        }}
                                        variant="contained"
                                        color="buttonInvertSecondaryEnabled"
                                        onClick={onSecondaryButtonClick}>
                                        {secondaryButtonText}
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid item xs={0} md={1} />
            </Grid>
        </Stack>
    );
};
