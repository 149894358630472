import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, Stack } from '@mui/material';

import { TNews } from '@entities/news/types';
import { NewsSection } from '@entities/news/ui/news-section';

type TProps = {
    newses: TNews[];
    onActionClick: () => void;
    onBannerClick: (sectionId: string, bannerId: string) => void;
};

export const DriverAuthPage = ({
    newses,
    onActionClick,
    onBannerClick,
}: TProps) => {
    return (
        <Stack gap="100px">
            <Box>
                <Button
                    variant="contained"
                    color="buttonSecondaryEnabled"
                    onClick={onActionClick}
                    startIcon={<ArrowBackIcon />}>
                    На главную страницу
                </Button>
            </Box>
            <Stack gap="50px">
                {newses.map(news => (
                    <NewsSection
                        key={news.id}
                        news={news}
                        onBannerClick={bannerId =>
                            onBannerClick(news.id, bannerId)
                        }
                    />
                ))}
            </Stack>
        </Stack>
    );
};
