import { Outlet, RouteObject } from 'react-router-dom';

import { routes as accountRoutes } from '@pages/account';
import { routes as authRoutes } from '@pages/auth';
import { routes as catalogRoutes } from '@pages/catalog';
import { routes as clientIdRoutes } from '@pages/client-id';
import { routes as errorRoutes } from '@pages/error';
import { routes as favoriteRoutes } from '@pages/favorite';
import {
    ClientIdGuard,
    UserInfoGuard,
    UserSearchHistoryGuard,
} from '@pages/guards';
import { Protected } from '@pages/guards/protected';
import { routes as logoutRoutes } from '@pages/logout';
import { routes as providerRoutes } from '@pages/provider';
import { MetaTitle } from '@shared/ui/atoms/meta-title';

const protectedRoutes = [
    ...accountRoutes,
    ...catalogRoutes,
    ...providerRoutes,
    ...providerRoutes,
    ...favoriteRoutes,
];

const generalRoutes = [...authRoutes, ...logoutRoutes, ...errorRoutes];

export const routes: RouteObject[] = [
    {
        children: [
            {
                element: (
                    <Protected>
                        <UserInfoGuard>
                            <ClientIdGuard>
                                <UserSearchHistoryGuard>
                                    <>
                                        <MetaTitle>
                                            Маркетплейс запчастей
                                        </MetaTitle>
                                        <Outlet />
                                    </>
                                </UserSearchHistoryGuard>
                            </ClientIdGuard>
                        </UserInfoGuard>
                    </Protected>
                ),
                children: protectedRoutes,
            },
            {
                element: (
                    <Protected>
                        <>
                            <MetaTitle>Маркетплейс запчастей</MetaTitle>
                            <Outlet />
                        </>
                    </Protected>
                ),
                children: clientIdRoutes,
            },
            ...generalRoutes,
        ],
    },
];
