import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type TSearchHistoryState = {
    searchHistory: string[];
    set: (searchHistory: string[]) => void;
    add: (newQuery: string) => void;
    reset: () => void;
};

type TDeviceInfo = {
    deviceId: string;
    deviceName: string;
};

type TDeviceInfoState = {
    deviceInfo?: TDeviceInfo;
    set: (deviceInfo: TDeviceInfo) => void;
    reset: () => void;
};

export const useSearchHistoryStore = create<TSearchHistoryState>()(
    persist(
        set => ({
            searchHistory: [],
            set: searchHistory => set({ searchHistory: searchHistory }),
            add: newQuery =>
                set(state => {
                    const searchHistory = state.searchHistory.filter(
                        item => item !== newQuery,
                    );
                    return {
                        searchHistory: [newQuery, ...searchHistory],
                    };
                }),
            reset: () => set({ searchHistory: [] }),
        }),
        {
            name: 'searchHistoryStore',
            storage: createJSONStorage(() => localStorage),
        },
    ),
);

export const useDeviceInfoStore = create<TDeviceInfoState>()(
    persist(
        set => ({
            deviceInfo: undefined,
            set: deviceInfo => set({ deviceInfo }),
            reset: () => set({ deviceInfo: undefined }),
        }),
        {
            name: 'deviceInfoStore',
            storage: createJSONStorage(() => localStorage),
        },
    ),
);
