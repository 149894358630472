import { TUserInfo as TAccountInfo } from '@pages/account/types';
import { TUserInfo } from '@shared/session';

export const mapUserInfo = (
    profile: TUserInfo | null,
    cartAmount: number,
): TAccountInfo => {
    return {
        firstName: profile?.firstName || '',
        middleName: profile?.lastName || '',
        login: profile?.login || '',
        email: profile?.email || '',
        avatarUrl: profile?.avatarId
            ? `https://avatars.mds.yandex.net/get-yapic/${profile.avatarId}/islands-200`
            : '',
        phone: profile?.phone || '',
        clientID: String(profile?.clientId) || '',
        cartAmount: cartAmount,
    };
};
