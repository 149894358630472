import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLogout } from '@entities/auth';
import { useCartAmountStore } from '@entities/cart';
import { useSearchHistoryStore } from '@entities/user/model/user-domain';
import { authPaths } from '@pages/auth/paths';
import { useYandexReachGoal } from '@shared/hooks';
import {
    useResetSessionDomain,
    useSessionStore,
    useUserInfoStore,
} from '@shared/session';
import { InfoTemplate } from '@shared/ui/templates/info-template';
import { HeaderWidgetConnector } from '@widgets/header';

import { LogoutPage } from './logout-page';

export const LogoutPageConnector = () => {
    const { session } = useSessionStore();
    const { userInfo } = useUserInfoStore();
    const queryClient = useQueryClient();
    const resetSessionDomain = useResetSessionDomain();
    const cartAmountStore = useCartAmountStore();
    const searchHistoryStore = useSearchHistoryStore();
    const navigate = useNavigate();
    const fireProductEvent = useYandexReachGoal();
    const logoutToken = session?.refreshToken || '';
    const authToken = userInfo?.yaToken || '';
    const { logout } = useLogout({
        onSettled: () => {
            resetSessionDomain();
            searchHistoryStore.reset();
            cartAmountStore.reset();
            fireProductEvent('logout');
            navigate(authPaths.home.absolute);
            queryClient.clear();
        },
    });
    useEffect(() => {
        logout({ authToken, logoutToken });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <InfoTemplate
            Header={<HeaderWidgetConnector withLogo variant="secondary" />}
            title="один момент..."
            CenterSlot={<LogoutPage />}
        />
    );
};
