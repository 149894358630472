import { Box, CircularProgress } from '@mui/material';

import { FormClientIdProvider } from '@features/form-client-id';
import { TFormData } from '@features/form-client-id/form-client-id';
import { TExternalFormError } from '@features/form-client-id/form-client-id-provider';

type TProps = {
    isSubmitting: boolean;
    externalFormError?: TExternalFormError;
    onSubmit: (data: TFormData) => void;
};

export const ClientIdPage = ({
    isSubmitting,
    externalFormError,
    onSubmit,
}: TProps) => {
    if (isSubmitting) {
        return (
            <Box color={theme => theme.palette.common.white}>
                <CircularProgress color="inherit" size="80px" />
            </Box>
        );
    }
    return (
        <FormClientIdProvider
            onSubmit={onSubmit}
            buttonText="добавить"
            externalFormError={externalFormError}
        />
    );
};
