import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSetClid } from '@entities/user/model/hooks';
import { TFormData } from '@features/form-client-id';
import { TExternalFormError } from '@features/form-client-id/form-client-id-provider';
import { useYandexReachGoal, useYandexUserParams } from '@shared/hooks';
import { useSessionStore, useUserInfoStore } from '@shared/session';
import { Dialog } from '@shared/ui/organisms/dialog';
import { InfoTemplate } from '@shared/ui/templates/info-template';
import { PageTemplate } from '@shared/ui/templates/page-template';
import { HeaderWidgetConnector } from '@widgets/header';

import { ChangeClientIdPage } from './change-client-id-page';
import { ChangeClientIdHeader } from './ui/change-client-id-header';
import { accountPaths } from '../paths';

export const ChangeClientIdPageConnector = () => {
    const navigate = useNavigate();
    const [externalFormError, setExternalFormError] = useState<
        TExternalFormError | undefined
    >();
    const fireProductEvent = useYandexReachGoal();
    const changeUserParams = useYandexUserParams();
    const sessionsStore = useSessionStore();
    const userInfoStore = useUserInfoStore();
    const { enqueueSnackbar } = useSnackbar();
    const [newClid, setNewClid] = useState<number>();
    const { setClid, isLoading, error, isSuccess, isError, reset } = useSetClid(
        {
            onSuccess: data => {
                fireProductEvent('changeClientID', {
                    previousClientID: userInfoStore.userInfo?.clientId,
                });
                changeUserParams({ userID: newClid });
                userInfoStore.update({ clientId: newClid });
                sessionsStore.set(data.data);
                setTimeout(() => navigate(accountPaths.home.absolute), 1500);
            },
            onError: e => {
                if (e.code === 'ClientIdInvalid') {
                    setExternalFormError({
                        name: 'clientId',
                        message: e.message,
                        type: 'api_error',
                    });
                    return e;
                }
                enqueueSnackbar('что-то пошло не так', {
                    variant: 'error',
                    description: 'попробуйте позже',
                });
            },
        },
    );
    const handleSubmit = useCallback(
        (data: TFormData) => {
            const clid = Number(data.clientId.replaceAll('-', ''));
            setNewClid(clid);
            setClid({ clientId: clid });
        },
        [setClid],
    );
    const handleSkipError = useCallback(() => {
        navigate(accountPaths.home.absolute);
    }, [navigate]);
    const handleRetryError = useCallback(async () => {
        if (newClid) {
            setClid({ clientId: newClid });
            return;
        }
        reset();
    }, [newClid, reset, setClid]);
    if (isLoading) {
        return (
            <InfoTemplate
                Header={<HeaderWidgetConnector withLogo variant="secondary" />}
                title="один момент..."
                CenterSlot={
                    <ChangeClientIdPage
                        onSubmit={handleSubmit}
                        isSubmitting={true}
                        isSuccess={false}
                    />
                }
            />
        );
    }
    if (isSuccess) {
        return (
            <InfoTemplate
                Header={<HeaderWidgetConnector withLogo variant="secondary" />}
                title="client ID успешно изменен"
                CenterSlot={
                    <ChangeClientIdPage
                        onSubmit={handleSubmit}
                        isSubmitting={false}
                        isSuccess={true}
                    />
                }
            />
        );
    }
    if (isError && error.request.status !== 404) {
        return (
            <Dialog
                Header={<HeaderWidgetConnector withLogo variant="secondary" />}
                title="не удалось изменить client ID"
                helpedText="попробуйте позже"
                variant="error"
                primaryButtonText="еще раз"
                secondaryButtonText="пропустить"
                onPrimaryButtonClick={handleRetryError}
                onSecondaryButtonClick={handleSkipError}
            />
        );
    }
    return (
        <PageTemplate
            variant="small"
            withHeadSpace
            Header={
                <HeaderWidgetConnector withLogo withProfile variant="primary">
                    <ChangeClientIdHeader />
                </HeaderWidgetConnector>
            }>
            <ChangeClientIdPage
                onSubmit={handleSubmit}
                isSubmitting={false}
                isSuccess={false}
                defaultValue={userInfoStore.userInfo?.clientId}
                externalFormError={externalFormError}
            />
        </PageTemplate>
    );
};
