import NorthIcon from '@mui/icons-material/North';
import { Grid, Stack, Typography } from '@mui/material';

import {
    TProductTableHeaderField,
    TSortableField,
} from '@entities/products/types';

type TProps = {
    fields: TProductTableHeaderField[];
    sortableField?: TSortableField;
    onClick?: (attribute: string) => void;
};

const mapAlight = (alight?: 'left' | 'right') => {
    switch (alight) {
        case 'right':
            return 'row-reverse';
        case 'left':
        default:
            return 'row';
    }
};

export const ProductTableHeader = ({
    fields,
    sortableField,
    onClick,
}: TProps) => {
    return (
        <Grid
            width="100%"
            container
            minWidth="1300px"
            direction="row"
            alignItems="center"
            borderBottom={theme => `1px solid ${theme.palette.border.border4}`}>
            {fields.map((field, index) => {
                const isActive = sortableField?.attribute === field.attribute;
                const isSortable = field.isSortable && onClick;
                const handelClick = () => {
                    if (isSortable) {
                        onClick(field.attribute);
                    }
                };
                return (
                    <Grid item xs={field.size ?? 1} key={index}>
                        <Stack
                            direction={mapAlight(field.alight)}
                            justifyContent="flex-start"
                            alignItems="center"
                            onClick={handelClick}
                            gap="4px"
                            padding="20px 10px 20px 0px"
                            sx={{
                                '&:hover > svg': {
                                    color: isSortable
                                        ? 'inherit'
                                        : 'transparent',
                                },
                            }}>
                            <Typography
                                sx={{
                                    cursor: isSortable ? 'pointer' : 'default',
                                    userSelect: 'none',
                                }}
                                variant={isActive ? 'caption2' : 'caption1'}
                                fontWeight={isActive ? '600' : '400'}
                                overflow="hidden"
                                textOverflow="ellipsis"
                                whiteSpace="nowrap"
                                color={
                                    isActive
                                        ? 'typography.primary'
                                        : 'typography.secondary'
                                }>
                                {field.label}{' '}
                            </Typography>
                            <NorthIcon
                                sx={{
                                    fontSize: '12px',
                                    transform: `rotate(${sortableField?.sort === 'ASC' ? 180 : 0}deg)`,
                                    transition: 'transform 0.1s',
                                    color: isActive ? 'inherit' : 'transparent',
                                }}
                            />
                        </Stack>
                    </Grid>
                );
            })}
        </Grid>
    );
};
