import { Stack, Typography } from '@mui/material';

import { TNews } from '@entities/news/types';

import { NewsCard } from '../news-card';

type TProps = {
    news: TNews;
    onBannerClick: (bannerId: string) => void;
};

export const NewsSection = ({ news, onBannerClick }: TProps) => {
    return (
        <Stack direction="column" gap="40px">
            <Typography variant="h3">{news.title}</Typography>
            <Stack direction="row" gap="20px" flexWrap="wrap">
                {news.banners.map(banner => (
                    <NewsCard
                        key={banner.id}
                        title={banner.title}
                        description={banner.shortText}
                        imgUrl={banner.pictureId}
                        onClick={() => onBannerClick(banner.id)}
                    />
                ))}
            </Stack>
        </Stack>
    );
};
