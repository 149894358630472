import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCartAmountStore } from '@entities/cart';
import { useGetFavorite } from '@entities/favorite/model';
import { useLogout } from '@entities/user/model';
import { mapUserInfo } from '@entities/user/utils/mappers/user-info-mapper';
import { FeatureToggle } from '@features/feature-toggle';
import { useFeatureToggleModalStore } from '@features/feature-toggle/feature-toggle-domain';
import { accountPaths } from '@pages/account/paths';
import { favoritePaths } from '@pages/favorite/paths';
import { IS_DEV } from '@shared/config/constants';
import { useStateModal, useYandexReachGoal } from '@shared/hooks';
import { useUserInfoStore } from '@shared/session';
import { InfoModal } from '@shared/ui/organisms/info-modal';
import { CartWidgetConnector } from '@widgets/cart';

import { TTopBarComponent, TopbarMenuWidget } from './topbar-menu-widget';
import { TopbarMenuItem } from './ui/topbar-menu-item';

const menus = [
    {
        label: 'Личные данные',
        path: accountPaths.home.absolute,
    },
    {
        label: 'Поставщики',
        path: accountPaths.providers.absolute,
    },
    {
        label: 'Вопросы и ответы',
        path: accountPaths.help.absolute,
    },
];

const isFavoriteEnabled = FeatureToggle.hasFeatureFlag('favorite');

export const TopbarMenuWidgetConnector = () => {
    const { data: favoriteData } = useGetFavorite();
    const logout = useLogout();
    const navigate = useNavigate();
    const fireProductEvent = useYandexReachGoal();
    const { open: openFeatureToggle } = useFeatureToggleModalStore();
    const {
        isOpen: isInfoOpen,
        open: openInfo,
        close: closeInfo,
    } = useStateModal();
    const {
        isOpen: isCartOpen,
        open: openCart,
        close: closeCart,
    } = useStateModal();
    const userInfoStore = useUserInfoStore();
    const cartAmountStore = useCartAmountStore();
    const userInfo = useMemo(
        () =>
            mapUserInfo(
                userInfoStore.userInfo,
                cartAmountStore.cartAmount || 0,
            ),
        [cartAmountStore.cartAmount, userInfoStore.userInfo],
    );
    const handleOpenCart = useCallback(() => {
        fireProductEvent('openCart');
        openCart();
    }, [fireProductEvent, openCart]);
    const handleOpenFavorite = useCallback(() => {
        navigate(favoritePaths.home.absolute);
    }, [navigate]);

    const topBarComponents: TTopBarComponent[] = [
        {
            component: (
                <TopbarMenuItem
                    title="фиче-тогл"
                    icon={<AccessibleForwardIcon />}
                    onClick={openFeatureToggle}
                />
            ),
            isVisible: IS_DEV,
        },
        {
            component: (
                <TopbarMenuItem
                    title="избранное"
                    icon={<FavoriteBorderOutlinedIcon />}
                    badgeContent={favoriteData?.data.totalQuantity}
                    badgeColor="primary"
                    onClick={handleOpenFavorite}
                />
            ),
            isVisible: isFavoriteEnabled,
        },
        {
            component: (
                <TopbarMenuItem
                    title="корзина"
                    icon={<ShoppingCartOutlinedIcon />}
                    badgeContent={userInfo.cartAmount}
                    onClick={handleOpenCart}
                    badgeColor="error"
                />
            ),
            isVisible: true,
        },
    ];
    return (
        <>
            <TopbarMenuWidget
                profileInfo={userInfo}
                menusInfo={menus}
                topbarComponents={topBarComponents}
                onCartClick={handleOpenCart}
                onLogout={openInfo}
            />
            <InfoModal
                withHeader
                title="Вы действительно хотите выйти?"
                variant="question"
                primaryButtonText="да, выйти"
                secondaryButtonText="нет, отменить"
                onClose={closeInfo}
                open={isInfoOpen}
                onSecondaryButtonClick={closeInfo}
                onPrimaryButtonClick={logout}
            />
            {isCartOpen && (
                <CartWidgetConnector isOpen={isCartOpen} onClose={closeCart} />
            )}
        </>
    );
};
