import { TCart, TCartItem } from '@entities/cart/types';
import { TCartAccordionData } from '@entities/cart/ui/cart-accordion/cart-accordion';
import { TFavoriteItem } from '@entities/favorite/types';
import { TBanner, TNews } from '@entities/news/types';
import {
    TFormDelivery,
    TFormDeliveryType,
    TFormState,
    TProductTable,
    TProductTableField,
    TProductTableHeaderField,
} from '@entities/products/types';
import {
    TCredentialField,
    TFormProvider,
    TProvider,
} from '@entities/providers/types';
import { TUserInfo } from '@pages/account/types';
import { TCartValue } from '@pages/catalog/catalog/catalog-page';

import { userAvatarImgMock } from './images-mock';

export const formDeliveryDateMock: TFormDelivery[] = [
    { id: 'any', label: 'Любой' },
    { id: '1', label: '1 день' },
    { id: '3', label: 'До 3 дней' },
    { id: '7', label: 'До 7 дней' },
];

export const formProviderMock: TFormProvider[] = [
    { id: '1', label: 'Провайдер 1' },
    { id: '2', label: 'Провайдер 2' },
    { id: '3', label: 'Провайдер 3' },
    { id: '4', label: 'Провайдер 4' },
];

export const formDeliveryTypeMock: TFormDeliveryType[] = [
    { value: 'any', label: 'любой тип' },
    { value: 'pickup', label: 'самовывоз' },
    { value: 'delivery', label: 'доставка' },
];

export const formStateMock: TFormState[] = [
    { value: 'any', label: 'любое' },
    { value: 'new', label: 'новое' },
    { value: 'used', label: 'б/у' },
];

export const userInfoMock: TUserInfo = {
    firstName: 'Михаил',
    middleName: 'Рожков',
    login: 'rojkov88',
    phone: '+7 962 786 45 91',
    email: 'mishagonch@gmail.com',
    clientID: '1240-4152-762-9762',
    avatarUrl: userAvatarImgMock,
    cartAmount: 9,
};

export const providerCredentialMock: TCredentialField[] = [
    { isRequired: true, label: 'Код', code: 'code' },
    { isRequired: true, label: 'Логин', code: 'login' },
    { isRequired: true, label: 'Пароль', code: 'password' },
];

export const providerMock: TProvider[] = [
    {
        id: '1',
        title: 'АвтоКонтинент',
        credentialFields: [{ isRequired: true, label: 'Код', code: 'code' }],
    },
    {
        id: '2',
        title: 'АвтоПитер',
        credentialFields: [
            { isRequired: true, label: 'Логин', code: 'login' },
            { isRequired: true, label: 'Пароль', code: 'password' },
        ],
    },
    {
        id: '3',
        title: 'Авторусь',
        credentialFields: [
            { isRequired: true, label: 'Логин', code: 'login' },
            { isRequired: true, label: 'Пароль', code: 'password' },
            { isRequired: true, label: 'Пароль', code: 'code' },
        ],
    },
    {
        id: '4',
        title: 'Автотрэйд1',
        credentialFields: [
            { isRequired: true, label: 'Логин', code: 'login' },
            { isRequired: true, label: 'Пароль', code: 'password' },
            { isRequired: false, label: 'Пароль', code: 'code' },
            { isRequired: false, label: 'Пароль', code: 'code2' },
            { isRequired: false, label: 'Пароль', code: 'code3' },
        ],
    },
    {
        id: '5',
        title: 'Автотрэйд2',
        credentialFields: [
            { isRequired: true, label: 'Логин', code: 'login' },
            { isRequired: true, label: 'Пароль', code: 'password' },
            { isRequired: false, label: 'Пароль', code: 'code' },
            { isRequired: false, label: 'Пароль', code: 'code2' },
            { isRequired: false, label: 'Пароль', code: 'code3' },
        ],
    },
    {
        id: '6',
        title: 'Автотрэйд3',
        credentialFields: [
            { isRequired: true, label: 'Логин', code: 'login' },
            { isRequired: true, label: 'Пароль', code: 'password' },
            { isRequired: false, label: 'Пароль', code: 'code' },
            { isRequired: false, label: 'Пароль', code: 'code2' },
            { isRequired: false, label: 'Пароль', code: 'code3' },
        ],
    },
    {
        id: '7',
        title: 'Автотрэйд4',
        credentialFields: [
            { isRequired: true, label: 'Логин', code: 'login' },
            { isRequired: true, label: 'Пароль', code: 'password' },
            { isRequired: false, label: 'Пароль', code: 'code' },
            { isRequired: false, label: 'Пароль', code: 'code2' },
            { isRequired: false, label: 'Пароль', code: 'code3' },
        ],
    },
    {
        id: '8',
        title: 'Автотрэйд5',
        credentialFields: [
            { isRequired: true, label: 'Логин', code: 'login' },
            { isRequired: true, label: 'Пароль', code: 'password' },
            { isRequired: false, label: 'Пароль', code: 'code' },
            { isRequired: false, label: 'Пароль', code: 'code2' },
            { isRequired: false, label: 'Пароль', code: 'code3' },
        ],
    },
];

export const searchSuggestMock = [
    '52525',
    '54545',
    '56565',
    '30965456',
    '30435435',
    '3064535',
    '31232435',
    '35346754',
    '30654647',
    'MSS015933',
    'G7514',
    '87501029L',
    '52525',
];

export const cartItemsMock: TCartItem[] = [
    {
        id: '31316750791313',
        providerId: '1',
        warehouseId: '1',
        price: 34708,
        title: 'Амортизатор Передний Gas L Meyle 3266230020 Meyle арт. 3266230020 в Новосибирске',
        article: '93454309',
        deliveryDate: '12.03.2001',
        amount: 30,
        stock: 10000000,
    },
    {
        id: '31316750732491',
        providerId: '1',
        warehouseId: '1',
        price: 3408,
        title: 'Амортизатор Передний Gas L Meyle 3266230020 Meyle арт. 3266230020 в Новосибирске',
        article: '9345430',
        deliveryDate: '12.03.2001',
        amount: 1,
        stock: 8,
    },
    {
        id: '313160791',
        providerId: '1',
        warehouseId: '1',
        price: 328,
        title: 'Амортизатор Передний Gas L Meyle 3266230020 Meyle арт. 3266230020 в Новосибирске',
        article: '9345430',
        deliveryDate: '12.03.2001',
        amount: 3,
        stock: 3,
    },
];

export const attributeMock = [
    {
        attribute: 'attribute',
        name: 'name',
        value: 'value',
    },
];

export const productTableHeaderMock: TProductTableHeaderField[] = [
    {
        label: 'Поставщик',
        alight: undefined,
        size: undefined,
        attribute: 'provider',
    },
    {
        label: 'Производитель',
        alight: undefined,
        size: undefined,
        attribute: '2',
    },
    {
        label: 'Артикул',
        alight: undefined,
        size: undefined,
        attribute: '3',
    },
    {
        label: 'Название товара',
        alight: undefined,
        size: 3,
        attribute: 'name',
        isSortable: true,
    },
    {
        label: 'Состояние',
        alight: undefined,
        size: undefined,
        attribute: '5',
    },
    {
        label: 'Тип получения',
        alight: undefined,
        size: undefined,
        attribute: '6',
    },
    {
        label: 'Дата доставки',
        alight: 'right',
        size: undefined,
        attribute: 'deliveryDate',
        isSortable: true,
    },
    {
        label: 'В наличии',
        alight: 'right' as const,
        size: undefined,
        attribute: '8',
    },
    {
        label: 'Стоимость',
        alight: 'right' as const,
        size: undefined,
        attribute: 'price',
        isSortable: true,
    },
];

export const productTableItemMock: TProductTableField[] = [
    {
        label: 'Фаворит Авто',
        isLabelBold: false,
        helpedText: undefined,
        helpedTextBg: undefined,
        alight: undefined,
        size: undefined,
    },
    {
        label: 'AUGER',
        isLabelBold: false,
        helpedText: undefined,
        helpedTextBg: undefined,
        alight: undefined,
        size: undefined,
    },
    {
        label: '52525',
        isLabelBold: false,
        helpedText: undefined,
        helpedTextBg: undefined,
        alight: undefined,
        size: undefined,
    },
    {
        label: 'ГОФРИРОВАННАЯ ТРУБА, ВЫХЛОПНАЯ СИСТЕМА fsdf sdf sdf ',
        isLabelBold: false,
        helpedText: undefined,
        helpedTextBg: undefined,
        alight: undefined,
        size: 3,
    },
    {
        label: 'б/у',
        isLabelBold: false,
        helpedText: undefined,
        helpedTextBg: undefined,
        alight: undefined,
        size: undefined,
    },
    {
        label: 'самовывоз',
        isLabelBold: false,
        helpedText: undefined,
        helpedTextBg: undefined,
        alight: undefined,
        size: undefined,
    },
    {
        label: '16.02.2024',
        isLabelBold: false,
        helpedText: undefined,
        helpedTextBg: undefined,
        alight: 'right',
        size: undefined,
    },
    {
        label: '1',
        isLabelBold: false,
        helpedText: undefined,
        helpedTextBg: undefined,
        alight: 'right',
        size: undefined,
    },
    {
        label: '10 000 ₽',
        isLabelBold: true,
        helpedText: 'Лучшая цена',
        helpedTextBg: 'yellow',
        alight: 'right',
        size: undefined,
    },
];

export const cartValuesMock: TCartValue[] = [
    {
        productId: '1',
        warehouseId: '1',
        providerId: '1',
        quantity: 2,
        stock: 10,
    },
    {
        productId: '3',
        warehouseId: '3',
        providerId: '1',
        quantity: 1,
        stock: 100,
    },
    {
        productId: '2',
        warehouseId: '2',
        providerId: '2',
        quantity: 10000,
        stock: 10000,
    },
];

export const productTableMock: TProductTable[] = [
    {
        id: '1',
        warehouseId: '1',
        providerId: '1',
        fields: productTableItemMock,
    },
    {
        id: '2',
        warehouseId: '2',
        providerId: '2',
        fields: productTableItemMock,
    },
    {
        id: '3',
        warehouseId: '3',
        providerId: '1',
        fields: productTableItemMock,
    },

    {
        id: '4',
        warehouseId: '2',
        providerId: '4',
        fields: productTableItemMock,
    },
    {
        id: '5',
        warehouseId: '2',
        providerId: '3',
        fields: productTableItemMock,
    },
    {
        id: '6',
        warehouseId: '2',
        providerId: '2',
        fields: productTableItemMock,
    },
    {
        id: '7',
        warehouseId: '1',
        providerId: '1',
        fields: productTableItemMock,
    },
];

export const productManufactoryTableHeaderMock: TProductTableHeaderField[] = [
    {
        label: '№',
        alight: undefined,
        size: 1,
        attribute: '1',
    },
    {
        label: 'Название товара',
        alight: undefined,
        size: 5,
        attribute: '2',
    },
    {
        label: 'Производитель',
        alight: undefined,
        size: 2,
        attribute: '3',
    },
    {
        label: 'Артикул',
        alight: undefined,
        size: 3,
        attribute: '4',
    },
];

export const productManufactoryTableItemMock: TProductTableField[] = [
    {
        label: '01',
        isLabelBold: true,
        helpedText: undefined,
        helpedTextBg: undefined,
        alight: undefined,
        size: undefined,
    },
    {
        label: 'Датчик, частота вращения колеса',
        isLabelBold: true,
        helpedText: undefined,
        helpedTextBg: undefined,
        alight: undefined,
        size: 5,
    },
    {
        label: 'Производитель',
        isLabelBold: false,
        helpedText: undefined,
        helpedTextBg: undefined,
        alight: undefined,
        size: 2,
    },
    {
        label: 'Артикул',
        isLabelBold: false,
        helpedText: undefined,
        helpedTextBg: undefined,
        alight: undefined,
        size: 3,
    },
];

export const productManufactoryTableMock = [
    {
        id: '1',
        providerId: '1',
        warehouseId: '1',
        fields: productManufactoryTableItemMock,
    },
    {
        id: '2',
        providerId: '1',
        warehouseId: '1',
        fields: productManufactoryTableItemMock,
    },
    {
        id: '3',
        providerId: '1',
        warehouseId: '1',
        fields: productManufactoryTableItemMock,
    },

    {
        id: '4',
        providerId: '1',
        warehouseId: '1',
        fields: productManufactoryTableItemMock,
    },
    {
        id: '5',
        providerId: '1',
        warehouseId: '1',
        fields: productManufactoryTableItemMock,
    },
    {
        id: '6',
        providerId: '1',
        warehouseId: '1',
        fields: productManufactoryTableItemMock,
    },
    {
        id: '7',
        providerId: '1',
        warehouseId: '1',
        fields: productManufactoryTableItemMock,
    },
];

export const cartMock: TCart = {
    id: '1',
    items: cartItemsMock,
    totalAmount: 3,
    providerName: 'providerName',
    providerId: 'string',
    totalPrice: 20000,
    minOrderPrice: 10000,
    instantOrderAvailable: false,
};

export const cartAccordionMock: TCartAccordionData[] = [
    {
        cart: cartMock,
        title: 'Title',
        isError: false,
        onChangeCart: () => null,
        onExportGroup: () => null,
        onRemove: () => null,
        onRemoveGroup: () => null,
    },
];

export const favoriteItemsMock: TFavoriteItem[] = [
    {
        id: '1',
        productName: 'Масло моторное MOTUL H-Tech 100 Plus 5w30 1л',

        brand: 'TORR',
        vendorCode: '31316750791',
    },
    {
        id: '2',
        productName: 'Масло моторное MOTUL H-Tech 100 Plus 5w30 1л',

        brand: 'TORR',
        vendorCode: '31316750791',
    },
    {
        id: '3',
        productName: 'Масло моторное MOTUL H-Tech 100 Plus 5w30 1л',

        brand: 'TORR',
        vendorCode: '31316750791',
    },
    {
        id: '4',
        productName: 'Масло моторное MOTUL H-Tech 100 Plus 5w30 1л',

        brand: 'TORR',
        vendorCode: '31316750791',
    },
    {
        id: '5',
        productName: 'Масло моторное MOTUL H-Tech 100 Plus 5w30 1л',

        brand: 'TORR',
        vendorCode: '31316750791',
    },
    {
        id: '6',
        productName: 'Масло моторное MOTUL H-Tech 100 Plus 5w30 1л',

        brand: 'TORR',
        vendorCode: '31316750791',
    },
    {
        id: '7',
        productName: 'Масло моторное MOTUL H-Tech 100 Plus 5w30 1л',

        brand: 'TORR',
        vendorCode: '31316750791',
    },
];

export const mockNewsesData = {
    sections: [
        {
            sectionId: 'sectionId0',
            name: 'Заголовок блока 1',
            banners: [
                {
                    bannerId: 'bannerId0',
                    title: 'Возврат водителей',
                    pictureId:
                        'https://s3-alpha-sig.figma.com/img/5bc6/39ab/9c922ffb1b75064fa7ac5b030739b39e?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MS054VJ1BI8jBeGEx6N6Zs~bh0QwCIpSA0LcZ9Ken0mnf8YKFkr~1mLSyl3H5xr11AwpK9ycpL67fqDaiUq5CwhKX7w0NpbskBFKX9Nxjh6s8rD~ms2Qhkwe~nFavFNMwsD~s8K0m7X16qKnlR1lnc21JiQK2eV0PpRTw6C9R1tJ7HrPDjE2HhVh4ypw5mJZjkfxmDmKNypaIbHdYCj1DiaDsOlxUXFFJxiWxShTXtN0ixBPfEEfh3m2c3EpZQ2DwvM~C7mSejqxT8d~7PncPLxvdG6neMJvlE9Du3AbNvGBxlMXrkLFjMR83bMQPJzWvhY6LcNW2Xg~DLg-0w8tnA__',
                    shortText:
                        'Поможем вам вернуть неактивных водителей на линию неактивных водителей',
                    fullText: `«Возврат водителей» - это автоматизированная система, которая поможет вам вернуть неактивных водителей в свой таксопарк. <br/> <br/>
                    Она выбирает тех, которые не выполнял заказы больше 28 дней. Раз в месяц мы звоним им, отправляем смс и пуши в Яндекс Про. <br/> <br/>
                    По нашей статистике, за первые 3 недели с момента коммуникации на линию возвращается до 15% неактивных водителей. <br/> <br/> «Возврат водителей» позволит вам: <br/> <br/> - Сократить расходы на привлечение -- вернуть водителя на 80% дешевле, чем найти нового <br/>- Сэкономить ресурсы сотрудников -- работой с ушедшими водителями займёмся мы. <br/>- Работать с надёжными водителями - с теми, с кем вы уже сотрудничали.<br/>- Иметь прозрачную отчётность - вы будете каждую неделю получать отчёты, кто вернулся и кого пытались вернуть`,
                    url: 'https://www.google.com',
                },
                {
                    bannerId: 'bannerId01',
                    title: 'Возврат водителей',
                    pictureId:
                        'https://s3-alpha-sig.figma.com/img/5bc6/39ab/9c922ffb1b75064fa7ac5b030739b39e?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MS054VJ1BI8jBeGEx6N6Zs~bh0QwCIpSA0LcZ9Ken0mnf8YKFkr~1mLSyl3H5xr11AwpK9ycpL67fqDaiUq5CwhKX7w0NpbskBFKX9Nxjh6s8rD~ms2Qhkwe~nFavFNMwsD~s8K0m7X16qKnlR1lnc21JiQK2eV0PpRTw6C9R1tJ7HrPDjE2HhVh4ypw5mJZjkfxmDmKNypaIbHdYCj1DiaDsOlxUXFFJxiWxShTXtN0ixBPfEEfh3m2c3EpZQ2DwvM~C7mSejqxT8d~7PncPLxvdG6neMJvlE9Du3AbNvGBxlMXrkLFjMR83bMQPJzWvhY6LcNW2Xg~DLg-0w8tnA__',
                    shortText:
                        'Поможем вам вернуть неактивных водителей на линию неактивных водителей',
                    fullText: `«Возврат водителей» - это автоматизированная система, которая поможет вам вернуть неактивных водителей в свой таксопарк. <br/> <br/>
                    Она выбирает тех, которые не выполнял заказы больше 28 дней. Раз в месяц мы звоним им, отправляем смс и пуши в Яндекс Про. <br/> <br/>
                    По нашей статистике, за первые 3 недели с момента коммуникации на линию возвращается до 15% неактивных водителей. <br/> <br/> «Возврат водителей» позволит вам: <br/> <br/> - Сократить расходы на привлечение -- вернуть водителя на 80% дешевле, чем найти нового <br/>- Сэкономить ресурсы сотрудников -- работой с ушедшими водителями займёмся мы. <br/>- Работать с надёжными водителями - с теми, с кем вы уже сотрудничали.<br/>- Иметь прозрачную отчётность - вы будете каждую неделю получать отчёты, кто вернулся и кого пытались вернуть`,
                    url: 'https://www.google.com',
                },
                {
                    bannerId: 'bannerId02',
                    title: 'Возврат водителей',
                    pictureId:
                        'https://s3-alpha-sig.figma.com/img/5bc6/39ab/9c922ffb1b75064fa7ac5b030739b39e?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MS054VJ1BI8jBeGEx6N6Zs~bh0QwCIpSA0LcZ9Ken0mnf8YKFkr~1mLSyl3H5xr11AwpK9ycpL67fqDaiUq5CwhKX7w0NpbskBFKX9Nxjh6s8rD~ms2Qhkwe~nFavFNMwsD~s8K0m7X16qKnlR1lnc21JiQK2eV0PpRTw6C9R1tJ7HrPDjE2HhVh4ypw5mJZjkfxmDmKNypaIbHdYCj1DiaDsOlxUXFFJxiWxShTXtN0ixBPfEEfh3m2c3EpZQ2DwvM~C7mSejqxT8d~7PncPLxvdG6neMJvlE9Du3AbNvGBxlMXrkLFjMR83bMQPJzWvhY6LcNW2Xg~DLg-0w8tnA__',
                    shortText:
                        'Поможем вам вернуть неактивных водителей на линию неактивных водителей',
                    fullText: `«Возврат водителей» - это автоматизированная система, которая поможет вам вернуть неактивных водителей в свой таксопарк. <br/> <br/>
                    Она выбирает тех, которые не выполнял заказы больше 28 дней. Раз в месяц мы звоним им, отправляем смс и пуши в Яндекс Про. <br/> <br/>
                    По нашей статистике, за первые 3 недели с момента коммуникации на линию возвращается до 15% неактивных водителей. <br/> <br/> «Возврат водителей» позволит вам: <br/> <br/> - Сократить расходы на привлечение -- вернуть водителя на 80% дешевле, чем найти нового <br/>- Сэкономить ресурсы сотрудников -- работой с ушедшими водителями займёмся мы. <br/>- Работать с надёжными водителями - с теми, с кем вы уже сотрудничали.<br/>- Иметь прозрачную отчётность - вы будете каждую неделю получать отчёты, кто вернулся и кого пытались вернуть`,
                    url: 'https://www.google.com',
                },
                {
                    bannerId: 'bannerId03',
                    title: 'Возврат водителей',
                    pictureId:
                        'https://s3-alpha-sig.figma.com/img/5bc6/39ab/9c922ffb1b75064fa7ac5b030739b39e?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MS054VJ1BI8jBeGEx6N6Zs~bh0QwCIpSA0LcZ9Ken0mnf8YKFkr~1mLSyl3H5xr11AwpK9ycpL67fqDaiUq5CwhKX7w0NpbskBFKX9Nxjh6s8rD~ms2Qhkwe~nFavFNMwsD~s8K0m7X16qKnlR1lnc21JiQK2eV0PpRTw6C9R1tJ7HrPDjE2HhVh4ypw5mJZjkfxmDmKNypaIbHdYCj1DiaDsOlxUXFFJxiWxShTXtN0ixBPfEEfh3m2c3EpZQ2DwvM~C7mSejqxT8d~7PncPLxvdG6neMJvlE9Du3AbNvGBxlMXrkLFjMR83bMQPJzWvhY6LcNW2Xg~DLg-0w8tnA__',
                    shortText:
                        'Поможем вам вернуть неактивных водителей на линию неактивных водителей',
                    fullText: `«Возврат водителей» - это автоматизированная система, которая поможет вам вернуть неактивных водителей в свой таксопарк. <br/> <br/>
                    Она выбирает тех, которые не выполнял заказы больше 28 дней. Раз в месяц мы звоним им, отправляем смс и пуши в Яндекс Про. <br/> <br/>
                    По нашей статистике, за первые 3 недели с момента коммуникации на линию возвращается до 15% неактивных водителей. <br/> <br/> «Возврат водителей» позволит вам: <br/> <br/> - Сократить расходы на привлечение -- вернуть водителя на 80% дешевле, чем найти нового <br/>- Сэкономить ресурсы сотрудников -- работой с ушедшими водителями займёмся мы. <br/>- Работать с надёжными водителями - с теми, с кем вы уже сотрудничали.<br/>- Иметь прозрачную отчётность - вы будете каждую неделю получать отчёты, кто вернулся и кого пытались вернуть`,
                    url: 'https://www.google.com',
                },
                {
                    bannerId: 'bannerId04',
                    title: 'Возврат водителей',
                    pictureId:
                        'https://s3-alpha-sig.figma.com/img/5bc6/39ab/9c922ffb1b75064fa7ac5b030739b39e?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MS054VJ1BI8jBeGEx6N6Zs~bh0QwCIpSA0LcZ9Ken0mnf8YKFkr~1mLSyl3H5xr11AwpK9ycpL67fqDaiUq5CwhKX7w0NpbskBFKX9Nxjh6s8rD~ms2Qhkwe~nFavFNMwsD~s8K0m7X16qKnlR1lnc21JiQK2eV0PpRTw6C9R1tJ7HrPDjE2HhVh4ypw5mJZjkfxmDmKNypaIbHdYCj1DiaDsOlxUXFFJxiWxShTXtN0ixBPfEEfh3m2c3EpZQ2DwvM~C7mSejqxT8d~7PncPLxvdG6neMJvlE9Du3AbNvGBxlMXrkLFjMR83bMQPJzWvhY6LcNW2Xg~DLg-0w8tnA__',
                    shortText:
                        'Поможем вам вернуть неактивных водителей на линию неактивных водителей',
                    fullText: `«Возврат водителей» - это автоматизированная система, которая поможет вам вернуть неактивных водителей в свой таксопарк. <br/> <br/>
                    Она выбирает тех, которые не выполнял заказы больше 28 дней. Раз в месяц мы звоним им, отправляем смс и пуши в Яндекс Про. <br/> <br/>
                    По нашей статистике, за первые 3 недели с момента коммуникации на линию возвращается до 15% неактивных водителей. <br/> <br/> «Возврат водителей» позволит вам: <br/> <br/> - Сократить расходы на привлечение -- вернуть водителя на 80% дешевле, чем найти нового <br/>- Сэкономить ресурсы сотрудников -- работой с ушедшими водителями займёмся мы. <br/>- Работать с надёжными водителями - с теми, с кем вы уже сотрудничали.<br/>- Иметь прозрачную отчётность - вы будете каждую неделю получать отчёты, кто вернулся и кого пытались вернуть`,
                    url: 'https://www.google.com',
                },
                {
                    bannerId: 'bannerId05',
                    title: 'Возврат водителей',
                    pictureId:
                        'https://s3-alpha-sig.figma.com/img/5bc6/39ab/9c922ffb1b75064fa7ac5b030739b39e?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MS054VJ1BI8jBeGEx6N6Zs~bh0QwCIpSA0LcZ9Ken0mnf8YKFkr~1mLSyl3H5xr11AwpK9ycpL67fqDaiUq5CwhKX7w0NpbskBFKX9Nxjh6s8rD~ms2Qhkwe~nFavFNMwsD~s8K0m7X16qKnlR1lnc21JiQK2eV0PpRTw6C9R1tJ7HrPDjE2HhVh4ypw5mJZjkfxmDmKNypaIbHdYCj1DiaDsOlxUXFFJxiWxShTXtN0ixBPfEEfh3m2c3EpZQ2DwvM~C7mSejqxT8d~7PncPLxvdG6neMJvlE9Du3AbNvGBxlMXrkLFjMR83bMQPJzWvhY6LcNW2Xg~DLg-0w8tnA__',
                    shortText:
                        'Поможем вам вернуть неактивных водителей на линию неактивных водителей',
                    fullText: `«Возврат водителей» - это автоматизированная система, которая поможет вам вернуть неактивных водителей в свой таксопарк. <br/> <br/>
                    Она выбирает тех, которые не выполнял заказы больше 28 дней. Раз в месяц мы звоним им, отправляем смс и пуши в Яндекс Про. <br/> <br/>
                    По нашей статистике, за первые 3 недели с момента коммуникации на линию возвращается до 15% неактивных водителей. <br/> <br/> «Возврат водителей» позволит вам: <br/> <br/> - Сократить расходы на привлечение -- вернуть водителя на 80% дешевле, чем найти нового <br/>- Сэкономить ресурсы сотрудников -- работой с ушедшими водителями займёмся мы. <br/>- Работать с надёжными водителями - с теми, с кем вы уже сотрудничали.<br/>- Иметь прозрачную отчётность - вы будете каждую неделю получать отчёты, кто вернулся и кого пытались вернуть`,
                    url: 'https://www.google.com',
                },
            ],
        },
        {
            sectionId: 'sectionId01',
            name: 'Заголовок блока 2',
            banners: [
                {
                    bannerId: 'bannerId0',
                    title: 'Возврат водителей',
                    pictureId:
                        'https://s3-alpha-sig.figma.com/img/5bc6/39ab/9c922ffb1b75064fa7ac5b030739b39e?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MS054VJ1BI8jBeGEx6N6Zs~bh0QwCIpSA0LcZ9Ken0mnf8YKFkr~1mLSyl3H5xr11AwpK9ycpL67fqDaiUq5CwhKX7w0NpbskBFKX9Nxjh6s8rD~ms2Qhkwe~nFavFNMwsD~s8K0m7X16qKnlR1lnc21JiQK2eV0PpRTw6C9R1tJ7HrPDjE2HhVh4ypw5mJZjkfxmDmKNypaIbHdYCj1DiaDsOlxUXFFJxiWxShTXtN0ixBPfEEfh3m2c3EpZQ2DwvM~C7mSejqxT8d~7PncPLxvdG6neMJvlE9Du3AbNvGBxlMXrkLFjMR83bMQPJzWvhY6LcNW2Xg~DLg-0w8tnA__',
                    shortText:
                        'Поможем вам вернуть неактивных водителей на линию неактивных водителей',
                    fullText: `«Возврат водителей» - это автоматизированная система, которая поможет вам вернуть неактивных водителей в свой таксопарк. <br/> <br/>
                    Она выбирает тех, которые не выполнял заказы больше 28 дней. Раз в месяц мы звоним им, отправляем смс и пуши в Яндекс Про. <br/> <br/>
                    По нашей статистике, за первые 3 недели с момента коммуникации на линию возвращается до 15% неактивных водителей. <br/> <br/> «Возврат водителей» позволит вам: <br/> <br/> - Сократить расходы на привлечение -- вернуть водителя на 80% дешевле, чем найти нового <br/>- Сэкономить ресурсы сотрудников -- работой с ушедшими водителями займёмся мы. <br/>- Работать с надёжными водителями - с теми, с кем вы уже сотрудничали.<br/>- Иметь прозрачную отчётность - вы будете каждую неделю получать отчёты, кто вернулся и кого пытались вернуть`,
                    url: 'https://www.google.com',
                },
                {
                    bannerId: 'bannerId01',
                    title: 'Возврат водителей',
                    pictureId:
                        'https://s3-alpha-sig.figma.com/img/5bc6/39ab/9c922ffb1b75064fa7ac5b030739b39e?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MS054VJ1BI8jBeGEx6N6Zs~bh0QwCIpSA0LcZ9Ken0mnf8YKFkr~1mLSyl3H5xr11AwpK9ycpL67fqDaiUq5CwhKX7w0NpbskBFKX9Nxjh6s8rD~ms2Qhkwe~nFavFNMwsD~s8K0m7X16qKnlR1lnc21JiQK2eV0PpRTw6C9R1tJ7HrPDjE2HhVh4ypw5mJZjkfxmDmKNypaIbHdYCj1DiaDsOlxUXFFJxiWxShTXtN0ixBPfEEfh3m2c3EpZQ2DwvM~C7mSejqxT8d~7PncPLxvdG6neMJvlE9Du3AbNvGBxlMXrkLFjMR83bMQPJzWvhY6LcNW2Xg~DLg-0w8tnA__',
                    shortText:
                        'Поможем вам вернуть неактивных водителей на линию неактивных водителей',
                    fullText: `«Возврат водителей» - это автоматизированная система, которая поможет вам вернуть неактивных водителей в свой таксопарк. <br/> <br/>
                    Она выбирает тех, которые не выполнял заказы больше 28 дней. Раз в месяц мы звоним им, отправляем смс и пуши в Яндекс Про. <br/> <br/>
                    По нашей статистике, за первые 3 недели с момента коммуникации на линию возвращается до 15% неактивных водителей. <br/> <br/> «Возврат водителей» позволит вам: <br/> <br/> - Сократить расходы на привлечение -- вернуть водителя на 80% дешевле, чем найти нового <br/>- Сэкономить ресурсы сотрудников -- работой с ушедшими водителями займёмся мы. <br/>- Работать с надёжными водителями - с теми, с кем вы уже сотрудничали.<br/>- Иметь прозрачную отчётность - вы будете каждую неделю получать отчёты, кто вернулся и кого пытались вернуть`,
                    url: 'https://www.google.com',
                },
                {
                    bannerId: 'bannerId02',
                    title: 'Возврат водителей',
                    pictureId:
                        'https://s3-alpha-sig.figma.com/img/5bc6/39ab/9c922ffb1b75064fa7ac5b030739b39e?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MS054VJ1BI8jBeGEx6N6Zs~bh0QwCIpSA0LcZ9Ken0mnf8YKFkr~1mLSyl3H5xr11AwpK9ycpL67fqDaiUq5CwhKX7w0NpbskBFKX9Nxjh6s8rD~ms2Qhkwe~nFavFNMwsD~s8K0m7X16qKnlR1lnc21JiQK2eV0PpRTw6C9R1tJ7HrPDjE2HhVh4ypw5mJZjkfxmDmKNypaIbHdYCj1DiaDsOlxUXFFJxiWxShTXtN0ixBPfEEfh3m2c3EpZQ2DwvM~C7mSejqxT8d~7PncPLxvdG6neMJvlE9Du3AbNvGBxlMXrkLFjMR83bMQPJzWvhY6LcNW2Xg~DLg-0w8tnA__',
                    shortText:
                        'Поможем вам вернуть неактивных водителей на линию неактивных водителей',
                    fullText: `«Возврат водителей» - это автоматизированная система, которая поможет вам вернуть неактивных водителей в свой таксопарк. <br/> <br/>
                    Она выбирает тех, которые не выполнял заказы больше 28 дней. Раз в месяц мы звоним им, отправляем смс и пуши в Яндекс Про. <br/> <br/>
                    По нашей статистике, за первые 3 недели с момента коммуникации на линию возвращается до 15% неактивных водителей. <br/> <br/> «Возврат водителей» позволит вам: <br/> <br/> - Сократить расходы на привлечение -- вернуть водителя на 80% дешевле, чем найти нового <br/>- Сэкономить ресурсы сотрудников -- работой с ушедшими водителями займёмся мы. <br/>- Работать с надёжными водителями - с теми, с кем вы уже сотрудничали.<br/>- Иметь прозрачную отчётность - вы будете каждую неделю получать отчёты, кто вернулся и кого пытались вернуть`,
                    url: 'https://www.google.com',
                },
                {
                    bannerId: 'bannerId03',
                    title: 'Возврат водителей',
                    pictureId:
                        'https://s3-alpha-sig.figma.com/img/5bc6/39ab/9c922ffb1b75064fa7ac5b030739b39e?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MS054VJ1BI8jBeGEx6N6Zs~bh0QwCIpSA0LcZ9Ken0mnf8YKFkr~1mLSyl3H5xr11AwpK9ycpL67fqDaiUq5CwhKX7w0NpbskBFKX9Nxjh6s8rD~ms2Qhkwe~nFavFNMwsD~s8K0m7X16qKnlR1lnc21JiQK2eV0PpRTw6C9R1tJ7HrPDjE2HhVh4ypw5mJZjkfxmDmKNypaIbHdYCj1DiaDsOlxUXFFJxiWxShTXtN0ixBPfEEfh3m2c3EpZQ2DwvM~C7mSejqxT8d~7PncPLxvdG6neMJvlE9Du3AbNvGBxlMXrkLFjMR83bMQPJzWvhY6LcNW2Xg~DLg-0w8tnA__',
                    shortText:
                        'Поможем вам вернуть неактивных водителей на линию неактивных водителей',
                    fullText: `«Возврат водителей» - это автоматизированная система, которая поможет вам вернуть неактивных водителей в свой таксопарк. <br/> <br/>
                    Она выбирает тех, которые не выполнял заказы больше 28 дней. Раз в месяц мы звоним им, отправляем смс и пуши в Яндекс Про. <br/> <br/>
                    По нашей статистике, за первые 3 недели с момента коммуникации на линию возвращается до 15% неактивных водителей. <br/> <br/> «Возврат водителей» позволит вам: <br/> <br/> - Сократить расходы на привлечение -- вернуть водителя на 80% дешевле, чем найти нового <br/>- Сэкономить ресурсы сотрудников -- работой с ушедшими водителями займёмся мы. <br/>- Работать с надёжными водителями - с теми, с кем вы уже сотрудничали.<br/>- Иметь прозрачную отчётность - вы будете каждую неделю получать отчёты, кто вернулся и кого пытались вернуть`,
                    url: 'https://www.google.com',
                },
                {
                    bannerId: 'bannerId04',
                    title: 'Возврат водителей',
                    pictureId:
                        'https://s3-alpha-sig.figma.com/img/5bc6/39ab/9c922ffb1b75064fa7ac5b030739b39e?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MS054VJ1BI8jBeGEx6N6Zs~bh0QwCIpSA0LcZ9Ken0mnf8YKFkr~1mLSyl3H5xr11AwpK9ycpL67fqDaiUq5CwhKX7w0NpbskBFKX9Nxjh6s8rD~ms2Qhkwe~nFavFNMwsD~s8K0m7X16qKnlR1lnc21JiQK2eV0PpRTw6C9R1tJ7HrPDjE2HhVh4ypw5mJZjkfxmDmKNypaIbHdYCj1DiaDsOlxUXFFJxiWxShTXtN0ixBPfEEfh3m2c3EpZQ2DwvM~C7mSejqxT8d~7PncPLxvdG6neMJvlE9Du3AbNvGBxlMXrkLFjMR83bMQPJzWvhY6LcNW2Xg~DLg-0w8tnA__',
                    shortText:
                        'Поможем вам вернуть неактивных водителей на линию неактивных водителей',
                    fullText: `«Возврат водителей» - это автоматизированная система, которая поможет вам вернуть неактивных водителей в свой таксопарк. <br/> <br/>
                    Она выбирает тех, которые не выполнял заказы больше 28 дней. Раз в месяц мы звоним им, отправляем смс и пуши в Яндекс Про. <br/> <br/>
                    По нашей статистике, за первые 3 недели с момента коммуникации на линию возвращается до 15% неактивных водителей. <br/> <br/> «Возврат водителей» позволит вам: <br/> <br/> - Сократить расходы на привлечение -- вернуть водителя на 80% дешевле, чем найти нового <br/>- Сэкономить ресурсы сотрудников -- работой с ушедшими водителями займёмся мы. <br/>- Работать с надёжными водителями - с теми, с кем вы уже сотрудничали.<br/>- Иметь прозрачную отчётность - вы будете каждую неделю получать отчёты, кто вернулся и кого пытались вернуть`,
                    url: 'https://www.google.com',
                },
                {
                    bannerId: 'bannerId05',
                    title: 'Возврат водителей',
                    pictureId:
                        'https://s3-alpha-sig.figma.com/img/5bc6/39ab/9c922ffb1b75064fa7ac5b030739b39e?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MS054VJ1BI8jBeGEx6N6Zs~bh0QwCIpSA0LcZ9Ken0mnf8YKFkr~1mLSyl3H5xr11AwpK9ycpL67fqDaiUq5CwhKX7w0NpbskBFKX9Nxjh6s8rD~ms2Qhkwe~nFavFNMwsD~s8K0m7X16qKnlR1lnc21JiQK2eV0PpRTw6C9R1tJ7HrPDjE2HhVh4ypw5mJZjkfxmDmKNypaIbHdYCj1DiaDsOlxUXFFJxiWxShTXtN0ixBPfEEfh3m2c3EpZQ2DwvM~C7mSejqxT8d~7PncPLxvdG6neMJvlE9Du3AbNvGBxlMXrkLFjMR83bMQPJzWvhY6LcNW2Xg~DLg-0w8tnA__',
                    shortText:
                        'Поможем вам вернуть неактивных водителей на линию неактивных водителей',
                    fullText: `«Возврат водителей» - это автоматизированная система, которая поможет вам вернуть неактивных водителей в свой таксопарк. <br/> <br/>
                    Она выбирает тех, которые не выполнял заказы больше 28 дней. Раз в месяц мы звоним им, отправляем смс и пуши в Яндекс Про. <br/> <br/>
                    По нашей статистике, за первые 3 недели с момента коммуникации на линию возвращается до 15% неактивных водителей. <br/> <br/> «Возврат водителей» позволит вам: <br/> <br/> - Сократить расходы на привлечение -- вернуть водителя на 80% дешевле, чем найти нового <br/>- Сэкономить ресурсы сотрудников -- работой с ушедшими водителями займёмся мы. <br/>- Работать с надёжными водителями - с теми, с кем вы уже сотрудничали.<br/>- Иметь прозрачную отчётность - вы будете каждую неделю получать отчёты, кто вернулся и кого пытались вернуть`,
                    url: 'https://www.google.com',
                },
            ],
        },
    ],
};

export type TMockNewsesData = typeof mockNewsesData;

const banner: TBanner = {
    id: 'bannerId05',
    title: 'Возврат водителей',
    pictureId:
        'https://s3-alpha-sig.figma.com/img/5bc6/39ab/9c922ffb1b75064fa7ac5b030739b39e?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=MS054VJ1BI8jBeGEx6N6Zs~bh0QwCIpSA0LcZ9Ken0mnf8YKFkr~1mLSyl3H5xr11AwpK9ycpL67fqDaiUq5CwhKX7w0NpbskBFKX9Nxjh6s8rD~ms2Qhkwe~nFavFNMwsD~s8K0m7X16qKnlR1lnc21JiQK2eV0PpRTw6C9R1tJ7HrPDjE2HhVh4ypw5mJZjkfxmDmKNypaIbHdYCj1DiaDsOlxUXFFJxiWxShTXtN0ixBPfEEfh3m2c3EpZQ2DwvM~C7mSejqxT8d~7PncPLxvdG6neMJvlE9Du3AbNvGBxlMXrkLFjMR83bMQPJzWvhY6LcNW2Xg~DLg-0w8tnA__',
    shortText:
        'Поможем вам вернуть неактивных водителей на линию неактивных водителей',
    fullText: `«Возврат водителей» - это автоматизированная система, которая поможет вам вернуть неактивных водителей в свой таксопарк. <br/> <br/>
    Она выбирает тех, которые не выполнял заказы больше 28 дней. Раз в месяц мы звоним им, отправляем смс и пуши в Яндекс Про. <br/> <br/>
    По нашей статистике, за первые 3 недели с момента коммуникации на линию возвращается до 15% неактивных водителей. <br/> <br/> «Возврат водителей» позволит вам: <br/> <br/> - Сократить расходы на привлечение -- вернуть водителя на 80% дешевле, чем найти нового <br/>- Сэкономить ресурсы сотрудников -- работой с ушедшими водителями займёмся мы. <br/>- Работать с надёжными водителями - с теми, с кем вы уже сотрудничали.<br/>- Иметь прозрачную отчётность - вы будете каждую неделю получать отчёты, кто вернулся и кого пытались вернуть`,
    url: 'https://www.google.com',
};

export const mockNewses: TNews[] = [
    {
        id: '1',
        title: 'Сеция 1',
        banners: [banner, banner],
    },
    {
        id: '2',
        title: 'Сеция 2',
        banners: [banner],
    },
    {
        id: '3',
        title: 'Сеция 3',
        banners: [banner, banner, banner, banner, banner, banner],
    },
];
