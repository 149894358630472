import { Box, CircularProgress, Stack } from '@mui/material';

import { FormClientIdProvider, TFormData } from '@features/form-client-id';
import { TExternalFormError } from '@features/form-client-id/form-client-id-provider';
import { DoneIcon } from '@shared/ui/icons';

type TProps = {
    defaultValue?: number;
    isSuccess: boolean;
    isSubmitting: boolean;
    externalFormError?: TExternalFormError;
    onSubmit: (data: TFormData) => void;
};

export const ChangeClientIdPage = ({
    defaultValue,
    isSuccess,
    isSubmitting,
    externalFormError,
    onSubmit,
}: TProps) => {
    if (isSubmitting) {
        return (
            <Box color={theme => theme.palette.common.white}>
                <CircularProgress color="inherit" size="80px" />
            </Box>
        );
    }
    if (isSuccess) {
        return (
            <Box color={theme => theme.palette.common.white}>
                <DoneIcon />
            </Box>
        );
    }
    return (
        <Stack
            height="100%"
            justifyContent={{ xs: 'center', md: 'start' }}
            paddingX={{ xs: '8px', md: 0 }}>
            <FormClientIdProvider
                onSubmit={onSubmit}
                buttonText="изменить"
                defaultValue={defaultValue}
                externalFormError={externalFormError}
            />
        </Stack>
    );
};
