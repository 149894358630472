import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

type TCartAmountState = {
    cartAmount: number | null;
    set: (newCartAmount: number) => void;
    reset: () => void;
};

export const useCartAmountStore = create<TCartAmountState>()(
    persist(
        set => ({
            cartAmount: null,
            set: newCartAmount => set({ cartAmount: newCartAmount }),
            reset: () => set({ cartAmount: null }),
        }),
        {
            name: 'amountCartStore',
            storage: createJSONStorage(() => sessionStorage),
        },
    ),
);
