import { RouteObject } from 'react-router-dom';

import { MetaTitle } from '@shared/ui/atoms/meta-title';

import { ChangeClientIdPageConnector } from './change-client-id';
import { ChangeProvidersPageConnector } from './change-providers';
import { HelpConnector } from './help';
import { AccountPageConnector } from './home';
import { accountPaths } from './paths';

export const routes: RouteObject[] = [
    {
        path: accountPaths.home.absolute,
        element: (
            <>
                <MetaTitle>Личные данные</MetaTitle>
                <AccountPageConnector />
            </>
        ),
    },
    {
        path: accountPaths.providers.absolute,
        element: (
            <>
                <MetaTitle>Изменение поставщика</MetaTitle>
                <ChangeProvidersPageConnector />
            </>
        ),
    },
    {
        path: accountPaths.help.absolute,
        element: (
            <>
                <MetaTitle>Вопросы и ответы</MetaTitle>
                <HelpConnector />
            </>
        ),
    },
    {
        path: accountPaths.clientId.absolute,
        element: (
            <>
                <MetaTitle>Изменение client id</MetaTitle>
                <ChangeClientIdPageConnector />
            </>
        ),
    },
];
