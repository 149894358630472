import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Slide, Stack } from '@mui/material';

import { TProvider } from '@entities/providers/types';
import { Logo } from '@shared/ui/atoms/logo';

import { ProviderAddCredential } from '../provider-add-credential';
import { TFormData } from '../provider-add-credential/provider-add-credential';

type TProps = {
    open: boolean;
    provider: TProvider;
    defaultValues?: TFormData;
    onClose: () => void;
    onSubmit: (data: TFormData, id: string) => void;
    onRemove: (id: string) => void;
};

export const ProviderCredentialModal = ({
    open,
    provider,
    defaultValues,
    onClose,
    onSubmit,
    onRemove,
}: TProps) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Slide
                direction="down"
                in={open}
                mountOnEnter
                timeout={300}
                unmountOnExit>
                <Box
                    height={{ xs: '100dvh', md: '90dvh' }}
                    width="100vw"
                    overflow="auto"
                    paddingBottom="20px"
                    sx={theme => ({
                        background: theme.palette.bg.primary,
                    })}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        padding={{ xs: '10px', md: '30px 40px' }}>
                        <Logo />
                        <IconButton onClick={onClose} color="buttonPrimary">
                            <CloseIcon sx={{ fontSize: '42px' }} />
                        </IconButton>
                    </Stack>
                    <ProviderAddCredential
                        title={provider?.title}
                        fields={provider.credentialFields}
                        instantOrderAvailable={provider?.instantOrderAvailable}
                        defaultValues={defaultValues}
                        onClose={onClose}
                        onSubmit={data => onSubmit(data, provider.id)}
                        onRemove={() => onRemove(provider.id)}
                    />
                </Box>
            </Slide>
        </Modal>
    );
};
