import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    Grid,
    Hidden,
    IconButton,
    Modal,
    Slide,
    Stack,
    Typography,
} from '@mui/material';

import { Conditional } from '@shared/ui/atoms/conditional';
import { Logo } from '@shared/ui/atoms/logo';
import { ExclamationMarkIcon, QuestionMarkIcon } from '@shared/ui/icons';

type TModalVariant = 'question' | 'error';

type TProps = {
    title: string;
    description?: string;
    primaryButtonText: string;
    secondaryButtonText?: string;
    open: boolean;
    variant: TModalVariant;
    withHeader?: boolean;
    withCloseIcon?: boolean;
    onClose?: () => void;
    onPrimaryButtonClick: () => void;
    onSecondaryButtonClick?: () => void;
};

const getIconVariant = (variant: TModalVariant) => {
    switch (variant) {
        case 'error':
            return (
                <Stack
                    maxHeight="270px"
                    maxWidth="270px"
                    alignItems="center"
                    justifyContent="center">
                    <ExclamationMarkIcon />
                </Stack>
            );
        case 'question':
            return <QuestionMarkIcon />;
        default:
            return <></>;
    }
};

export const InfoModal = ({
    title,
    description,
    primaryButtonText,
    secondaryButtonText,
    open,
    variant,
    withHeader,
    withCloseIcon = true,
    onClose,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
}: TProps) => {
    return (
        <Modal open={open} onClose={onClose}>
            <Slide
                direction="down"
                in={open}
                mountOnEnter
                timeout={300}
                unmountOnExit>
                <Stack
                    height={{ xs: '100dvh', md: '60dvh' }}
                    width="100vw"
                    direction="column"
                    position="relative"
                    sx={theme => ({
                        background: theme.palette.bg.primary,
                        outlineColor: 'transparent',
                    })}>
                    <Conditional condition={withHeader}>
                        <Stack
                            direction="row"
                            width="100%"
                            zIndex={10}
                            position={{ xs: 'relative', md: 'absolute' }}
                            justifyContent="space-between"
                            alignItems="center"
                            padding="30px">
                            <Logo />
                            <Conditional condition={withCloseIcon}>
                                <IconButton
                                    onClick={onClose}
                                    color="buttonPrimary">
                                    <CloseIcon fontSize="large" />
                                </IconButton>
                            </Conditional>
                        </Stack>
                    </Conditional>
                    <Box
                        width="100%"
                        height="100%"
                        position="relative"
                        display="flex">
                        <Grid container direction="row">
                            <Grid item md={2} xs={0} />
                            <Grid item xs={12} md={6}>
                                <Stack
                                    gap="20px"
                                    height="100%"
                                    zIndex={2}
                                    justifyContent="center"
                                    position="relative"
                                    textAlign={{ xs: 'center', md: 'start' }}>
                                    <Typography variant="h2">
                                        {title}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color={theme =>
                                            theme.palette.typography.secondary
                                        }>
                                        {description}
                                    </Typography>
                                    <Stack
                                        direction={{ xs: 'column', md: 'row' }}
                                        gap="20px"
                                        marginTop="20px">
                                        <Button
                                            sx={{ minWidth: '150px' }}
                                            variant="contained"
                                            color="buttonPrimary"
                                            onClick={onPrimaryButtonClick}>
                                            {primaryButtonText}
                                        </Button>
                                        <Conditional
                                            condition={Boolean(
                                                secondaryButtonText &&
                                                    onSecondaryButtonClick,
                                            )}>
                                            <Button
                                                variant="contained"
                                                color="buttonSecondaryEnabled"
                                                onClick={
                                                    onSecondaryButtonClick
                                                }>
                                                {secondaryButtonText}
                                            </Button>
                                        </Conditional>
                                    </Stack>
                                </Stack>
                                <Grid />
                            </Grid>
                        </Grid>
                        <Hidden mdDown>
                            <Stack
                                position="absolute"
                                direction="row"
                                alignItems="center"
                                top="100px"
                                zIndex={1}
                                right="10%"
                                color={theme =>
                                    theme.palette.action.disabledBackground
                                }
                                height="calc(90% - 100px)">
                                {getIconVariant(variant)}
                            </Stack>
                        </Hidden>
                    </Box>
                </Stack>
            </Slide>
        </Modal>
    );
};
