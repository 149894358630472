import { ProductInfo } from '@shared/api/axios-client';
import { arrayUtils, dateUtils } from '@shared/lib/utils';

export const productSort = (
    products: ProductInfo[],
    attribute: string,
    order: string,
) => {
    return products.sort((a, b) => {
        switch (attribute) {
            case 'price':
                return order === 'DESC'
                    ? a.warehouse.price - b.warehouse.price
                    : b.warehouse.price - a.warehouse.price;
            case 'deliveryDate':
                const dataA = dateUtils.validateDate(
                    a.warehouse.shipmentDate || '',
                );
                const dataB = dateUtils.validateDate(
                    b.warehouse.shipmentDate || '',
                );
                return order === 'DESC'
                    ? dataA.getTime() - dataB.getTime()
                    : dataB.getTime() - dataA.getTime();
            case 'name':
                if (order === 'DESC') {
                    return arrayUtils.alphabeticalSort(
                        a.product.name,
                        b.product.name,
                    );
                }
                return arrayUtils.alphabeticalSort(
                    b.product.name,
                    a.product.name,
                );
            default:
                return 0;
        }
    });
};
