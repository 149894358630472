import { Fade, Stack, Typography } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';

import { TFavoriteItem } from '@entities/favorite/types';
import { FavoriteCardItem } from '@entities/favorite/ui/favorite-card-item';
import { Conditional } from '@shared/ui/atoms/conditional';

type TProps = {
    items: TFavoriteItem[];
    count: number;
    onNavigate: (vendorCode: string, brand: string) => void;
    onRemove: (id: string) => void;
};

export const FavoritePage = ({
    count,
    items,
    onNavigate,
    onRemove,
}: TProps) => {
    return (
        <Stack width="100%" gap="56px" paddingTop="36px">
            <TransitionGroup
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    gap: '20px',
                }}>
                {items.map(item => (
                    <Fade in key={item.id}>
                        {/* https://stackoverflow.com/questions/74862197/typeerror-cannot-read-properties-of-null-reading-scrolltop-material-ui */}
                        <div>
                            <FavoriteCardItem
                                title={item.productName}
                                subTitle={item.vendorCode}
                                subDescription={item.brand}
                                onAction={() =>
                                    onNavigate(item.vendorCode, item.brand)
                                }
                                onRemove={() => onRemove(item.id)}
                            />
                        </div>
                    </Fade>
                ))}
                <Conditional condition={!Boolean(count)}>
                    <Typography
                        variant="h2"
                        color={theme => theme.palette.typography.secondary}>
                        В избранном пока ничего нет...
                    </Typography>
                    <Typography
                        variant="body2"
                        color={theme => theme.palette.typography.secondary}>
                        воспользуйтесь поиском, чтобы добавить товар
                    </Typography>
                </Conditional>
            </TransitionGroup>
        </Stack>
    );
};
