import { Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';

import { TNews } from '@entities/news/types';
import { NewsSection } from '@entities/news/ui/news-section';

type TProps = {
    newses: TNews[];
    AuthControl: ReactElement;
    onBannerClick: (sectionId: string, bannerId: string) => void;
};

export const CompanyAuthPage = ({
    newses,
    AuthControl,
    onBannerClick,
}: TProps) => {
    return (
        <Stack gap="100px">
            <Stack gap="80px" height="200px">
                <Typography variant="h2">
                    Поиск автозапчастей по всей <br /> России
                </Typography>
                {AuthControl}
            </Stack>
            <Stack gap="50px">
                {newses.map(news => (
                    <NewsSection
                        key={news.id}
                        news={news}
                        onBannerClick={bannerId =>
                            onBannerClick(news.id, bannerId)
                        }
                    />
                ))}
            </Stack>
        </Stack>
    );
};
