import { Avatar, Box, Button, Grid, Stack, Typography } from '@mui/material';

import { ClientIdCard } from '@entities/user/ui/client-id-card';
import { TUserInfo } from '@pages/account/types';

type TProps = {
    userInfo: TUserInfo;
    changeInfoLink: string;
    onChangeClientID: () => void;
};

export const AccountPage = ({
    userInfo,
    changeInfoLink,
    onChangeClientID,
}: TProps) => {
    return (
        <Grid container direction="column">
            <Grid
                container
                direction="row"
                spacing={{ xs: '16px', md: '40px' }}
                justifyContent={{ xs: 'center', md: 'start' }}
                textAlign={{ xs: 'center', md: 'start' }}>
                <Grid item xs={6} md={2} paddingLeft="20px">
                    <Avatar
                        src={userInfo.avatarUrl}
                        sx={theme => ({
                            width: '100%',
                            height: 'auto',
                            aspectRatio: '1',
                            border: `4px solid ${theme.palette.icon.secondaryInvert}`,
                        })}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Stack direction="column">
                        <Typography
                            variant="h1"
                            sx={{ wordBreak: 'break-word' }}>
                            {userInfo.firstName} {userInfo.middleName}
                        </Typography>
                        <Typography
                            variant="h3"
                            color={theme => theme.palette.typography.secondary}
                            sx={{ wordBreak: 'break-word' }}>
                            #{userInfo.login}
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                spacing={{ xs: '16px', md: '40px' }}
                paddingTop="40px">
                <Grid item md={2} />
                <Grid
                    item
                    xs={12}
                    md={6}
                    textAlign={{ xs: 'center', md: 'start' }}>
                    <Stack
                        direction="column"
                        gap={{ xs: '8px', md: '40px' }}
                        overflow="hidden">
                        <Stack
                            direction="column"
                            gap={{ xs: '8px', md: '20px' }}
                            width="100%"
                            overflow="hidden">
                            <Typography
                                variant="caption2"
                                color={theme =>
                                    theme.palette.typography.secondary
                                }
                                textOverflow="ellipsis"
                                overflow="hidden">
                                номер телефона
                            </Typography>
                            <Typography
                                variant="h3"
                                sx={{ wordBreak: 'break-word' }}>
                                {userInfo.phone}
                            </Typography>
                        </Stack>
                        <Stack
                            direction="column"
                            gap={{ xs: '8px', md: '20px' }}>
                            <Typography
                                variant="caption2"
                                color={theme =>
                                    theme.palette.typography.secondary
                                }
                                textOverflow="ellipsis"
                                overflow="hidden">
                                email
                            </Typography>
                            <Typography
                                variant="h3"
                                sx={{ wordBreak: 'break-word' }}>
                                {userInfo.email}
                            </Typography>
                        </Stack>
                        <Box>
                            <Button
                                href={changeInfoLink}
                                target="_blank"
                                sx={{ width: { xs: '100%', md: 'auto' } }}
                                variant="contained"
                                color="buttonPrimary">
                                изменить данные
                            </Button>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={4}>
                    <ClientIdCard
                        onClick={onChangeClientID}
                        clientId={userInfo.clientID}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};
