import {
    startHeadersInterceptor,
    startSessionInterceptor,
} from '@kode-frontend/session-interceptor';
import { AxiosInstance } from 'axios';
import { useCallback, useEffect } from 'react';

import { logoutPaths } from '@pages/logout/paths';
import { authApi } from '@shared/api';
import { CreateAuthCredentialsResponse } from '@shared/api/axios-client';
import { ENV } from '@shared/config/constants';

import { useSessionStore } from '../model';

type Props = { axiosInstances: AxiosInstance[]; children: JSX.Element };

export const SessionProvider = ({ axiosInstances, children }: Props) => {
    const sessionStore = useSessionStore();
    const tokensGetter =
        useCallback(async (): Promise<CreateAuthCredentialsResponse> => {
            const { session } = useSessionStore.getState();
            const { data } = await authApi.postV1Refresh(
                {
                    createRefreshModelRequest: {
                        refreshToken: session?.refreshToken || '',
                    },
                },
                {
                    url: ENV.refreshPath,
                },
            );
            return data;
        }, []);

    const onInvalidRefreshResponse = useCallback(() => {
        window.location.href = logoutPaths.home.absolute;
    }, []);

    const getHeaders = useCallback(() => {
        const { session } = useSessionStore.getState();
        return [
            {
                key: 'Authorization',
                value: `Bearer ${session?.accessToken || ''}`,
            },
        ];
    }, []);

    useEffect(() => {
        startHeadersInterceptor({
            getHeaders,
        })(axiosInstances);

        startSessionInterceptor({
            storage: {
                storageGetter: key => localStorage.getItem(key),
                storageSetter: (key, value) => localStorage.setItem(key, value),
            },
            invalidAccessTokenErrors: [
                {
                    code: 'AccessTokenExpired',
                    status: 401,
                },
                {
                    code: 'AccessTokenInvalid',
                    status: 401,
                },
            ],
            invalidRefreshTokenErrors: [
                {
                    code: 'RefreshTokenInvalid',
                    status: 404,
                },
            ],
            tokensGetter,
            onGotNewTokens: tokens => sessionStore.set(tokens),
            onInvalidRefreshResponse,
        })(axiosInstances);
    }, [
        axiosInstances,
        tokensGetter,
        onInvalidRefreshResponse,
        getHeaders,
        sessionStore,
    ]);

    return children;
};
