import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { authPaths } from '@pages/auth/paths';
import { useLogin } from '@shared/session';

type TProps = {
    children: ReactElement | null;
};

export const Protected = ({ children }: TProps) => {
    const isLogin = useLogin();
    if (!isLogin) {
        return <Navigate to={authPaths.home.absolute} />;
    }
    return children;
};
