import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useSetClid } from '@entities/user/model/hooks';
import { TFormData } from '@features/form-client-id';
import { TExternalFormError } from '@features/form-client-id/form-client-id-provider';
import { providerPaths } from '@pages/provider/paths';
import { useYandexReachGoal, useYandexUserParams } from '@shared/hooks';
import { useSessionStore, useUserInfoStore } from '@shared/session';
import { InfoTemplate } from '@shared/ui/templates/info-template';
import { PageTemplate } from '@shared/ui/templates/page-template';
import { HeaderWidgetConnector } from '@widgets/header';

import { ClientIdPage } from './client-id-page';
import { ClientIdHeader } from './ui/client-id-header';

export const ClientIdPageConnector = () => {
    const navigate = useNavigate();
    const [newClid, setNewClid] = useState<number>();
    const [externalFormError, setExternalFormError] = useState<
        TExternalFormError | undefined
    >();
    const { enqueueSnackbar } = useSnackbar();
    const fireProductEvent = useYandexReachGoal();
    const changeUserParams = useYandexUserParams();
    const sessionStore = useSessionStore();
    const userInfoStore = useUserInfoStore();
    const { setClid, isLoading } = useSetClid({
        onSuccess: data => {
            sessionStore.set(data.data);
            fireProductEvent('registerClientID');
            changeUserParams({ userID: newClid });
            userInfoStore.update({ clientId: newClid });
            navigate(providerPaths.home.absolute, {
                state: { fromAuth: true },
            });
        },
        onError: e => {
            if (e.code === 'ClientIdInvalid') {
                setExternalFormError({
                    name: 'clientId',
                    message: e.message,
                    type: 'api_error',
                });
                return;
            }
            fireProductEvent('errorClientID');
            enqueueSnackbar('что-то пошло не так', {
                variant: 'error',
                description: 'Повторите попытку',
            });
        },
    });
    const handleSubmit = useCallback(
        (data: TFormData) => {
            const clid = Number(data.clientId.replaceAll('-', ''));
            setNewClid(clid);
            setClid({ clientId: clid });
        },
        [setClid],
    );
    if (isLoading) {
        return (
            <InfoTemplate
                Header={<HeaderWidgetConnector withLogo variant="secondary" />}
                title="один момент..."
                CenterSlot={
                    <ClientIdPage
                        onSubmit={handleSubmit}
                        isSubmitting={isLoading}
                    />
                }
            />
        );
    }
    return (
        <PageTemplate
            withHeadSpace
            variant="medium"
            Header={
                <HeaderWidgetConnector withLogo variant="primary">
                    <ClientIdHeader />
                </HeaderWidgetConnector>
            }>
            <ClientIdPage
                onSubmit={handleSubmit}
                isSubmitting={false}
                externalFormError={externalFormError}
            />
        </PageTemplate>
    );
};
