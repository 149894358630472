import { CartProduct, CartProductGroup } from '@shared/api/axios-client';

import { TCartValue } from '../../catalog-page';

export const mapCart = (cartProducts?: CartProductGroup[]): TCartValue[] => {
    if (!cartProducts) {
        return [];
    }
    const allProducts = cartProducts.reduce<CartProduct[]>(
        (acc, group) => [...acc, ...group.products],
        [],
    );
    return allProducts.map((product, index) => ({
        position: index + 1,
        productId: product.product.id,
        warehouseId: product.warehouse.id,
        providerId: product.provider.id,
        quantity: product.warehouse.quantity,
        stock: product.warehouse.stock,
    }));
};
