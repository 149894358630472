export const providerPaths = {
    home: {
        absolute: '/provider',
        relative: 'provider',
    },
    proposition: {
        absolute: '/provider/proposition',
        relative: 'provider/proposition',
    },
} as const;
