import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useGetUserProviders } from '@entities/user/model';
import { useSearchHistoryStore } from '@entities/user/model/user-domain';
import { providerPaths } from '@pages/provider/paths';
import { useStateModal } from '@shared/hooks';
import { InfoModal } from '@shared/ui/organisms/info-modal';
import { PageTemplate } from '@shared/ui/templates/page-template';
import { HeaderWidgetConnector } from '@widgets/header';

import { SearchPage } from './search-page';
import { catalogPaths } from '../paths';

export const SearchPageConnector = () => {
    const { open, isOpen, close } = useStateModal();
    const [params] = useSearchParams();
    const navigate = useNavigate();
    const initSearch = params.get('search') || '';
    const { data } = useGetUserProviders({
        onError: () =>
            enqueueSnackbar('что-то пошло не так', {
                variant: 'error',
                description: 'попробуйте позже',
            }),
    });
    const searchHistoryStore = useSearchHistoryStore();
    const { enqueueSnackbar } = useSnackbar();
    const handleAddProvider = useCallback(() => {
        navigate(providerPaths.home.absolute);
    }, [navigate]);
    const handleSubmit = useCallback(
        (search: string) => {
            searchHistoryStore.add(search);
            if (!Boolean(data?.data.provider?.length)) {
                open();
                return;
            }
            navigate(`${catalogPaths.catalog.absolute}?search=${search}`);
        },
        [data?.data.provider?.length, navigate, open, searchHistoryStore],
    );
    return (
        <PageTemplate
            withHeadSpace
            variant="small"
            Header={
                <HeaderWidgetConnector withLogo variant="primary" withProfile>
                    <Typography
                        variant="h2"
                        textAlign={{ xs: 'center', md: 'start' }}>
                        поиск автозапчастей <br /> по всей России
                    </Typography>
                </HeaderWidgetConnector>
            }>
            <SearchPage
                suggests={searchHistoryStore.searchHistory}
                initSearch={initSearch}
                onSubmit={handleSubmit}
            />
            <InfoModal
                open={isOpen}
                withHeader
                variant="error"
                title="Пока вы не добавили ни одного поставщика"
                description="Чтобы увидеть результаты поиска, нужно добавить минимум одного"
                primaryButtonText="добавить"
                secondaryButtonText="пропустить"
                onPrimaryButtonClick={handleAddProvider}
                onSecondaryButtonClick={close}
                onClose={close}
            />
        </PageTemplate>
    );
};
