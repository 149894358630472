import { Container, Stack, Typography } from '@mui/material';

import { TCredentialField } from '@entities/providers/types';
import { FormProviderCredentialProvider } from '@features/form-provider-credential';
import { Conditional } from '@shared/ui/atoms/conditional';

import { ProviderInstantOrderInfo } from '../provider-instant-order-info';

type TProps = {
    title: string;
    fields: TCredentialField[];
    defaultValues?: TFormData;
    instantOrderAvailable?: boolean;
    onSubmit: (data: TFormData) => void;
    onClose: () => void;
    onRemove: () => void;
};

export type TFormData = {
    [key: string]: string;
};

export const ProviderAddCredential = ({
    title,
    fields,
    defaultValues,
    instantOrderAvailable,
    onSubmit,
    onRemove,
}: TProps) => {
    if (!fields.length) {
        return null;
    }

    return (
        <Container maxWidth="md">
            <Stack direction="column" gap="40px" marginTop={{ md: '5%' }}>
                <Typography
                    variant="h2"
                    textAlign={{ xs: 'center', md: 'start' }}>
                    {title}
                </Typography>
                <Conditional condition={instantOrderAvailable}>
                    <ProviderInstantOrderInfo />
                </Conditional>
                <FormProviderCredentialProvider
                    fields={fields}
                    defaultValues={defaultValues}
                    onSubmit={onSubmit}
                    onProviderRemove={onRemove}
                />
            </Stack>
        </Container>
    );
};
