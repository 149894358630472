// Deprecated
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

import {
    FormProviderProposition,
    TFormData,
} from './form-provider-proposition';

type TProps = {
    onSubmit: (data: TFormData) => void;
};

const schema = z.object({
    name: z.string().nonempty('Обязательное поле'),
    site: z.string().nullable(),
    phone: z.string().nullable(),
    email: z.string().nullable(),
});

const defaultValues: TFormData = {
    name: '',
    site: '',
    phone: '',
    email: '',
};

export const FormProviderPropositionProvider = ({ onSubmit }: TProps) => {
    const methods = useForm<TFormData>({
        mode: 'onChange',
        resolver: zodResolver(schema),
        defaultValues,
    });

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <FormProviderProposition />
            </form>
        </FormProvider>
    );
};
