import { RouteObject } from 'react-router-dom';

import { FeatureToggleGuard } from '@pages/guards';
import { MetaTitle } from '@shared/ui/atoms/meta-title';

import { FavoritePageConnector } from './favorite-page-connector';
import { favoritePaths } from './paths';

export const routes: RouteObject[] = [
    {
        path: favoritePaths.home.absolute,
        element: (
            <FeatureToggleGuard featureFlag="favorite">
                <>
                    <MetaTitle>Избранное</MetaTitle>
                    <FavoritePageConnector />
                </>
            </FeatureToggleGuard>
        ),
    },
];
