import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import EditIcon from '@mui/icons-material/Edit';
import { TabContext, TabPanel } from '@mui/lab';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    Stack,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import { useState } from 'react';

import { helpData } from './data';
type TProps = {
    onSupportClick: () => void;
};

type TMenuNames = 'vitrina' | 'providers' | 'search' | 'other';

export const Help = ({ onSupportClick }: TProps) => {
    const [activeTab, setActiveTabs] = useState<TMenuNames>('vitrina');
    return (
        <Grid container direction="row">
            <Grid item xs={0} md={2} />
            <Grid item xs={12} md={8} direction="column">
                <TabContext value={activeTab}>
                    <Stack direction="column" gap="60px">
                        <Stack direction="row">
                            <Tabs
                                value={activeTab}
                                variant="scrollable"
                                onChange={(_, value) => setActiveTabs(value)}>
                                <Tab label="О сервисе" value="vitrina" />
                                <Tab label="О поставщиках" value="providers" />
                                <Tab label="О поиске товаров" value="search" />
                                <Tab label="Другое" value="other" />
                            </Tabs>
                        </Stack>
                        <Stack>
                            {Object.keys(helpData).map(item => (
                                <TabPanel value={item}>
                                    {helpData[item as TMenuNames].map(data => (
                                        <Accordion
                                            sx={theme => ({
                                                borderRadius: '0 !important',
                                                borderBottom: `1px solid ${theme.palette.border.border3}`,
                                                '&::before': {
                                                    opacity: 0,
                                                },
                                                '&:hover > div > div > svg': {
                                                    color: theme.palette.icon
                                                        .primary,
                                                },
                                                '&:hover': {
                                                    borderColor:
                                                        theme.palette.icon
                                                            .primary,
                                                },
                                            })}>
                                            <AccordionSummary
                                                expandIcon={
                                                    <ArrowDownwardIcon fontSize="large" />
                                                }>
                                                <Typography
                                                    variant="h4"
                                                    dangerouslySetInnerHTML={{
                                                        __html: data.question,
                                                    }}
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography
                                                    variant="body2"
                                                    dangerouslySetInnerHTML={{
                                                        __html: data.answer,
                                                    }}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </TabPanel>
                            ))}
                        </Stack>
                    </Stack>
                </TabContext>
            </Grid>
            <Grid item xs={12} md={2}>
                <Stack direction="row" justifyContent="end">
                    <Button
                        variant="contained"
                        sx={{ width: { xs: '100%', md: 'auto' } }}
                        startIcon={<EditIcon />}
                        color="buttonPrimary"
                        onClick={onSupportClick}>
                        написать в поддержку
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
};
