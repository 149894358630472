import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

import { Conditional } from '@shared/ui/atoms/conditional';

type TProps = {
    InfoComponent: ReactNode;

    onHelpClick: () => void;
};

export const ProductsEmpty = ({ InfoComponent, onHelpClick }: TProps) => {
    return (
        <Stack gap="20px">
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="column" gap="20px">
                    <Typography variant="h2">
                        К сожалению, <br /> ничего не нашлось
                    </Typography>
                    <Conditional condition={!InfoComponent}>
                        <Stack direction="row" alignItems="center" gap="8px">
                            <InfoOutlinedIcon
                                sx={theme => ({
                                    fontSize: '24px',
                                    color: theme.palette.icon.tertiary,
                                })}
                            />
                            <Typography
                                variant="body2"
                                color={theme =>
                                    theme.palette.typography.secondary
                                }>
                                Убедитесь, что артикул введён правильно или
                                добавьте новых поставщиков
                            </Typography>
                        </Stack>
                    </Conditional>
                </Stack>
                <Box>
                    <Button
                        variant="contained"
                        color="buttonPrimary"
                        onClick={onHelpClick}>
                        помощь в подборе деталей
                    </Button>
                </Box>
            </Stack>
            {InfoComponent}
        </Stack>
    );
};
