import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDeleteFavorite, useGetFavorite } from '@entities/favorite/model';
import { catalogPaths } from '@pages/catalog/paths';
import { PageTemplate } from '@shared/ui/templates/page-template';
import { HeaderWidgetConnector } from '@widgets/header';

import { FavoritePage } from './favorite-page';
import { mapFavorite } from './mappers';
import { Header } from './ui/header';

const TIME_TO_CANCEL = 5000;

const snackOptions = {
    preventDuplicate: false,
    autoHideDuration: TIME_TO_CANCEL,
    variant: 'info' as const,
    withIcon: false,
    closable: false,
    actionContent: 'ОТМЕНИТЬ',
};

export const FavoritePageConnector = () => {
    const [favoriteToDelete, setFavoriteToDelete] = useState<string[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { data } = useGetFavorite();
    const { deleteFavorite } = useDeleteFavorite();

    const handleNavigate = useCallback(
        (vendorCode: string, brand: string) =>
            navigate(
                `${catalogPaths.catalog.absolute}?search=${vendorCode}&brand=${brand}`,
            ),
        [navigate],
    );

    const handleRemove = useCallback(
        (id: string) => {
            setFavoriteToDelete(prev => [...prev, id]);
            const timer = setTimeout(() => {
                deleteFavorite(id);
            }, TIME_TO_CANCEL);
            enqueueSnackbar('Удален из избранного', {
                ...snackOptions,
                onAction: () => {
                    clearTimeout(timer);
                    setFavoriteToDelete(prev =>
                        prev.filter(item => item !== id),
                    );
                },
            });
        },
        [deleteFavorite, enqueueSnackbar],
    );

    const favorites = useMemo(
        () =>
            mapFavorite(data?.data.productsFavorites).filter(
                favorite => !favoriteToDelete.includes(favorite.id),
            ),
        [data?.data.productsFavorites, favoriteToDelete],
    );

    return (
        <PageTemplate
            withHeadSpace
            variant="medium"
            Header={
                <HeaderWidgetConnector withLogo variant="primary" withProfile>
                    <Header count={favorites.length} />
                </HeaderWidgetConnector>
            }>
            <FavoritePage
                count={favorites.length || 0}
                items={favorites}
                onNavigate={handleNavigate}
                onRemove={handleRemove}
            />
        </PageTemplate>
    );
};
