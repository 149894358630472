import { Button, Stack } from '@mui/material';

import { FeatureToggle } from '@features/feature-toggle';
import { OAuthYandexConnector } from '@widgets/oauth-yandex';
import { TYandexAuthResponse } from '@widgets/oauth-yandex/oauth-yandex';

type TProps = {
    onPrimaryButton: () => void;
    onSecondaryButton: () => void;
    onSuccess: (data: TYandexAuthResponse) => void;
    onError: () => void;
};

const isLoginWithNews = FeatureToggle.hasFeatureFlag('loginWithNews');

export const AuthControls = ({
    onPrimaryButton,
    onSecondaryButton,
    onSuccess,
    onError,
}: TProps) => {
    return isLoginWithNews ? (
        <Stack direction="row" gap="16px">
            <Button
                variant="contained"
                color="buttonPrimary"
                sx={{ width: '320px' }}
                onClick={onPrimaryButton}>
                Вход для таксопарков
            </Button>
            <Button
                variant="contained"
                color="buttonSecondaryEnabled"
                sx={{ width: '320px' }}
                onClick={onSecondaryButton}>
                Вход для водителей
            </Button>
        </Stack>
    ) : (
        <OAuthYandexConnector onSuccess={onSuccess} onError={onError} />
    );
};
