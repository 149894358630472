import { Typography } from '@mui/material';

import { catalogPaths } from '@pages/catalog/paths';
import { ErrorPageTemplate } from '@shared/ui/templates/error-page-template';
import { HeaderWidgetConnector } from '@widgets/header';

import { NotFound } from './not-found';

export const NotFoundConnector = () => {
    return (
        <ErrorPageTemplate
            Header={
                <HeaderWidgetConnector withLogo variant="primary">
                    <Typography variant="h2">cтраница не найдена</Typography>
                </HeaderWidgetConnector>
            }>
            <NotFound navigateTo={catalogPaths.home.absolute} />
        </ErrorPageTemplate>
    );
};
