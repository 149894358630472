import { Avatar, Stack, Typography } from '@mui/material';

type TProps = {
    avatarUrl: string;
    firstName: string;
    middleName: string;
    email: string;
    onAvatarClick: () => void;
};

export const AccountCard = ({
    firstName,
    middleName,
    email,
    avatarUrl,
    onAvatarClick,
}: TProps) => {
    return (
        <Stack
            direction="row"
            gap="10px"
            onClick={onAvatarClick}
            sx={{ cursor: 'pointer' }}>
            <Stack direction="column">
                <Typography variant="subtitle1">
                    {firstName} {middleName}
                </Typography>
                <Typography
                    variant="caption1"
                    color={theme => theme.palette.typography.secondary}>
                    {email}
                </Typography>
            </Stack>
            <Avatar src={avatarUrl} sizes="40px" sx={{ cursor: 'pointer' }} />
        </Stack>
    );
};
