import { useCallback, useMemo } from 'react';

import {
    TFormDelivery,
    TFormDeliveryType,
    TFormState,
    TProductFilters,
} from '@entities/products/types';
import { TFormData } from '@features/form-filters/form-filters';
import { ProductInfo, Provider } from '@shared/api/axios-client';
import { useYandexReachGoal } from '@shared/hooks';

import { CatalogFiltersWidget } from './catalog-filters-widget';
import { filterProducts } from './utils/helpers';
import { mapProviders } from './utils/mappers';

type TProps = {
    products?: ProductInfo[];
    onFilterChange: (filteredProducts?: ProductInfo[]) => void;
};

const formDeliveryDate: TFormDelivery[] = [
    { id: 'any', label: 'Любой' },
    { id: '1', label: '1 день' },
    { id: '3', label: 'До 3 дней' },
    { id: '7', label: 'До 7 дней' },
];

const formDeliveryType: TFormDeliveryType[] = [
    { value: 'any', label: 'любой тип' },
    { value: 'pickup', label: 'самовывоз' },
    { value: 'delivery', label: 'доставка' },
];

const formState: TFormState[] = [
    { value: 'any', label: 'любое' },
    { value: 'new', label: 'новое' },
    { value: 'used', label: 'б/у' },
];

export const CatalogFiltersWidgetConnector = ({
    products,
    onFilterChange,
}: TProps) => {
    const fireProductEvent = useYandexReachGoal();

    const formProviders = useMemo(
        () =>
            mapProviders(
                products?.reduce<Provider[]>(
                    (acc, product) =>
                        acc.find(item => item.id === product.providers.id)
                            ? acc
                            : [...acc, product.providers],
                    [],
                ),
            ), //TODO @OKA (be) partTypeGroup - do not null
        [products],
    );
    const getFilteredProducts = useCallback(
        (filters: TProductFilters) =>
            filterProducts(
                filters,
                products || [],
                formState,
                formDeliveryType,
                formDeliveryDate,
            ),
        [products],
    );
    const handleFilterChange = useCallback(
        (data: TFormData) => {
            const filters: TProductFilters = {
                providers: data.provider?.map(item => item.id),
                delivery: data.deliveryDate?.id,
                minPrice: data.minPrice,
                maxPrice: data.maxPrice,
                state: data.state?.value,
                type: data.deliveryType?.value,
            };
            fireProductEvent('filterProducts', filters);
            const filteredProducts = getFilteredProducts(filters);
            onFilterChange(filteredProducts);
        },
        [fireProductEvent, getFilteredProducts, onFilterChange],
    );

    const priceLimit = useMemo(
        () =>
            products?.reduce(
                (acc, product) => {
                    let newPrice = acc;
                    if (product.warehouse.price < acc.min) {
                        newPrice.min = Math.floor(product.warehouse.price);
                    }
                    if (product.warehouse.price > acc.max) {
                        newPrice.max = Math.ceil(product.warehouse.price);
                    }
                    return newPrice;
                },
                { min: Infinity, max: 0 },
            ),
        [products],
    );
    return (
        <CatalogFiltersWidget
            formDelivery={formDeliveryDate}
            formProviders={formProviders}
            formDeliveryType={formDeliveryType}
            formState={formState}
            onFilterChange={handleFilterChange}
            initValues={{
                deliveryDate: formDeliveryDate[0],
                provider: [],
            }}
            priceLimit={priceLimit || { min: 0, max: 0 }}
        />
    );
};
