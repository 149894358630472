import EastIcon from '@mui/icons-material/East';
import { Box, Button, Stack, Typography } from '@mui/material';

import { TProductTable } from '@entities/products/types';
import { ProductsEmpty } from '@entities/products/ui/products-empty';
import { ProductTable } from '@entities/products/ui/products-table';
import { ProviderErrorAlert } from '@entities/products/ui/provider-error-alert';
import { useStateModal } from '@shared/hooks';
import { Conditional } from '@shared/ui/atoms/conditional';

import { tableHeader } from './utils/data';
type TProps = {
    products: TProductTable[];
    providersError: string[];
    onHelpClick: () => void;
    onSelect: (brand: string) => void;
    onChangeProvider: () => void;
};

export const BrandPage = ({
    products,
    providersError,
    onHelpClick,
    onSelect,
    onChangeProvider,
}: TProps) => {
    const { isOpen, close } = useStateModal(Boolean(providersError.length));

    if (products.length === 0) {
        return (
            <Box paddingTop="60px">
                <ProductsEmpty
                    onHelpClick={onHelpClick}
                    InfoComponent={
                        isOpen ? (
                            <ProviderErrorAlert
                                onClose={close}
                                onChangeProvider={onChangeProvider}
                                providersError={providersError}
                            />
                        ) : null
                    }
                />
            </Box>
        );
    }
    return (
        <Stack direction="column" gap="40px" padding="40px">
            <Stack paddingBottom="40px" gap="30px">
                <Stack
                    direction="row"
                    justifyContent={{ xs: 'center', md: 'space-between' }}
                    flexWrap="wrap"
                    gap="8px">
                    <Stack direction="column" gap="20px">
                        <Stack direction="row" gap="8px" alignItems="center">
                            <Typography variant="h2">
                                Найденые товары
                            </Typography>
                            <Typography
                                variant="h4"
                                marginBottom="20px"
                                color={theme =>
                                    theme.palette.typography.tertiary
                                }>
                                {products.length}
                            </Typography>
                        </Stack>
                        <Typography
                            textAlign={{ xs: 'center', md: 'start' }}
                            variant="body2"
                            color={theme => theme.palette.typography.secondary}>
                            Важно: у нескольких производителей есть разные
                            товары с этим артикулом.
                            <br /> Чтобы не ошибиться, лучше уточнить запрос и
                            выбрать тип детали, который вам нужен
                        </Typography>
                    </Stack>
                    <Box>
                        <Button
                            variant="contained"
                            color="buttonPrimary"
                            onClick={onHelpClick}>
                            помощь в подборе деталей
                        </Button>
                    </Box>
                </Stack>
                <Conditional condition={isOpen}>
                    <ProviderErrorAlert
                        onClose={close}
                        onChangeProvider={onChangeProvider}
                        providersError={providersError}
                    />
                </Conditional>
            </Stack>
            <ProductTable
                tableHeader={tableHeader}
                products={products}
                onItemClick={brand => onSelect(brand)}
                renderControl={({ brand }) => (
                    <Box maxWidth="117px" height="42px" width="100%">
                        <Button
                            fullWidth
                            variant="contained"
                            color="buttonSecondaryEnabled"
                            onClick={() => brand && onSelect(brand)}>
                            <EastIcon />
                        </Button>
                    </Box>
                )}
            />
        </Stack>
    );
};
