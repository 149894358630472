import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback } from 'react';

import { UnknownBusinessError, productApi, queryClient } from '@shared/api';

import { favoriteKeys } from '../query-keys';

type TBusinessError = UnknownBusinessError;

type TUseDeleteFavorite = {
    onSuccess?: () => void;
    onError?: (businessError: TBusinessError) => void;
};

export const useDeleteFavorite = (props?: TUseDeleteFavorite) => {
    const { mutateAsync, ...rest } = useMutation(
        favoriteKeys.addToFavorite.queryKey,
        (productId: string) => {
            return productApi.deleteV1ProductFavorites(
                {
                    productId,
                },
                {
                    headers: {
                        Authorization: 'Bearer',
                    },
                },
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(
                    favoriteKeys.getFavorite.queryKey,
                );
                props?.onSuccess?.();
            },
            onError: ({ response }: AxiosError<TBusinessError>) => {
                if (!props?.onError) {
                    return;
                }
                if (response?.data.code && response.data.message) {
                    props.onError(response.data);
                } else {
                    props.onError({
                        code: 'unknown_code',
                        message: 'что-то пошло не так',
                        response,
                    });
                }
            },
        },
    );

    const deleteFavorite = useCallback(
        (productId: string) => {
            return mutateAsync(productId);
        },
        [mutateAsync],
    );

    return { ...rest, deleteFavorite };
};
