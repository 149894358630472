import { Box, Button, Stack, Typography } from '@mui/material';

type TProps = {
    title: string;
    description: string;
    imgUrl: string;
    onClick: () => void;
};

export const NewsCard = ({ title, description, imgUrl, onClick }: TProps) => {
    return (
        <Stack
            direction="column"
            border={theme => `1px solid ${theme.palette.border.border4}`}
            borderRadius="15px"
            paddingBottom="20px"
            maxWidth="270px"
            width="100%"
            gap="20px">
            <Box
                sx={{
                    borderRadius: '15px 15px 0 0',
                    height: '165px',
                    background: `url('${imgUrl}')`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
            />
            <Stack gap="30px">
                <Stack gap="5px" padding="0px 15px">
                    <Typography variant="subtitle1">{title}</Typography>
                    <Typography variant="body3">{description}</Typography>
                </Stack>
                <Box display="flex" justifyContent="flex-end" paddingX="10px">
                    <Button
                        variant="contained"
                        color="buttonSecondaryEnabled"
                        onClick={onClick}>
                        подробнее
                    </Button>
                </Box>
            </Stack>
        </Stack>
    );
};
