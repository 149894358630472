import { Box, Button, Stack, Typography, styled } from '@mui/material';

type TProps = {
    clientId: string;
    onClick: () => void;
};

const IDTypography = styled(Typography)`
    position: absolute;
    -webkit-text-stroke: 1px ${({ theme }) => theme.palette.typography.primary};
    color: transparent;
    right: -10%;
    bottom: 0;
    opacity: 0.2;
    font-size: 258px;
    font-weight: 900;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
`;

const Wrapper = styled(Stack)`
    width: 100%;
    min-height: 247px;
    padding: 20px;
    border-radius: 20px;
    background: ${({ theme }) => theme.palette.surface.primary};
    justify-content: space-between;
    position: relative;
    overflow: hidden;
`;

export const ClientIdCard = ({ clientId, onClick }: TProps) => {
    return (
        <Wrapper direction="column">
            <IDTypography>ID</IDTypography>
            <Stack>
                <Typography
                    variant="body2"
                    color={theme => theme.palette.typography.secondary}>
                    clientID
                </Typography>
                <Typography variant="h3">{clientId}</Typography>
            </Stack>
            <Box>
                <Button
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                    variant="contained"
                    color="buttonPrimary"
                    onClick={onClick}>
                    изменить clientID
                </Button>
            </Box>
        </Wrapper>
    );
};
