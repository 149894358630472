import { RouteObject } from 'react-router-dom';

import { MetaTitle } from '@shared/ui/atoms/meta-title';

import { BrandPageConnector } from './brand';
import { CatalogPageConnector } from './catalog';
import { catalogPaths } from './paths';
import { SearchPageConnector } from './search';

export const routes: RouteObject[] = [
    {
        path: catalogPaths.home.absolute,
        element: (
            <>
                <MetaTitle>Поиск</MetaTitle>
                <SearchPageConnector />
            </>
        ),
    },
    {
        path: catalogPaths.catalog.absolute,
        element: (
            <>
                <MetaTitle>Каталог</MetaTitle>
                <CatalogPageConnector />
            </>
        ),
    },
    {
        path: catalogPaths.brand.absolute,
        element: (
            <>
                <MetaTitle>Бренды</MetaTitle>
                <BrandPageConnector />
            </>
        ),
    },
];
