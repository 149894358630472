import { createQueryKeys } from '@lukemorales/query-key-factory';

export const cartKeys = createQueryKeys('cart', {
    addToCart: ['add-to-cart'],
    getCart: ['get-cart'],
    deleteCartItem: ['delete-cart-item'],
    deleteCart: ['delete-cart'],
    deleteCartGroup: ['delete-cart-group'],
    exportCart: ['export-cart'],
});
