import { Button, Input, Stack, styled } from '@mui/material';
import { useCallback } from 'react';
import { RefCallBack } from 'react-hook-form';

export type TCounterVariant = 'standard' | 'rounded';

type TProps = {
    fieldName?: string;
    value: number;
    variant?: TCounterVariant;
    isDisabled?: boolean;
    maxValue?: number;
    minValue?: number;
    inputRef?: RefCallBack;
    onChange: (value: number) => void;
    onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    onAdd: () => void;
    onSubtract: () => void;
};

const StyledButton = styled(Button)`
    min-width: auto;
    padding: 8px;
    width: 32px;
    height: 100%;
    color: ${({ theme }) => theme.palette.icon.primaryInvert};
    &:hover {
        color: ${({ theme }) => theme.palette.icon.secondaryInvert};
    }
    &:active {
        background: ${({ theme }) => theme.palette.icon.tertiaryInvert};
    }
    &:disabled {
        color: ${({ theme }) => theme.palette.icon.tertiaryInvert};
    }
`;

const variantStyleMap = {
    rounded: {
        borderRadius: '50px',
    },
    standard: {
        borderRadius: '5px',
    },
};

export const Counter = ({
    fieldName,
    value,
    variant = 'standard',
    isDisabled,
    maxValue = Infinity,
    minValue = 0,
    inputRef,
    onChange,
    onBlur,
    onAdd,
    onSubtract,
}: TProps) => {
    const isAddDisabled = Boolean(value >= maxValue);
    const isRemoveDisabled = Boolean(value <= minValue);
    const borderRadius = variantStyleMap[variant].borderRadius;
    const addBorderStyle = `0 ${borderRadius} ${borderRadius} 0`;
    const removeBorderStyle = `${borderRadius} 0 0 ${borderRadius}`;

    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const newValue = Number(e.currentTarget!.value);
            if (
                !isNaN(newValue) &&
                newValue >= minValue &&
                newValue <= maxValue
            ) {
                onChange(newValue);
            }
        },
        [maxValue, minValue, onChange],
    );

    return (
        <Stack
            direction="row"
            alignItems="center"
            height="40px"
            borderRadius={variantStyleMap[variant].borderRadius}
            justifyContent="space-between"
            sx={theme => ({ background: theme.palette.surface.primaryInvert })}>
            <StyledButton
                disabled={isDisabled || isRemoveDisabled}
                variant="text"
                onClick={onSubtract}
                sx={{ borderRadius: `${removeBorderStyle}  !important` }}>
                -
            </StyledButton>

            <Stack alignItems="center" width="46px">
                <Input
                    ref={inputRef}
                    disableInjectingGlobalStyles
                    name={fieldName}
                    value={value}
                    type="string"
                    sx={theme => ({
                        color: isDisabled
                            ? theme.palette.typography.tertiaryInvert
                            : theme.palette.typography.primaryInvert,
                        typography: theme.typography.caption2,
                        minHeight: 'auto',
                        paddingBottom: 0,
                        width: '100%',
                        borderBottomColor: 'transparent !important',
                    })}
                    inputProps={{
                        style: { textAlign: 'center' },
                    }}
                    onChange={handleChange}
                    onBlur={onBlur}
                />
            </Stack>
            <StyledButton
                variant="text"
                onClick={onAdd}
                disabled={isDisabled || isAddDisabled}
                sx={{ borderRadius: `${addBorderStyle} !important` }}>
                +
            </StyledButton>
        </Stack>
    );
};
