import { RouteObject } from 'react-router-dom';

import { MetaTitle } from '@shared/ui/atoms/meta-title';

import { ClientIdPageConnector } from './client-id-page-connector';
import { clientIdPaths } from './paths';

export const routes: RouteObject[] = [
    {
        path: clientIdPaths.home.absolute,
        element: (
            <>
                <MetaTitle>Изменение сlient id</MetaTitle>
                <ClientIdPageConnector />
            </>
        ),
    },
];
