import { useTheme } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import {
    useCartAmountStore,
    useChangeCartAmount,
    useGetCart,
} from '@entities/cart';
import {
    useAddToFavorite,
    useDeleteFavorite,
    useGetFavorite,
} from '@entities/favorite/model';
import {
    TSortableField,
    ecommerceProductMap,
    useGetProducts,
} from '@entities/products';
import { useSearchHistoryStore } from '@entities/user/model/user-domain';
import { FormSearchHeaderProvider } from '@features/form-search-header';
import { accountPaths } from '@pages/account/paths';
import { ProductInfo } from '@shared/api/axios-client';
import { ENV } from '@shared/config/constants';
import {
    useNavigateExternal,
    useQueryParams,
    useYandexEcommerce,
    useYandexReachGoal,
} from '@shared/hooks';
import { CatalogPageTemplate } from '@shared/ui/templates/catalog-page-template';
import { CatalogFiltersWidgetConnector } from '@widgets/catalog-filters';
import { HeaderWidgetConnector } from '@widgets/header';

import { CatalogPage, TCartValue } from './catalog-page';
import { CatalogPageSkeleton } from './ui/catalog-page-skeleton';
import { productSort } from './utils/helpers';
import { mapCart, productMap } from './utils/mappers';
import { mapFavorite } from './utils/mappers/favorite-mapper';
import { catalogPaths } from '../paths';

export type TFavoriteItem = {
    productId: string;
    brand: string;
    vendorCode: string;
};

export const CatalogPageConnector = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const navigateExt = useNavigateExternal();
    const cartAmountStore = useCartAmountStore();
    const fireProductEvent = useYandexReachGoal();
    const { params, setParams } = useQueryParams();
    const onChangeCartAmount = useChangeCartAmount();
    const { impressionAction } = useYandexEcommerce();
    const searchHistoryStore = useSearchHistoryStore();
    const search = params.get('search');

    const [sortableFiled, setSortableFiled] = useState<TSortableField>({
        attribute: 'price',
        sort: 'DESC',
    });

    const getErrorMessage = useCallback(
        () =>
            enqueueSnackbar('что-то пошло не так', {
                variant: 'error',
                description: 'обновите страницу или попробуйте позже',
            }),
        [enqueueSnackbar],
    );
    const onUnknownPostError = useCallback(
        () =>
            enqueueSnackbar('что-то пошло не так', {
                variant: 'error',
                description: 'попробуйте повторить запрос',
            }),
        [enqueueSnackbar],
    );

    const brand = params.get('brand') || undefined;
    const { data, isRefetching, isLoading } = useGetProducts({
        body: {
            vendorCode: search || '',
            brand: brand,
        },
        onError: getErrorMessage,
    });

    const { data: favoriteData } = useGetFavorite({
        onError: getErrorMessage,
    });

    const { addToFavorite } = useAddToFavorite({
        onSuccess: () =>
            enqueueSnackbar('Добавлен в избранное', {
                variant: 'info',
                closable: true,
                withIcon: false,
            }),
        onError: onUnknownPostError,
    });

    const { deleteFavorite } = useDeleteFavorite({
        onSuccess: () =>
            enqueueSnackbar('Удален из избранного', {
                variant: 'info',
                closable: true,
                withIcon: false,
            }),
        onError: onUnknownPostError,
    });

    const { data: cartData } = useGetCart({
        onSuccess: data => cartAmountStore.set(data.data.totalQuantity),
        onError: getErrorMessage,
    });

    const productsData = data?.data.partTypeGroup[0]?.products;
    const [filteredProducts, setFilteredProducts] = useState<
        ProductInfo[] | undefined
    >(undefined);

    useEffect(() => {
        data?.data.providersError?.find(error => error.errorCode === '500') &&
            getErrorMessage();
    }, [data?.data.providersError, enqueueSnackbar, getErrorMessage]);

    useEffect(() => {
        setFilteredProducts(productsData);
    }, [productsData]);

    useEffect(() => {
        if (filteredProducts) {
            impressionAction(ecommerceProductMap(filteredProducts));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredProducts]);

    const handelChangeQuantity = useCallback(
        (cart: TCartValue) => {
            onChangeCartAmount(cart, cartData?.data.cart);
        },
        [cartData?.data.cart, onChangeCartAmount],
    );

    const handleAddFavorite = useCallback(
        (productId: string) => {
            addToFavorite({ productId });
        },
        [addToFavorite],
    );

    const handleSupportClick = useCallback(() => {
        navigateExt(ENV.yandexSupportLink);
    }, [navigateExt]);

    const handleHeaderSearch = useCallback(
        (search: string) => {
            searchHistoryStore.add(search);
            setParams({ search: search });
        },
        [searchHistoryStore, setParams],
    );

    const handleChangeSort = useCallback(
        (attribute: string, sort: string) => {
            if (attribute === 'price') {
                fireProductEvent('sortByPrice', { sort });
            }
            if (attribute === 'deliveryDate') {
                fireProductEvent('sortByDeliveryDate', { sort });
            }
            if (attribute === 'name') {
                fireProductEvent('sortByName', { sort });
            }
            setSortableFiled({ attribute, sort });
        },
        [fireProductEvent],
    );

    const handleChangeProviderClick = useCallback(() => {
        navigate(accountPaths.providers.absolute);
    }, [navigate]);

    const favorites = useMemo(
        () => mapFavorite(favoriteData?.data.productsFavorites),
        [favoriteData?.data.productsFavorites],
    );

    const favoriteItem = favorites.find(
        favorite =>
            favorite.brand === productsData?.[0].product.brand &&
            favorite.vendorCode === productsData?.[0].product.vendorCode,
    );

    const handleRemoveFavorite = useCallback(() => {
        deleteFavorite(favoriteItem?.productId || '');
    }, [deleteFavorite, favoriteItem]);

    const products = useMemo(
        () =>
            productMap(
                productSort(
                    filteredProducts || [],
                    sortableFiled.attribute,
                    sortableFiled.sort,
                ),
            ),
        [filteredProducts, sortableFiled],
    );

    const providersError = useMemo(
        () =>
            data?.data.providersError
                ?.filter(error => error.errorCode === '401')
                .map(error => error.providerName) || [],
        [data?.data.providersError],
    );

    const cartValue = useMemo(
        () => mapCart(cartData?.data.cart),
        [cartData?.data.cart],
    );

    if (isLoading || isRefetching) {
        return <CatalogPageSkeleton />;
    }

    if (data?.data.partTypeGroup && data.data.partTypeGroup.length > 1) {
        return (
            <Navigate
                to={`${catalogPaths.brand.absolute}?search=${search}`}
                replace
            />
        );
    }

    return (
        <CatalogPageTemplate
            color="secondary"
            Header={
                <HeaderWidgetConnector
                    withLogo
                    variant="primary"
                    withProfile
                    backgroundColor={theme.palette.surface.secondary}>
                    <FormSearchHeaderProvider
                        options={searchHistoryStore.searchHistory}
                        initSearch={search || ''}
                        onSubmit={data => handleHeaderSearch(data.search)}
                    />
                </HeaderWidgetConnector>
            }>
            <CatalogPage
                article={search || ''}
                products={products}
                FilterWidgetComponent={
                    <CatalogFiltersWidgetConnector
                        products={productsData}
                        onFilterChange={setFilteredProducts}
                    />
                }
                isLoading={isLoading || isRefetching}
                isProducts={Boolean(productsData?.length)}
                sort={sortableFiled}
                onSortChange={handleChangeSort}
                onQuantityChange={handelChangeQuantity}
                onChangeProvider={handleChangeProviderClick}
                cartValue={cartValue}
                onHelpClick={handleSupportClick}
                providersError={providersError}
                favoriteHandlers={{
                    onAddFavorite: handleAddFavorite,
                    onRemoveFavorite: handleRemoveFavorite,
                }}
                isFavorite={Boolean(favoriteItem)}
            />
        </CatalogPageTemplate>
    );
};
