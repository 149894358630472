import { Box, Grid, Stack } from '@mui/material';
import { ReactNode } from 'react';

type TColor = 'primary' | 'secondary';

type TProps = {
    children?: ReactNode;
    Header?: ReactNode;
    color?: TColor;
};

export const CatalogPageTemplate = ({
    children,
    color = 'primary',
    Header,
}: TProps) => {
    return (
        <Stack direction="column" minHeight="100dvh" width="100vw">
            <Box
                width="100%"
                sx={theme => ({
                    background:
                        color === 'primary'
                            ? theme.palette.common.white
                            : theme.palette.surface.secondary,
                })}
                padding="20px 40px 40px 40px">
                {Header}
            </Box>
            <Grid direction="row" container width="100%" flex={1}>
                <Grid item xs={12} flex={1}>
                    {children}
                </Grid>
            </Grid>
        </Stack>
    );
};
