import { useCallback, useState } from 'react';

import { TCartValue } from '@pages/catalog/catalog/catalog-page';
import { useYandexEcommerce } from '@shared/hooks';

export const useProductEcommerce = () => {
    const { addAction, removeAction, purchaseAction } = useYandexEcommerce();
    const [lastCartItem, setLastCartItem] = useState<TCartValue>();
    const changeCartActions = useCallback(
        (currentCartItem: TCartValue) => {
            const isSame =
                lastCartItem?.productId === currentCartItem.productId &&
                lastCartItem.providerId === currentCartItem.providerId &&
                lastCartItem.warehouseId === currentCartItem.warehouseId;
            if (!isSame && lastCartItem) {
                const item = {
                    id: lastCartItem.productId,
                    name: lastCartItem.productId,
                    quantity: lastCartItem.quantity,
                };
                item.quantity !== 0 ? addAction(item) : removeAction(item);
                setLastCartItem(currentCartItem);
            }
            setLastCartItem(currentCartItem);
        },
        [addAction, lastCartItem, removeAction],
    );

    return { changeCartActions, purchaseAction };
};
