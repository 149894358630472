import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { Button, Grid, Stack } from '@mui/material';

import { TProvider } from '@entities/providers/types';
import { ProviderInfoCard } from '@entities/providers/ui/provider-info-card';
import { ProviderInstantOrderInfo } from '@entities/providers/ui/provider-instant-order-info';
import { TFormData } from '@features/form-provider-change/form-provider-change';
import { FormProviderChangeProvider } from '@features/form-provider-change/form-provider-change-provider';
import { Conditional } from '@shared/ui/atoms/conditional';

type TProps = {
    providers: TProvider[];
    changeableProvider?: TProvider;
    defaultValues?: TFormData;
    instantOrderAvailable?: boolean;
    onSubmitChange: (data: TFormData) => void;
    onAddProvider: () => void;
    onRemoveProvider: () => void;
    onChange: (provider: TProvider) => void;
    onBack: () => void;
};

export const ChangeProvidersPage = ({
    providers,
    changeableProvider,
    defaultValues,
    instantOrderAvailable,
    onSubmitChange,
    onAddProvider,
    onRemoveProvider,
    onChange,
    onBack,
}: TProps) => {
    const credFields = providers.find(
        provider => provider.id === changeableProvider?.id,
    )?.credentialFields;
    return (
        <Grid container direction="row" gap={{ xs: '16px', md: 0 }}>
            <Grid item xs={0} md={2} />
            <Grid item xs={12} md={6}>
                <Stack
                    direction="column"
                    gap="20px"
                    padding={{ xs: '8px', md: 0 }}>
                    {changeableProvider && credFields ? (
                        <Stack direction="column" gap="40px">
                            <Conditional condition={instantOrderAvailable}>
                                <ProviderInstantOrderInfo />
                            </Conditional>
                            <FormProviderChangeProvider
                                onSubmit={onSubmitChange}
                                defaultValues={defaultValues}
                                fields={credFields}
                                onBack={onBack}
                            />
                        </Stack>
                    ) : (
                        providers.map(item => (
                            <ProviderInfoCard
                                key={item.id}
                                title={item.title}
                                isDirty
                                isError={item.isCredentialCorrect === false}
                                onClick={() => onChange(item)}
                                withIcon={false}
                            />
                        ))
                    )}
                </Stack>
            </Grid>
            <Grid item xs={12} md={2} />
            <Grid item xs={12} md={2} padding={{ xs: '8px', md: 0 }}>
                <Button
                    sx={{ width: { xs: '100%', md: 'auto' } }}
                    variant="contained"
                    color={
                        changeableProvider
                            ? 'buttonSecondaryEnabled'
                            : 'buttonPrimary'
                    }
                    onClick={
                        changeableProvider ? onRemoveProvider : onAddProvider
                    }
                    startIcon={
                        changeableProvider ? (
                            <ClearIcon fontSize="large" />
                        ) : (
                            <AddIcon fontSize="large" />
                        )
                    }>
                    {changeableProvider
                        ? 'удалить поставщика'
                        : 'добавить поставщиков'}
                </Button>
            </Grid>
        </Grid>
    );
};
