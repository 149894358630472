import { Grid, Stack } from '@mui/material';
import { ReactNode } from 'react';

import { TProductTableField } from '@entities/products/types';
import { ProductItemField } from '@entities/products/ui/product-item-field';

type TProps = {
    fields: TProductTableField[];
    Control?: ReactNode;
    onClick?: () => void;
};

export const ProductItem = ({ fields, Control, onClick }: TProps) => {
    return (
        <Grid
            container
            width="100%"
            minWidth="1300px"
            direction="row"
            alignItems="center"
            borderBottom={theme => `1px solid ${theme.palette.border.border4}`}
            onClick={onClick}
            sx={{
                cursor: onClick ? 'pointer' : 'default',
            }}>
            {fields.map((field, index) => (
                <ProductItemField {...field} key={index} />
            ))}
            <Grid item xs={1}>
                <Stack alignItems="flex-end">{Control}</Stack>
            </Grid>
        </Grid>
    );
};
