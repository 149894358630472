import { Outlet, RouteObject } from 'react-router-dom';

import { FeatureToggleGuard, Public } from '@pages/guards';
import { MetaTitle } from '@shared/ui/atoms/meta-title';

import { CompanyAuthPageConnector } from './company-auth';
import { DriverAuthPageConnector } from './driver-auth';
import { AuthPageConnector } from './home';
import { authPaths } from './paths';

export const routes: RouteObject[] = [
    {
        element: (
            <Public>
                <>
                    <MetaTitle>Авторизация</MetaTitle>
                    <Outlet />
                </>
            </Public>
        ),
        children: [
            {
                path: authPaths.home.absolute,
                element: <AuthPageConnector />,
            },
            {
                path: authPaths.company.absolute,
                element: (
                    <FeatureToggleGuard featureFlag="loginWithNews">
                        <CompanyAuthPageConnector />
                    </FeatureToggleGuard>
                ),
            },
            {
                path: authPaths.driver.absolute,
                element: (
                    <FeatureToggleGuard featureFlag="loginWithNews">
                        <DriverAuthPageConnector />
                    </FeatureToggleGuard>
                ),
            },
        ],
    },
];
