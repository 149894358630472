import { Box, Modal } from '@mui/material';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { NewsCardDetails } from '@entities/news/ui/news-card-details';
import { mapNews } from '@entities/news/utils/mappers';
import { useNavigateExternal } from '@shared/hooks';
import { mockNewsesData } from '@shared/mock';
import { PageTemplate } from '@shared/ui/templates/page-template';

import { DriverAuthPage } from './driver-auth-page';
import { Header } from './ui/header';
import { authPaths } from '../paths';

type TActiveBanner = {
    sectionId: string;
    bannerId: string;
};

export const DriverAuthPageConnector = () => {
    const navigateExt = useNavigateExternal();
    const navigate = useNavigate();
    const [modalBanner, setModalBanner] = useState<TActiveBanner>();
    const getMockNews = () => mockNewsesData;
    const newses = mapNews(getMockNews());
    const modalBannerInfo = newses
        .find(news => news.id === modalBanner?.sectionId)
        ?.banners.find(banner => banner.id);

    const handleCloseModal = useCallback(() => {
        setModalBanner(undefined);
    }, []);
    const handleBackClick = useCallback(() => {
        navigate(authPaths.home.absolute);
    }, [navigate]);
    return (
        <PageTemplate variant="medium" withHeadSpace Header={<Header />}>
            <DriverAuthPage
                onBannerClick={(sectionId, bannerId) =>
                    setModalBanner({ sectionId, bannerId })
                }
                newses={newses}
                onActionClick={handleBackClick}
            />
            <Modal open={Boolean(modalBanner)} onClose={handleCloseModal}>
                <Box
                    position="absolute"
                    top="50%"
                    left="50%"
                    sx={{ transform: 'translate(-50%, -50%)' }}>
                    <NewsCardDetails
                        title={modalBannerInfo?.title || ''}
                        imgUrl={modalBannerInfo?.pictureId || ''}
                        text={modalBannerInfo?.fullText || ''}
                        onClick={() => navigateExt(modalBannerInfo?.url || '')}
                        onClose={handleCloseModal}
                    />
                </Box>
            </Modal>
        </PageTemplate>
    );
};
