import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Button, Stack, TextField, useTheme } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { Conditional } from '@shared/ui/atoms/conditional';

export type TFormData = {
    name: string;
    site: string;
    phone: string;
    email: string;
};

export const FormProviderProposition = () => {
    const { formState, control } = useFormContext<TFormData>();
    const theme = useTheme();
    return (
        <Stack>
            <Controller
                name="name"
                control={control}
                render={({ field }) => (
                    <TextField
                        label="название поставщика"
                        InputProps={{
                            fullWidth: true,
                            endAdornment: (
                                <Conditional
                                    condition={Boolean(formState.errors.name)}>
                                    <ErrorOutlineIcon fontSize="large" />
                                </Conditional>
                            ),
                        }}
                        InputLabelProps={{
                            style: {
                                color: formState.errors.name
                                    ? theme.palette.typography.negative
                                    : theme.palette.typography.secondary,
                            },
                        }}
                        error={Boolean(formState.errors.name)}
                        helperText={
                            formState.errors.name?.message ||
                            'Обязательное поле'
                        }
                        variant="standard"
                        placeholder="название поставщика"
                        {...field}
                    />
                )}
            />
            <Controller
                name="site"
                control={control}
                render={({ field }) => (
                    <TextField
                        label="сайт"
                        InputProps={{
                            fullWidth: true,
                            endAdornment: (
                                <Conditional
                                    condition={Boolean(formState.errors.site)}>
                                    <ErrorOutlineIcon fontSize="large" />
                                </Conditional>
                            ),
                        }}
                        error={Boolean(formState.errors.site)}
                        helperText={formState.errors.site?.message || ' '}
                        variant="standard"
                        placeholder="сайт"
                        {...field}
                    />
                )}
            />
            <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                    <TextField
                        label="телефон"
                        InputProps={{
                            fullWidth: true,
                            endAdornment: (
                                <Conditional
                                    condition={Boolean(formState.errors.phone)}>
                                    <ErrorOutlineIcon fontSize="large" />
                                </Conditional>
                            ),
                        }}
                        error={Boolean(formState.errors.phone)}
                        helperText={formState.errors.phone?.message || ' '}
                        variant="standard"
                        placeholder="телефон"
                        {...field}
                    />
                )}
            />
            <Controller
                name="email"
                control={control}
                render={({ field }) => (
                    <TextField
                        label="e-mail"
                        InputProps={{
                            fullWidth: true,
                            endAdornment: (
                                <Conditional
                                    condition={Boolean(formState.errors.email)}>
                                    <ErrorOutlineIcon fontSize="large" />
                                </Conditional>
                            ),
                        }}
                        error={Boolean(formState.errors.email)}
                        helperText={formState.errors.email?.message || ' '}
                        variant="standard"
                        placeholder="e-mail"
                        {...field}
                    />
                )}
            />
            <Box marginTop="40px">
                <Button
                    variant="contained"
                    color="buttonPrimary"
                    type="submit"
                    sx={{ width: { xs: '100%', md: 'auto' } }}>
                    предложить
                </Button>
            </Box>
        </Stack>
    );
};
