import CloseIcon from '@mui/icons-material/Close';
import {
    Avatar,
    Button,
    Drawer,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';

import { TProfileInfo } from '@entities/user/types';

import { SideMenuItem } from '../side-menu-item';

type TProps = {
    profileInfo: TProfileInfo;
    menusInfo: TMenuInfo[];
    isSideMenuOpen: boolean;
    onCloseSideMenu: () => void;
    onLogout: () => void;
};

export type TMenuInfo = {
    label: string;
    path: string;
};

export const SideMenu = ({
    profileInfo,
    menusInfo,
    isSideMenuOpen,
    onCloseSideMenu,
    onLogout,
}: TProps) => {
    return (
        <Drawer anchor="right" open={isSideMenuOpen} onClose={onCloseSideMenu}>
            <Stack
                height="100dvh"
                width={{ xs: '100vw', md: '650px' }}
                overflow="hidden"
                sx={theme => ({
                    background: theme.palette.bg.primaryInvert,
                })}>
                <Stack
                    direction="column"
                    padding={{ xs: '8px', md: '20px 40px 40px 40px' }}
                    justifyContent="space-between"
                    overflow="auto"
                    height="100%">
                    <Stack height={{ xs: 'auto', md: '100%' }}>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography
                                variant="h2"
                                color={theme =>
                                    theme.palette.typography.primaryInvert
                                }
                                visibility={{
                                    xs: 'hidden',
                                    md: 'visible',
                                }}>
                                Профиль
                            </Typography>
                            <IconButton
                                color="buttonPrimaryInvert"
                                onClick={onCloseSideMenu}>
                                <CloseIcon sx={{ fontSize: '48px' }} />
                            </IconButton>
                        </Stack>
                        <Stack
                            direction={{ xs: 'column-reverse', md: 'row' }}
                            alignItems={{ xs: 'center', md: 'start' }}
                            marginTop={{ xs: '5%', md: '200px' }}
                            gap={{ xs: '8px', md: '58px' }}>
                            <Stack
                                direction="column"
                                gap="10px"
                                maxWidth="70%"
                                textAlign={{ xs: 'center', md: 'start' }}>
                                <Typography
                                    width="100%"
                                    variant="h2"
                                    color={theme =>
                                        theme.palette.typography.primaryInvert
                                    }
                                    overflow="hidden"
                                    textOverflow="ellipsis">
                                    {profileInfo.firstName}{' '}
                                    {profileInfo.middleName}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color={theme =>
                                        theme.palette.typography.secondaryInvert
                                    }
                                    overflow="hidden"
                                    textOverflow="ellipsis">
                                    {profileInfo.email}
                                </Typography>
                            </Stack>
                            <Avatar
                                sx={theme => ({
                                    width: '96px',
                                    height: '96px',
                                    border: `1px solid ${theme.palette.icon.secondary}`,
                                })}
                                src={profileInfo.avatarUrl}
                            />
                        </Stack>
                        <Stack
                            gap={{ xs: '8px', md: '40px' }}
                            flex={1}
                            justifyContent="space-between"
                            paddingBottom="8px">
                            <Stack
                                direction="column"
                                marginTop={{ xs: '10%', md: '80px' }}
                                gap={{ xs: '8px', md: '40px' }}
                                alignItems={{ xs: 'center', md: 'start' }}>
                                {menusInfo.map((item, index) => (
                                    <SideMenuItem key={index} {...item} />
                                ))}
                            </Stack>
                            <Stack alignItems={{ xs: 'center', md: 'start' }}>
                                <Button
                                    variant="text"
                                    size="small"
                                    color="buttonSecondaryText"
                                    onClick={onLogout}>
                                    <Typography variant="h3" color="inherit">
                                        Выход
                                    </Typography>
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Drawer>
    );
};
