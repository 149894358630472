import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { ReactNode, useCallback } from 'react';

import { TProductTable, TSortableField } from '@entities/products/types';
import { ProductsEmpty } from '@entities/products/ui/products-empty';
import { ProductTable } from '@entities/products/ui/products-table';
import { ProviderErrorAlert } from '@entities/products/ui/provider-error-alert';
import { FeatureToggle } from '@features/feature-toggle';
import { useStateModal } from '@shared/hooks';
import { Conditional } from '@shared/ui/atoms/conditional';

import { EmptyTableProducts } from './ui/empty-table-products';
import { ProductControllers } from './ui/product-controllers';
import { catalogTableHeaders } from './utils/data/catalog-table-headers';

export type TCartValue = {
    position?: number;
    brand?: string;
    productId: string;
    providerId: string;
    warehouseId: string;
    quantity: number;
    stock?: number;
};

type TProps = {
    article: string;
    products: TProductTable[];
    cartValue: TCartValue[];
    isLoading: boolean;
    sort: TSortableField;
    isProducts: boolean;
    providersError: string[];
    FilterWidgetComponent: ReactNode;
    favoriteHandlers: {
        onAddFavorite: (productId: string) => void;
        onRemoveFavorite: () => void;
    };
    isFavorite?: boolean;
    onHelpClick: () => void;
    onChangeProvider: () => void;
    onSortChange: (attribute: string, sort: string) => void;
    onQuantityChange: (value: TCartValue) => void;
};

const isFavoriteEnabled = FeatureToggle.hasFeatureFlag('favorite');

export const CatalogPage = ({
    article,
    products,
    cartValue,
    isLoading,
    sort,
    isProducts,
    providersError,
    FilterWidgetComponent,
    favoriteHandlers,
    isFavorite,
    onHelpClick,
    onChangeProvider,
    onSortChange,
    onQuantityChange,
}: TProps) => {
    const { isOpen, close } = useStateModal(Boolean(providersError.length));

    const favoriteVariant = {
        active: {
            handler: favoriteHandlers.onRemoveFavorite,
            icon: <FavoriteIcon />,
            title: 'В избранном',
        },
        nonActive: {
            handler: () => favoriteHandlers.onAddFavorite(products[0].id),
            icon: <FavoriteBorderIcon />,
            title: 'Добавить в избранное',
        },
    };
    const favorite = favoriteVariant[isFavorite ? 'active' : 'nonActive'];
    const handleHeaderClick = useCallback(
        (attribute: string) => {
            if (sort.attribute !== attribute) {
                return onSortChange(attribute, 'ASC');
            }
            if (sort.sort === 'ASC') {
                return onSortChange(attribute, 'DESC');
            }
            return onSortChange(attribute, 'ASC');
        },
        [onSortChange, sort],
    );
    const handleChange = (
        newQuantity: number,
        position: number,
        productId?: string,
        providerId?: string,
        warehouseId?: string,
        brand?: string,
    ) => {
        productId &&
            providerId &&
            warehouseId &&
            onQuantityChange({
                productId,
                providerId,
                warehouseId,
                quantity: newQuantity,
                position: position,
                brand: brand,
            });
    };
    if (!isProducts && !isLoading) {
        return (
            <Box padding="60px 40px 40px 40px">
                <ProductsEmpty
                    onHelpClick={onHelpClick}
                    InfoComponent={
                        isOpen ? (
                            <ProviderErrorAlert
                                onClose={close}
                                onChangeProvider={onChangeProvider}
                                providersError={providersError}
                            />
                        ) : null
                    }
                />
            </Box>
        );
    }
    return (
        <Stack direction="column">
            <Stack
                direction="column"
                padding={{ xs: '8px', md: '60px 40px 0 40px' }}
                gap="60px"
                sx={theme => ({
                    background: theme.palette.surface.secondary,
                })}>
                <Stack
                    direction="column"
                    sx={theme => ({
                        borderBottom: `solid 1px ${theme.palette.border.border5}`,
                    })}
                    paddingBottom="30px"
                    gap="30px">
                    <Stack direction="column" gap="10px">
                        <Stack
                            direction="row"
                            justifyContent={{
                                xs: 'center',
                                md: 'space-between',
                            }}
                            flexWrap="wrap"
                            gap="8px">
                            <Typography variant="h2">арт. {article}</Typography>
                            <Box>
                                <Button
                                    variant="contained"
                                    color="buttonPrimary"
                                    onClick={onHelpClick}>
                                    помощь в подборе деталей
                                </Button>
                            </Box>
                        </Stack>
                        <Conditional condition={isFavoriteEnabled}>
                            <Box>
                                <Button
                                    variant="text"
                                    onClick={favorite.handler}
                                    startIcon={favorite.icon}
                                    color="buttonPrimary"
                                    size="small">
                                    {favorite.title}
                                </Button>
                            </Box>
                        </Conditional>
                    </Stack>

                    <Box minHeight="60px">
                        <Conditional condition={isOpen}>
                            <ProviderErrorAlert
                                onClose={close}
                                onChangeProvider={onChangeProvider}
                                providersError={providersError}
                            />
                        </Conditional>
                    </Box>
                </Stack>
            </Stack>
            <Grid
                container
                direction="row"
                paddingTop="10px"
                paddingBottom="40px"
                sx={theme => ({
                    background: theme.palette.surface.secondary,
                })}>
                <Grid item xs={12} md={2}>
                    <Stack
                        direction="column"
                        gap="14px"
                        paddingLeft="40px"
                        paddingTop="65px"
                        height="100%"
                        justifyContent="center">
                        <Typography variant="caption1">
                            Найдено предложений
                        </Typography>
                        <Typography variant="h2">{products.length}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12} md={10}>
                    {FilterWidgetComponent}
                </Grid>
            </Grid>
            <Box padding="40px">
                <ProductTable
                    tableHeader={catalogTableHeaders}
                    sortableField={sort}
                    products={products}
                    EmptyComponent={<EmptyTableProducts />}
                    onHeaderClick={handleHeaderClick}
                    renderControl={({
                        position,
                        providerId,
                        productId,
                        warehouseId,
                        brand,
                    }) => {
                        const count = cartValue.find(
                            item =>
                                item.productId === productId &&
                                item.warehouseId === warehouseId,
                        );
                        const isAllData =
                            providerId && productId && warehouseId;
                        return isAllData ? (
                            <ProductControllers
                                cartQuantity={count?.quantity}
                                cartStock={count?.stock}
                                onCounter={newCount =>
                                    handleChange(
                                        newCount,
                                        position,
                                        productId,
                                        providerId,
                                        warehouseId,
                                        brand,
                                    )
                                }
                            />
                        ) : null;
                    }}
                />
            </Box>
        </Stack>
    );
};
