import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLogin } from '@entities/auth/model/hooks/use-login';
import { useYandexReachGoal } from '@shared/hooks';
import {
    useLoginStore,
    useSessionStore,
    useUserInfoStore,
} from '@shared/session';
import { TYandexAuthResponse } from '@widgets/oauth-yandex/oauth-yandex';

import { AuthPage } from './auth-page';
import { AuthControls } from './ui/auth-controls';
import { authPaths } from '../paths';

export const AuthPageConnector = () => {
    const { enqueueSnackbar } = useSnackbar();
    const fireProductEvent = useYandexReachGoal();
    const navigate = useNavigate();
    const sessionStore = useSessionStore();
    const userInfoStore = useUserInfoStore();
    const loginStore = useLoginStore();
    const { login } = useLogin({
        onSuccess: ({ data }) => {
            userInfoStore.update(data);
            sessionStore.set(data);
            loginStore.set(true);
        },
        onError: () => {
            enqueueSnackbar('что-то пошло не так', {
                variant: 'error',
                description: 'Повторите попытку',
            });
        },
    });
    const successHandler = (data: TYandexAuthResponse) => {
        if (data.access_token) {
            fireProductEvent('yandexOauth');
            userInfoStore.update({ yaToken: data.access_token });
            login({ authToken: data.access_token });
        }
    };
    const handleError = useCallback(
        () =>
            enqueueSnackbar('Что-то пошло не так', {
                variant: 'error',
                description: 'обновите страницу или попробуйте позже',
            }),
        [enqueueSnackbar],
    );
    const handlePrimaryClick = useCallback(() => {
        navigate(authPaths.company.absolute);
    }, [navigate]);
    const handleSecondaryClick = useCallback(() => {
        navigate(authPaths.driver.absolute);
    }, [navigate]);
    return (
        <AuthPage
            OAuthWidget={
                <AuthControls
                    onSuccess={successHandler}
                    onError={handleError}
                    onPrimaryButton={handlePrimaryClick}
                    onSecondaryButton={handleSecondaryClick}
                />
            }
        />
    );
};
